import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { useAppSelector } from '../../app/hooks';
import { BlockedScheme } from '../../features/blocked-numbers/blockedSlice';


const Phone = (props: BlockedScheme & { onClick: () => void }) => {

  const { places } = useAppSelector(state => state.places);

  return (
    <div className="staff user" onClick={props.onClick}>
      <div className="user__icon">
        <Profile />
      </div>
      <div>
        <h2 className="user__name">{props.phone_number}</h2>
        <div className="label--gray">@ {Object.keys(props.places!).map((slug) => places[slug].name).join(', ')}</div>
      </div>
    </div>
  )
}

export default Phone;