import { SectionTitle } from '../../components/titles';
import { BlockedScheme } from '../../features/blocked-numbers/blockedSlice';
import Phone from '../../components/phone';
import { Input } from '../../components/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface BlockedSectionProps {
  title: string,
  blocked_numbers: Record<string, BlockedScheme>,
  onPhoneClick: (phone: string) => void
}

const PhoneNumbers = (props: BlockedSectionProps) => {
  const blocked_numbers = Object.keys(props.blocked_numbers);

  const [searchTerm, setSearchTerm] = useState('');
  const {t} = useTranslation(['inputs']);
  
  return (
    <>
      <SectionTitle title={props.title} />
      <Input type="text" placeholder={t("numbers_placeholder", {ns: 'inputs'})} label={t("search_by_number", {ns: 'inputs'})} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
      <div className="grid blocked-numbers-grid">
        { blocked_numbers.map((phone, index) => Object.keys(props.blocked_numbers[phone].places).length > 0 && props.blocked_numbers[phone].phone_number.toLowerCase().includes(searchTerm) &&
        <Phone key={props.blocked_numbers[phone].phone_number} {...props.blocked_numbers[phone]} onClick={() => props.onPhoneClick(phone)} />)}
      </div>
    </>
  )
}

export default PhoneNumbers;