var script = null;
let autoComplete;

// dynamic load script
export const loadScript = (url, callback) => {
  if(script === null) {
    script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
  else {
    callback();
  }
};

export function handleScriptLoad(updateQuery, autoCompleteRef, assignAddress) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: ["hr"] }, fields: ["address_components", "formatted_address"] }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, assignAddress)
  );
}

async function handlePlaceSelect(updateQuery, assignAddress) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  let country_code = '';
  updateQuery(query);

  for (var i = 0; i < addressObject.address_components.length; i += 1) {
    var addressObj = addressObject.address_components[i];
    for (var j = 0; j < addressObj.types.length; j += 1) {
      if (addressObj.types[j] === 'country') {
        country_code = addressObj.short_name
      }
    }
  }
  // assign data
  assignAddress({address: query, country_code});
}