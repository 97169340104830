import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { staffAPI } from './staffAPI';
import StaffScheme from './staffEnum';

// places scheme
interface staffState {
  staff: Record<string, StaffScheme>
}

// initial state
const initialState = {
  staff: {},
} as staffState;

// login thunk
export const getStaff = createAsyncThunk('staff/get', staffAPI.get);
// edit staff thunk
export const assignStaff = createAsyncThunk('staff/edit', staffAPI.editStaff);

// create user slice
const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getStaff.fulfilled, (state, { payload }) => {
      state.staff = payload;
    })
    builder.addCase(assignStaff.fulfilled, (state, { payload }) => {
      let {query} = payload;
      // check if users exists in staff
      if(state.staff[query.email]) {
        //copy places
        let new_state = { ...state.staff[query.email] };
        let { places } = new_state
        
        for(const slug in places) {
          // go through places & update role
          if(query.places[places[slug].place_id] === "invite")
            places[slug].role = query.role;
          // delete role
          else if(query.places[places[slug].place_id] === "delete")
            delete places[slug];
        }
      }
    })
  },
})

export default staffSlice.reducer;