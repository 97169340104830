import { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";

interface PriceInputProps {
  label?: string,
  type: string,
  placeholder: string,
  name?: string,
  selectedCurrency: string,
  selectCurrency(currency: string): void,
  value?: string,
  error?: string,
  defaultValue?: string,
  key?: any,
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void,
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void
}

export const PriceInput = (props: PriceInputProps) => {

  // currencies import
  const { currencies } = useAppSelector(state => state.currencies );

  useEffect(() => {
    if(props.selectedCurrency === '') {
      let currency = (Object.keys(currencies).filter((value) => currencies[value].default))[0];
      // select currency
      props.selectCurrency(currency);
    }
  }, [props.selectedCurrency]);

  const handleCurrencyChange = (e:any) => {
    props.selectCurrency(e.target.value);
  }

  return (
    <div className="input-wrapper">
      <div className="label input__label">{props.label}</div>
      <div>
        <input className="price-input__input" key={props.key} autoCorrect="off" autoCapitalize="none" data-invalid={props.error && props.error !== '' && props.value === ''} defaultValue={props.defaultValue} type={props.type} name={props.name} value={props.value} placeholder={props.placeholder} onChange={props.onChange} onKeyDown={props.onKeyDown} />
        <select className="price-input__select" key={props.selectedCurrency} defaultValue={props.selectedCurrency} onChange={handleCurrencyChange}>
          {
            Object.keys(currencies).map((value) => {
              return <option key={currencies[value].symbol} value={value}>{currencies[value].symbol}</option>
            })
          }
        </select>
      </div>
    </div>
  );
}
