import API from '../api';

// function to reject request
export const rejectRequest = async (message: string, request_id: number, place_id: number, event_id: string) => {
  let response = await API.delete(`/requests/decline/${request_id}`, { data: { query: { place_id: place_id, event_id: event_id, reason: message } } });
  return response.data;
}

// function to accept request
export const acceptRequest = async (table_id: number | null, request_id: number, place_id: number, event_id: string) => {
  let response = await API.post(`/requests/approve/${request_id}`, { query: { place_id: place_id, event_id: event_id, table_id: table_id } });
  return response.data;
}