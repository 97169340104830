import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "../../components/loading/loading";
import PhonePopup from "../../components/popup/phone-popup";
import { ContentTitle } from "../../components/titles";
import { getBlockedNumbers } from "../../features/blocked-numbers/blockedSlice";
import PhoneNumbers from "./section";

const BlockedNumbers = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  // state for popup
  let [phonePopupOpened, setPhonePopupState] = useState({phone: ''});

  // staff from redux
  const { blocked_numbers } = useAppSelector(state => state.blocked_numbers);

  // open and close popup functions
  const openPhonePopup = (phone: string) => { setPhonePopupState({phone});};
  const closePhonePopup = () => setPhonePopupState({phone: ''});
  // translation
  const {t} = useTranslation(['general', 'menu']);

  useEffect(() => {
    // function to load blocked numbers
    (async () => {
      try {
        await dispatch(getBlockedNumbers(true));
        setLoading(false);
      } 
      catch {
        setLoading(false);
      }
    }
    )();
  }, [])

  if(loading) // manage loading page
    return <Loading />

  return (
    <>
      <ContentTitle title={t("blocked", {ns: "menu"})} />
      { 
      Object.keys(blocked_numbers).length === 0  ? <div className='empty-section'>{t("no_blocked_numbers")}</div> :
      <section className="content-section">
        <PhoneNumbers title={t("numbers_title")} blocked_numbers={blocked_numbers} onPhoneClick={openPhonePopup}/>
        <PhonePopup selected={phonePopupOpened} isOpened={(phonePopupOpened.phone === '') ? false : true} close={closePhonePopup} />
      </section>
      }
    </>
  )
}

export default BlockedNumbers;