import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useRoutes, Outlet, Navigate } from "react-router-dom";
import { checkSession } from "./features/user/userSlice";
import Layout from "./containers/layout";
import ClubLayout from "./containers/layout/club-layout";
import Clubs from "./containers/clubs";
import ClubDetails from "./containers/club-details";
import Login from "./containers/login";
import Staff from "./containers/staff";
import AddEvent from "./containers/add-event";
import { useEffect, useState } from "react";
import Notifications from "./containers/notifications";
import SignUp from "./containers/signup";
import PasswordRecovery from "./containers/recovery/password";
import Invitation from "./containers/invitation";
import EmailRecovery from "./containers/recovery/email";
import Settings from "./containers/settings";
import Canvas from "./components/canvas";
import BlockedNumbers from "./containers/blocked-numbers";
import ClubEdit from "./containers/clubs/edit";
import Loading from "./components/loading/loading";
import { useTranslation } from "react-i18next";
import { getMaintainceStatus } from "./helpers";
import MaintainceMode from "./containers/maintaince";

// App routes
const App = () => {

  const { i18n, ready } = useTranslation();
  const [status, setStatus] = useState({maintaince: false, duration: ''});

  useEffect(() => {
    (async () => { 
      setStatus(await getMaintainceStatus());
    })();
  }, [])
  // load languages
  useEffect(() => {
    // assign lang attribute
    if(ready && i18n.language)
      document.documentElement.lang = i18n.language;
      
  }, [ready]);

  let element = useRoutes([
    { 
      path: "/",
      element: <ProtectedRoute credentials />,
      children: [
        {
          path: "/",
          element: <Layout />,
          children: [
            {
              path: "",
              element: <Clubs />
            },
            {
              path: "club/:slug/",
              element: <ClubLayout />,
              children: [
                {
                  path: "",
                  element: <ClubDetails />,
                  children: [
                    {
                      path: "reservations/:event_id",
                      element: <Canvas />
                    }
                  ]
                },
                {
                  path: "events/new",
                  element: <AddEvent />,
                  children: [
                    {
                      path: "tables",
                      element: <Canvas mode={2} />
                    }
                  ]
                },
                {
                  path: "events/edit/:event_id",
                  element: <AddEvent />,
                  children: [
                    {
                      path: "tables",
                      element: <Canvas mode={2} />
                    }
                  ]
                },
                {
                  path: "edit",
                  element: <ClubEdit />
                },
              ]
            },
            {
              path: "staff",
              element: <Staff />
            },
            {
              path: "block-list",
              element: <BlockedNumbers />
            },
            {
              path: "notifications",
              element: <Notifications />
            },
            {
              path: "settings",
              element: <Settings />
            }
          ]
        }
      ]
    },
    {
      element: <ProtectedRoute />,
      children: [
        {
          path: "/invitation/:key",
          element: <Invitation />
        },
        {
          path: "/signup/:key",
          element: <SignUp />
        },
        {
          path: "/recovery/:key",
          element: <PasswordRecovery />
        },
        {
          path: "/recovery/",
          element: <EmailRecovery />
        },
        {
          path: "/login",
          element: <Login />
        }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    }
  ]);

  // languages didn't loaded return loading
  if(!ready) return <Loading />
  
  if(status.maintaince) return <MaintainceMode {...status} />;

  return element;
};

// function to protect route
const ProtectedRoute = (props: { credentials?: boolean } ) => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector(state => state.user);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    // if not loaded, call self invocating function to dispatch check user request
    !isLoaded && (async () => {
      // await for API call
      await dispatch(checkSession(true));
      // set isLoaded as true
      setLoaded(true);
    })()
  }, [isLoaded])

  // return null if sill waiting for API response
  if(!isLoaded) return <Loading />;

  // if user is not logged in and credentials are needed, return to login
  if (!isLoggedIn && props.credentials)
    return <Navigate to="/login" replace />;
  // if you are logged in and credentitals are not needed, return to dashboard
  else if(isLoggedIn && !props.credentials)
    return <Navigate to="/" replace />;

  // return Outlet if user is logged in
  return <Outlet />;
};

export default App;