import { useTranslation } from "react-i18next";
import { BackLink } from "../../link";
import { ReservationScheme } from "../enums";
import { GroupSizeIcon, PhoneIcon } from "../../icons";
import { formatReservationTime } from "../../../helpers";
import { useAppSelector } from "../../../app/hooks";
import { useParams } from "react-router-dom";

interface ReservationDetailsScheme {
  reservation: ReservationScheme | {},
  isOpened: boolean,
  close: () => void
}

const ReservationDetails = (props: ReservationDetailsScheme) => {
  // get events and places from redux store
  const { places } = useAppSelector( state => state.places );
  // get club slug from link and event id
  let { slug } = useParams();

  const { t } = useTranslation(['general', 'buttons']);

  if(!('id' in props.reservation))
    return <></>

  return (
    <div className={"reservation-details" + (props.isOpened ? " opened" : "")}>
      <BackLink text={t("back2", {ns: 'buttons'})} onClick={props.close} />
      <h1 className="reservation-details__h1">{t("reservation_details_title", { ns: 'general' })}</h1>
      {/* Person details */}
      <div className="reservation-details__person">
        <div className="reservation-details__person-info">
         <div className="label--gray label--pb2">{t("name_label", {ns: 'inputs'})}</div>
          <h3>{props.reservation.customer_name}</h3>
        </div>
          <div className="reservation-details__person-actions">
            {
              props.reservation.phone_number && 
              <a href={"tel:" + props.reservation.phone_number}>
                <div className="reservation-details__person-action">
                  <PhoneIcon />
                </div>
              </a>
            }
          </div>
      </div>
      {/* Reservation attributes */}
      <div className="reservation-details__attributes">
        {
          props.reservation.request_attributes && props.reservation.request_attributes.group_size &&
          <div className="reservation-details__attribute label--gray"><GroupSizeIcon /><span>{props.reservation.request_attributes.group_size}</span></div>
        }
        {
          props.reservation.request_attributes && props.reservation.request_attributes.age_average &&
          <div className="reservation-details__attribute label--gray">{t("age_average", {ns: 'general'})} {props.reservation.request_attributes.age_average}</div>
        }
        {
          props.reservation.request_attributes && props.reservation.request_attributes.section_id &&
          <div className="reservation-details__attribute label--gray">{t("requested_section", {ns: 'general'})} <span>{places[slug!].sections[props.reservation.request_attributes.section_id].section_name}</span></div>
        }
      </div>
      {/* Staff action details */}
      { props.reservation.assigned_by_name && <div className="reservation-details__assigned-by label--gray">{t("assigned_by_text", { ns: 'general' })} {props.reservation.assigned_by_name}</div> }
      { props.reservation.approved_by_name && <div className="reservation-details__assigned-by label--gray">{t("approved_by_text", { ns: 'general' })} {props.reservation.approved_by_name}</div> }
      {
        'created_at' in props.reservation && props.reservation.created_at &&
        <div className="reservation-details__time label--gray">{formatReservationTime(new Date(props.reservation.created_at), t)}</div>
      }
    </div>
  )
}

export default ReservationDetails;