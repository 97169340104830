import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { handleLimit, limitTablesAction, updateRequestsCount } from "../../features/events/eventsSlice";
import Loading from "../loading/loading";
import CanvasActions from './actions';
import EventBadge from "./event-badge";
import CanvasNav, { FastLimit } from "./nav";
import CanvasForm from "./form";
import { ReservationRequestScheme, ReservationScheme, TableReservationScheme, TableScheme } from "./enums";
import Scan from "./scan";
import { newMessage } from "../../features/messages/messagesSlice";
import { confirmGuestPresence, createReservation, switchTable } from "../../helpers/api/reservations";
import { setLimited } from '../../features/events/eventsSlice';
import SaveButton from "./save-button";
import { getEventPhase, GOING_PHASE, TAKEOVER_PHASE } from "../../helpers/eventPhases";
import GuestList from "./guest-list";
import { useTranslation } from "react-i18next";
import { canvas_config, place_roles } from "../../app/config";
import WaitingList from "./waiting-list";
import { acceptRequest } from "../../helpers/api/requests";
import { BackLink } from "../link";

interface CanvasScheme {
  mode?: number,
  event_id?: number,
  isOpened?: boolean
}

export const VIEW_MODE = 1; // manager & owner mode
export const RESTRICT_MODE = 2; // table restrict mode
export const HOSTESS_MODE = 3; // hostes mode

// actions variable
let actions: CanvasActions | undefined = undefined;

const Canvas = (props: CanvasScheme) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // canvas reference
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // get events and places from redux store
  const { events } = useAppSelector( state => state.events );
  const { places } = useAppSelector( state => state.places );
  // get club slug from link and event id
  let { slug, event_id } = useParams();
  // floor state (we have to update state so CanvasNav component re-renders)
  let [floor, updateFloor] = useState(0);
  // form state (on table click, in mode 1, open reservation form)
  let [formState, toggleFormState] = useState(false);
  let [optionsState, setOptionsState] = useState(false);
  let [guestListState, setGuestListState] = useState(false);
  let [tables, setTables] = useState({});
  let [reservations, setReservations] = useState<Record<string, TableReservationScheme>>({});
  let [reservationsInStack, setReservationsInStack] = useState<Record<string, ReservationScheme>>({});
  let [selectedTable, setSelectedTable] = useState({});
  let [availableTables, setAvailableTables] = useState({});
  let [selectedReservation, setSelectedReservation] = useState<ReservationScheme | {}>({});
  let [leading, setLeading] = useState(false);
  let [leadingReservation, setLeadingReservation] = useState<ReservationScheme | {}>({});
  let [isLoading, setLoading] = useState(true);
  let [mode, setMode] = useState(props.mode);
  let [eventPhase, setEventPhase] = useState(0);
  let [scanOption, setScanOption] = useState(false);
  let [waitingListState, setWaitingListState] = useState(false);
  let [waitingList, setWaitingList] = useState<Record<string, ReservationRequestScheme>>({});
  let [nameLabelsOption, setNameLabelsOption] = useState(canvas_config.nameLabels);
  let [pickATableMode, setPickATableMode] = useState(false);
  let [requestToAccept, setRequestToAccept] = useState<ReservationRequestScheme & { table_id?: number } | {}>({});
  let [mergeATableMode, setMergeATableMode] = useState(false);
  let [changeATableMode, setChangeATableMode] = useState(false);
  let [selectedTableAfterPick, setSelectedTableAfterPick] = useState<number>(-1);
  let [selectedStackReservationAfterPick, setSelectedStackReservationAfterPick] = useState<number>(-1);
  const { limitTables, delimitTables } = useAppSelector(state => state.events );
  // translate
  const {t} = useTranslation(['general', 'errors', 'success']);

  useEffect(() => {
    // auto mode
    if(!mode)
      return setMode(places[slug!].role <= place_roles.barmen ? 3 : 1);
    if(event_id)
      // get event phase
      setEventPhase(getEventPhase(events[event_id], true));
    // instance Canvas Actions class
    actions = new CanvasActions(canvasRef.current!, event_id ? events[event_id] : undefined, places[slug!], mode);
    // set onLoaded callback
    actions.onLoaded = onLoaded;
    actions.setLoading = socketReconnecting;
    actions.onLimit = onLimit;
    actions.toggleForm = toggleForm;
    actions.selectTable = selectTable;
    actions.setSelectedReservation = getSelectedReservation;
    actions.setAvailableTables = getAvailableTables;
    actions.updateFloor = setNewFloor;
    actions.setLimitedTables = setLimitedTables;
    actions.onDisconnect = onDisconnect;
    actions.updateTables = updateTables;
    actions.updateReservations = updateReservations;
    actions.updateWaitingList = updateWaitingList;
    actions.tablePicked = tablePicked;
    // update floor state (depending on Canvas Actions default setup)
    updateFloor(actions.floor);
    // on component unmount
    return () => {
      actions && actions.unmountSocket();
    }
  }, [mode, event_id]);

  useEffect(() => {
    // if form is opened
    if((formState || optionsState) && event_id)
      // raise number of click so real time
      setEventPhase(getEventPhase(events[event_id], true));
  }, [formState, optionsState]);

  useEffect(() => {
    // paint unsaved limited and delimited tables
    if(mode === 2 && actions) {
      actions.toLimit = limitTables;
      actions.toDelimit = delimitTables;
      actions.paintUnsavedLimit();
      actions.forceRender();
    }
  }, [limitTables, delimitTables])

  if(!mode && !event_id || (event_id && !events[event_id]))
    return <Navigate to="/" replace />
    
  let onDisconnect = () => {
    dispatch(newMessage({ content: t('socket_disconnect'), type: 'error' }));
    navigate(-1);
  }

  let socketReconnecting = () => {
    setLoading(true);
  }

  // set state as not loading anymore (remove loader)
  let onLoaded = () => {
    setLoading(false);
    // paint unsaved limited and delimited tables
    if(mode === 2 && actions) {
      actions.toLimit = limitTables;
      actions.toDelimit = delimitTables;
    }
  }

  let toggleForm = (state: boolean) => {
    toggleFormState(state);
  }
  
  let updateTables = (tables:  Record<string, TableScheme>) => {
    setTables(tables);
  }
  
  let updateReservations = (reservations:  Record<string, TableReservationScheme>, reservationsInStack: Record<string, ReservationScheme>) => {
    setReservations({ ...reservations });
    setReservationsInStack({ ...reservationsInStack });
  }

  let updateWaitingList = (waitingList: Record<string, ReservationRequestScheme>) => {
    // store reservation requests to waiting list
    setWaitingList({ ...waitingList });
    // update requests count for selected event
    dispatch(updateRequestsCount({ event_id: event_id, count: Object.keys(waitingList).length }))
  }
  
  let onLimit = (id: number) => {
    dispatch(handleLimit(id));
  }

  let selectTable = (table: TableScheme | {}) => {
    setSelectedTable(table);
  }
  
  let getAvailableTables = (tables: Record<string, TableScheme> | {}) => {
    setAvailableTables(tables);
  }
  
  let getSelectedReservation = (reservation: ReservationScheme | {}) => {
    setSelectedReservation(reservation);
  }
  
  let setLimitedTables = (tables: number[]) => {
    dispatch(setLimited(tables))
  }

  // function to handle floor change
  let handleFloorChange = (floor: number) => {
    // call change floor action
    actions?.changeFloor(floor);
  }

  // update floor state from actions
  let setNewFloor = (floor: number) => {
    updateFloor(floor);
  }

  // on shortcode scan
  let onScan = (shortcode: string) => {
    // get reservation by shortcode
    let reservation = actions?.getReservationByShortcode(shortcode);
    // if shortcode is invalid, function above will return null
    if(reservation === null)
      return dispatch(newMessage({ content: t("invalid_shortcode", {ns: 'errors'}), type: "error" }));
    else if(reservation?.confirmed)
      if(reservation.table_id)
        return dispatch(newMessage({ content: t("table_confirmed", {ns: 'errors', table: actions?.tables[reservation.table_id].label}), type: "success" }));
      else
        return dispatch(newMessage({ content: t("table_confirmed", {ns: 'errors', table: "?"}), type: "success" }));
    // store leading reservation
    setLeadingReservation(reservation ? reservation : {});
    // lead to the table if table reservation
    if(reservation?.table_id)
      actions?.toTheTable(reservation!.table_id);
    else
      actions?.toTheStackReservation(reservation!.id)
    // change button to confirm presence
    setLeading(true);
  }

  // confirm guest presence after leading to the table
  let confirmPresence = async () => {
    try {
      // throw error if missing data
      if(!(!!event_id && events[event_id] && (actions?.leading || actions?.leadingReservation) && 'id' in leadingReservation)) throw new Error("Missing data");
      // await for API response on confirm guest presence
      await confirmGuestPresence({ event_id: event_id, place_id: events[event_id].place_id, reservation_id: leadingReservation.id });
    } catch {
      dispatch(newMessage({ content: t("something_wrong", {ns: 'errors'}), type: "error" }));
    }
    // stop leading
    actions?.stopLeading();
    // store leading reservation
    setLeadingReservation({});
    // return normal button
    setLeading(false);
  }

  let saveLimitTables = async () => {
    try {
      // limit tables if there are any to limit
      if((limitTables.length || delimitTables.length) && event_id)
        await dispatch(limitTablesAction({ event_id: parseInt(event_id, 10), place_id: events[event_id].place_id, tables: limitTables, unlimit: delimitTables }));

      dispatch(newMessage({ content: t("limit_tables_success", {ns: 'success'}), type: 'success' }));
      navigate(-1);
    } catch {
      dispatch(newMessage({ content: t("something_wrong", {ns: 'errors'}), type: 'error' }));
    }
  }

  let onGuestDoubleClick = (table_id: number, reservation_id: number) => {
    // if opened, exit pick a table mode
    pickATableMode && exitPickATableMode(false);
    // close guest list
    setGuestListState(false);
    // opend form if not opened by accident
    toggleForm(true);
    // open table reservation
    if(table_id && table_id !== -1)
      actions?.handleReservationClick({}, table_id);
    else
      actions?.onStackReservationClick({}, reservation_id);
  }

  let exitLeading = () => {
    // stop leading
    actions?.stopLeading(true);
    // store leading reservation
    setLeadingReservation({});
    // set flag as false
    setLeading(false);
  }

  let download2d = () => {
    actions?.download2DGuestList();
  }

  // function to toggle name labels option
  let toggleNameLabelsOption = () => {
    let newState = nameLabelsOption ? false : true;
    // change component state
    setNameLabelsOption(newState)
    // do action
    actions?.toggleNameLabels(newState);
  }

  // function to toggle waiting list option
  let toggleWaitingListOption = (state?: boolean) => {
    // is state is undefined, turn it on
    setWaitingListState(state !== undefined ? state : true);
  }

  // called when table is picked
  let tablePicked = (table_id: number, request_id: number, reservation_id?: number) => {
    // if merging or changing tables is in action
    if(request_id === -2 || request_id === -3) {
      if(table_id !== -1)
        setSelectedTableAfterPick(table_id);
      return setSelectedStackReservationAfterPick(reservation_id || -1);
    }
    // if acception reservations is in action
    setRequestToAccept((prev) => ({ ...prev, table_id: table_id }));
  }

  // when changing reservation table, to see where are you changing to
  let onNewTableSelect = (table_id: number) => {
    actions?.markNewTable(table_id);
  }

  // function to accept user request (first pick a table)
  let acceptSingleRequest = (request: ReservationRequestScheme) => {
    // store request to state
    setRequestToAccept(request);
    // close waiting list
    toggleWaitingListOption(false);
    // get into pick a table mode (for form)
    setPickATableMode(true);
    // start table picking process
    actions?.acceptRequest(request.id);
  }

  // function to exit pick a table mode
  let exitPickATableMode = (reopenWaitingList?: boolean) => {
    // return request state to empty
    setRequestToAccept({});
    // re-open waiting list
    toggleWaitingListOption(reopenWaitingList === undefined ? true : reopenWaitingList);
    // exit form picka a table mode
    setPickATableMode(false);
    // stop table picking process
    actions?.acceptRequest(-1);
  }

  // function to accept reservation request with selected table
  let onConfirmTablePick = async () => {
    try {
      if(!requestToAccept || !('table_id' in requestToAccept)) return;
      // make API request
      await acceptRequest(requestToAccept.table_id!, requestToAccept.id, places[slug!].place_id, event_id!);
      // dispatch a pop message
      dispatch(newMessage({ content: t("request_accepted", {ns: 'success'}), type: 'success' }));
      // if accept is successful, close form and return to waiting list
      exitPickATableMode();
    } catch(e: any) {
      dispatch(newMessage({ content: t(e.response.data.error), type: 'error' }));
    }
  }

  let acceptRequestToStack = async () => {
    try {
      if(!requestToAccept || !('id' in requestToAccept)) return;
      // make API request
      await acceptRequest(null, requestToAccept.id, places[slug!].place_id, event_id!);
      // dispatch a pop message
      dispatch(newMessage({ content: t("request_accepted", {ns: 'success'}), type: 'success' }));
      // if accept is successful, close form and return to waiting list
      exitPickATableMode();
    } catch(e: any) {
      dispatch(newMessage({ content: t(e.response.data.error), type: 'error' }));
    }
  }

  let initiateTableMerging = () => {
    // return request state to empty
    if('table_id' in selectedReservation && selectedReservation.table_id)
      setSelectedTableAfterPick(selectedReservation.table_id);
    // get into pick a table mode (for form)
    setMergeATableMode(true);
    // start table picking process for merging
    actions?.acceptRequest(-2);
  }

  // function to exit pick a table mode
  let exitMergeATableMode = () => {
    // return request state to empty
    setSelectedTableAfterPick(-1);
    // exit form picka a table mode
    setMergeATableMode(false);
    // stop table picking process
    actions?.acceptRequest(-1);
  }

  let onConfirmTableMerge = async () => {
    try {
      if(selectedTableAfterPick === -1 || !('customer_name' in selectedReservation)) return;
      // make API request
      await createReservation({
        table_id: selectedTableAfterPick,
        event_id: event_id!,
        place_id: places[slug!].place_id,
        customer_name: selectedReservation.customer_name,
        phone_number: ""
      });
      // dispatch a pop message
      dispatch(newMessage({ content: t("merge_accepted", {ns: 'success'}), type: 'success' }));
      // if accept is successful, close form and return to waiting list
      exitMergeATableMode();
    } catch(e: any) {
      dispatch(newMessage({ content: t(e.response.data.error), type: 'error' }));
    }
  }

  let startChangeTableMode = () => {
    // return request state to empty
    if('id' in selectedReservation){
      setSelectedStackReservationAfterPick(selectedReservation.id);
      if(selectedReservation.table_id)
        setSelectedTableAfterPick(selectedReservation.table_id);
    }
    // get into pick a table mode (for form)
    setChangeATableMode(true);
    // start table picking process for changing table
    actions?.acceptRequest(-3);
  }

  // function to exit pick a table mode
  let exitChangeATableMode = () => {
    // return request state to empty
    setSelectedTableAfterPick(-1);
    // exit form picka a table mode
    setChangeATableMode(false);
    // stop table picking process
    actions?.acceptRequest(-1);
  }

  let onConfirmTableChange = async () => {
    try {
      if(!('customer_name' in selectedReservation)) return;
      // check if reservation on newly selected table exists (switch tables)
      if(reservationsInStack[selectedStackReservationAfterPick] && selectedStackReservationAfterPick !== selectedReservation.id) {
        await switchTable({
          reservation_id: selectedReservation.id,
          switch_reservation_id: selectedStackReservationAfterPick,
          event_id: selectedReservation.event_id, 
          place_id: places[slug!].place_id
        });
      } else if(reservations[selectedTableAfterPick] && selectedTable !== selectedTableAfterPick) {
        await switchTable({
          reservation_id: selectedReservation.id,
          switch_reservation_id: reservations[selectedTableAfterPick].id,
          event_id: selectedReservation.event_id, 
          place_id: places[slug!].place_id
        });
      } else {
        await switchTable({
          reservation_id: selectedReservation.id,
          new_table_id: selectedTableAfterPick, 
          event_id: selectedReservation.event_id, 
          place_id: places[slug!].place_id
        });
      }
      // dispatch a pop message
      dispatch(newMessage({ content: t("change_table_accepted", {ns: 'success'}), type: 'success' }));
      // if accept is successful, close form and return to waiting list
      exitChangeATableMode();
      // switch to new table
      if(selectedTableAfterPick)
        actions && actions.handleReservationClick({}, selectedTableAfterPick)
      else
        actions && actions.restoreFormState(true)
    } catch(e: any) {
      dispatch(newMessage({ content: t('something_wrong'), type: 'error' }));
    }
  }

  // function to move table to stack when changing table
  let moveTableToStack = async () => {
    try {
      // protection
      if(!('customer_name' in selectedReservation)) return;
      // send api request
      await switchTable({
        reservation_id: selectedReservation.id,
        new_table_id: null,
        event_id: selectedReservation.event_id, 
        place_id: places[slug!].place_id
      });
      // dispatch a pop message
      dispatch(newMessage({ content: t("change_table_accepted", {ns: 'success'}), type: 'success' }));
      // if accept is successful, close form and return to waiting list
      exitChangeATableMode();
      // restore form state
      actions && actions.onStackReservationClick({}, selectedReservation.id);
    } catch(e: any) {
      dispatch(newMessage({ content: t('something_wrong'), type: 'error' }));
    }
  }

  return (
    <div className="canvas__wrapper">
      <div className="canvas__header container">
        {/* <CanvasNav {...places[slug!]} changeFloor={handleFloorChange} activeFloor={floor} /> */}
        <div className="canvas__nav">
          <BackLink text="Povratak" />
          { 
            mode !== RESTRICT_MODE &&
              <EventBadge 
                eventPhase={eventPhase}
                event={events[event_id!]}
                club={places[slug!]}
                onGuestList={() => setGuestListState(true)}
                onOptionsClick={(state) => setOptionsState(state)}
                scanOption={scanOption}
                toggleScanOption={() => setScanOption(scanOption ? false : true)}
                nameLabelsOption={nameLabelsOption}
                toggleNameLabelsOption={toggleNameLabelsOption}
                waitingListLength={waitingList && Object.keys(waitingList).length}
                toggleWaitingListOption={toggleWaitingListOption}
              /> 
          }
        </div>
        {
          mode === RESTRICT_MODE && actions && 
            <FastLimit tablesBySections={actions.tablesBySections} reservations={reservations} />
        }
      </div>
      { 
        mode !== RESTRICT_MODE && 
          <CanvasForm 
            onNewTableSelect={onNewTableSelect}
            mode={mode!}
            event={events[event_id!]}
            event_phase={eventPhase}
            formState={formState}
            selectedTable={selectedTable}
            selectedReservation={selectedReservation}
            availableTables={availableTables}
            // to accept a request from waiting list
            requestToAccept={requestToAccept}
            pickATableMode={pickATableMode}
            exitPickATableMode={exitPickATableMode}
            onConfirmTablePick={onConfirmTablePick}
            acceptRequestToStack={acceptRequestToStack}
            // for merge a table mode
            mergeATableMode={mergeATableMode}
            startMergeATableMode={initiateTableMerging}
            exitMergeATableMode={exitMergeATableMode}
            onConfirmTableMerge={onConfirmTableMerge}
            // for change a table mode
            changeATableMode={changeATableMode}
            startChangeTableMode={startChangeTableMode}
            exitChangeATableMode={exitChangeATableMode}
            onConfirmTableChange={onConfirmTableChange}
            moveTableToStack={moveTableToStack}
            selectedTableAfterPick={selectedTableAfterPick}
            selectedStackReservationAfterPick={selectedStackReservationAfterPick}
            // toggle form
            toggleForm={() => actions && actions.restoreFormState(true)}
          />
      }
      { 
        mode !== RESTRICT_MODE && 
          <GuestList 
            download2d={download2d}
            sections={places[slug!].sections}
            event={events[event_id!]}
            tables={tables}
            reservations={reservations}
            reservationsInStack={reservationsInStack}
            opened={guestListState}
            close={() => setGuestListState(false)}
            onGuestDoubleClick={onGuestDoubleClick}
            isHostess={mode === HOSTESS_MODE}
          />
      }
      { mode !== RESTRICT_MODE && !formState && (eventPhase === TAKEOVER_PHASE || eventPhase === GOING_PHASE) && scanOption && <Scan onScan={onScan} isLeading={leading} confirmPresence={confirmPresence} exit={exitLeading} event_id={event_id!}/> }
      {
        mode !== RESTRICT_MODE && (places[slug!].role >= place_roles.manager) && event_id && 
        <WaitingList 
          place_id={places[slug!].place_id}
          sections={places[slug!].sections}
          event_id={event_id}
          list={waitingList}
          isOpened={waitingListState}
          close={() => setWaitingListState(false)}
          acceptSingle={acceptSingleRequest}
        />
      }
      { mode === RESTRICT_MODE && event_id && <SaveButton onClick={() => saveLimitTables()} /> }
      { isLoading && <Loading /> }
      <canvas ref={canvasRef}></canvas>
    </div>
  )
}

export default Canvas;