import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading/loading';
import { SignForm } from '../../components/signup/form';
import { getInvitation } from '../../helpers/api/invitations';

const SignUp = () => {
  // navigate
  const navigate = useNavigate();
  // fetch api
  const [loading, setLoaded] = useState(false);
  // get slug from link so we can fetch events for selected club
  let { key } = useParams();

  useEffect(() => {
    // get invitation (API request)
    getInvitation(key).then(res => {
      // if user exists or link is broken, navigate to /
      if(res.status !== -1) navigate('/notifications', { replace: true });
      // remove loader from page and display data
      setLoaded(true);
    }).catch(() => navigate('/', { replace: true }));
  }, [key]);

  if(!loading)
    return <Loading />

  return <SignForm hash={key}/>
}

export default SignUp;