import { useTranslation } from 'react-i18next';
import { ContentTitle } from '../../components/titles';
import InvitationsNotifications from './invitations';

const Notifications = () => {
  const {t} = useTranslation(['menu']);
  return (
    <>
      <ContentTitle title={t("notifications", {ns: 'menu'})} />
      <InvitationsNotifications />
    </>
  )
}

export default Notifications;