import API from '../../helpers/api';
import { newMessage } from '../messages/messagesSlice';

export const eventsAPI = {
  get,
  newEvent,
  updateEvent,
  cancelEvent,
  deleteEvent,
  getBySlug,
  limitTables,
  sendReceipts
}

// function for getting all use events
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let req = await API.get('/events/');
    // return places
    return req.data.events;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for getting all use events
async function getBySlug(params: string, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let req = await API.get('/events/staff/'+params);
    // return places
    return req.data.events;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for posting new event
async function newEvent(params: {formData: FormData, query:any}, { dispatch, rejectWithValue }:any) {
  try {
    // post for new event
    let res= await API.post('/events/new', params.formData);
    
    params.query.banner = res.data.new.banner;
    params.query.id = res.data.new.id;

    params.query.event_start = params.query.event_start.toString();
    params.query.event_end = params.query.event_end.toString();
    params.query.reservation_start = params.query.reservation_start.toString();
    params.query.reservation_takeover = params.query.reservation_takeover.toString();
    
    return params.query;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for update existing event
async function updateEvent(params: {formData: FormData, query:any, event_id:number }, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let res = await API.put('/events/update/'+params.event_id, params.formData);

    params.query.banner = res.data.new_banner;
    params.query.id = params.event_id;

    params.query.event_start = params.query.event_start.toString();
    params.query.event_end = params.query.event_end.toString();
    params.query.reservation_start = params.query.reservation_start.toString();
    params.query.reservation_takeover = params.query.reservation_takeover.toString();

    return params.query;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for cancel existing event
async function cancelEvent(params: {event_id: number, body: {query : {event_cancelled: boolean, place_id: number}}}, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    await API.put('/events/cancel/'+params.event_id, params.body);

    return {id: params.event_id, status: params.body.query.event_cancelled };
  } catch (err) {
    return rejectWithValue();
  }
}

// function for delete existing event
async function deleteEvent(params: {event_id: number, body: {query : { place_id: number}}}, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    await API.delete('/events/delete/'+params.event_id, {data: params.body});

    return params.event_id;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for limit tables for selected event
async function limitTables(params: {place_id: number, event_id: number, tables: number[], unlimit: number[]}, { dispatch, rejectWithValue }: any) {
  try {
    // get user places request
    await API.post('/reservations/limitTables/', { query: params });
    return true;
  } catch (err) {
    return rejectWithValue();
  }
}

// function to send receipts (double confirmation of arrival for guest)
async function sendReceipts(params: { event_id: number, body: {query : {place_id: number}} }, { dispatch, rejectWithValue }: any) {
  try {
    // get user places request
    let req = await API.post('/events/send_receipts/' + params.event_id, params.body);
    // return event if for payload
    return { event_id: params.event_id, success: req.data.success };
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
}