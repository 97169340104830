import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { _format } from '../../helpers';
import { eventsAPI } from './eventsAPI';
import EventsScheme from './eventsEnum';

const initialState = {
  events: {} as Record<string, EventsScheme>,
  limitedTables: [] as number[],
  limitTables: [] as number[],
  delimitTables: [] as number[]
}

// get events thunk
export const getEvents = createAsyncThunk('events/get', eventsAPI.get);
// get events thunk by slug
export const getEventsBySlug = createAsyncThunk('events/getbyslug', eventsAPI.getBySlug);
// new event thunk
export const newEvent = createAsyncThunk('events/add', eventsAPI.newEvent);
// update event thunk
export const updateEvent = createAsyncThunk('events/update', eventsAPI.updateEvent);
// delete event thunk
export const deleteEvent = createAsyncThunk('events/delete', eventsAPI.deleteEvent);
// cancel event thunk
export const cancelEvent = createAsyncThunk('events/cancel', eventsAPI.cancelEvent);
// limit tables thunk
export const limitTablesAction = createAsyncThunk('events/limitTables', eventsAPI.limitTables);
// limit tables thunk
export const sendReceipts = createAsyncThunk('events/sendReceipts', eventsAPI.sendReceipts);

// create user slice
export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    handleLimit: (state, action) => {
      let table_id = action.payload; // parseInt(action.payload, 10);
      let isLimited = state.limitedTables.indexOf(table_id);
      let delimitIndex = state.delimitTables.indexOf(table_id);
      let limitIndex = state.limitTables.indexOf(table_id);

      if(isLimited !== -1) {
        if(delimitIndex === -1) {
          state.delimitTables.push(table_id);
        } else {
          state.delimitTables.splice(delimitIndex, 1);
        }
      } else {
        if(limitIndex === -1) {
          state.limitTables.push(table_id);
        } else {
          state.limitTables.splice(limitIndex, 1);
        }
      }
    },
    setLimited: (state, action) => {
      state.limitedTables = action.payload;
    },
    setEventBookedStatus: (state, {payload}) => {
      state.events[payload.id].event_booked = payload.status;
    },
    updateRequestsCount: (state, { payload }) => {
      state.events[payload.event_id].request_reservations_count = payload.count;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, { payload }) => {
      // add sort by date in database
      state.events = _format(payload, 'id');
    })
    // get events by slug
    builder.addCase(getEventsBySlug.fulfilled, (state, { payload }) => {
      let new_events = _format(payload, 'id');
      // add sort by date in database
      state.events = {...state.events, ...new_events};
    })
    builder.addCase(newEvent.fulfilled, (state, { payload }) => {
      // assign created_at
      let new_event = {
        ...payload,
        created_at: new Date().toString()
      }
      // add new event
      state.events[payload.id] = new_event;
    })
    builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
      // update state
      Object.assign(state.events[payload.id], payload);
    })
    builder.addCase(cancelEvent.fulfilled, (state, { payload }) => {
      // update event
      state.events[payload.id].event_cancelled = payload.status;
      // turn off fake mode
      state.events[payload.id].fake_mode = false;
      state.events[payload.id].fake_instances = 0;
    })
    builder.addCase(deleteEvent.fulfilled, (state, { payload }) => {
      // update event
      delete state.events[payload];
    })
    builder.addCase(limitTablesAction.fulfilled, (state) => {
      // update event
      state.limitTables = [];
      state.delimitTables = [];
      state.limitedTables = [];
    })
    builder.addCase(sendReceipts.fulfilled, (state, { payload }) => {
      // update receipt sent to true for given event
      state.events[payload.event_id].receipt_sent = true;
    })
  },
})

export const { handleLimit, setLimited, setEventBookedStatus, updateRequestsCount } = eventsSlice.actions;

export default eventsSlice.reducer;