import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PopupWrapper, { PopupActions, PopupProps } from ".";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { newMessage } from "../../features/messages/messagesSlice";
import Loading from "../loading/loading";
import PhonePopups from "./phone";
import { filterPlacesForPhones, PhoneState } from "./phone/helpers";
import { phoneSubmit } from "./phone/phoneSubmit";

const PhonePopup = (props: PopupProps & {selected: {phone: string}}) => {

  // dispatch for editing staff
  const dispatch = useAppDispatch();
  
  // phone pull
  const { blocked_numbers } = useAppSelector(state => state.blocked_numbers);
  // places pull
  const { places } = useAppSelector(state => state.places);

  // for easier manipulate
  const { phone } = props.selected;

  const [initialState, setInitial] = useState<PhoneState[]>([]);
  const [currentState, setCurrent] = useState<PhoneState[]>([]);
  const [loading, setLoading] = useState(false);

  // translation
  const {t} = useTranslation(['buttons', 'success', 'errors']);

  useEffect(() => {
    if(phone && blocked_numbers[phone].places) {
      // get new_state
      let new_state = filterPlacesForPhones(places, Object.keys(blocked_numbers[phone].places!));

      // when selected role change change initial state
      setInitial(new_state);

      // assign also and current state
      setCurrent(structuredClone(new_state));
    }
  }, [phone]);

  // function to assign place for block
  const assignPlace = (index:number, block:boolean) =>  {
    let new_obj = [...currentState];
    new_obj[index].is_blocked = block;
    setCurrent(new_obj);
  }

  if(loading)
    return <Loading />

  const Submit = async () => {
    setLoading(true);

    await (async () => {
      // dispatch phone submit
      let response = await phoneSubmit(initialState, currentState, phone, dispatch);
      if(response.sucess) {
        props.close();
        dispatch(newMessage({content: t(response.sucess, {ns: 'success'}), type: "success" }))
      }
      else 
        dispatch(newMessage({content: t(response.error!, {ns: 'errors'}), type: "error" }))
    })();
    setLoading(false);
  }

  return (
    <PopupWrapper opened={props.isOpened}>
      <div>
        <PopupActions close={props.close} />
        {phone && <>
        <PhonePopups.PhoneDetails phone={phone}/>
        <PhonePopups.PhoneLabel total_count={blocked_numbers[phone].total_count} />
        <PhonePopups.PlacesDisplay places={currentState} assignPlace={assignPlace}/>
        </>
        }
      </div>
      <div>
        <div className="button--invert popup__button" onClick={ () => { props.close() } }>{t("back", {ns: 'buttons'})}</div>
        <div className="button--back" onClick={Submit}>{t("save", {ns: 'buttons'})}<img src="../assets/icons/save.svg" /></div>
      </div>
    </PopupWrapper>
  )
}

export default PhonePopup;