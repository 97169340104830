import { FormEvent, useState } from "react";
import { Button, Input } from "../form";
import { SectionTitle } from "../titles";
import API from '../../helpers/api'
import Loading from "../loading/loading";
import { newMessage } from "../../features/messages/messagesSlice";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";

export const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'errors', 'success', 'inputs', 'buttons']);

   // function to handle inputs
  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setFormData((prevState) => ({...prevState, [name]: value}));
  }
  const changePassword = () => {

    if(formData.new_password === '' || formData.old_password === '') return setMessage(t("input_all_data", {ns: "errors"}));
    if(formData.new_password !== formData.confirm_new_password) return setMessage(t("invalid_password_repeat", {ns: "errors"}))
    if(formData.new_password.length < 8) return setMessage(t("invalid_password_lenght", {ns: "errors"}));
    if(formData.new_password === formData.old_password) return setMessage(t("invalid_password_same", {ns: "errors"}));

    if(loading) return; // ne diraj, zbog toga da se ne moze vise zahtjeva istih poslat
    // dodat disable atribut na botun tako da kad uneses promjenu mos stisnist spremi promejna

    // assign loading to true
    setLoading(true);
    
    API.put('/user/change/password', { query: formData }).then(() => { dispatch(newMessage({content: t("password_reset_success", {ns: "success"}), type: 'success'})); resetInput(); }).catch(() => setMessage(t("wrong_password", {ns: "errors"})));

    // set loading to false
    setLoading(false);

  }

  // function to reset state
  const resetInput = () => {
    setFormData({
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    });
    setMessage('');
  }

  if(loading)
    return <Loading />

  return (
    <div className='col-4'>
      <SectionTitle title={t("change_password_title")} />
      {message !== '' && <p className="error">{message}</p> /* Potrebno uredit*/}
      <Input label={t("old_password_label", {ns: 'inputs'})} type="password" value={formData.old_password} name="old_password" placeholder={t("old_password_placeholder", {ns: 'inputs'})} onChange={handleChanges}/>
      <Input label={t("new_password_label", {ns: 'inputs'})} type="password" value={formData.new_password} name="new_password" placeholder={t("new_password_placeholder", {ns: 'inputs'})} onChange={handleChanges}/>
      <Input label={t("new_password_repeat_label", {ns: 'inputs'})} type="password" value={formData.confirm_new_password} name="confirm_new_password" placeholder={t("new_password_repeat_placeholder", {ns: 'inputs'})} onChange={handleChanges}/>
      <Button text={t("change_password", {ns: 'buttons'})} type="save" onClick={changePassword}/>
    </div>
  );
}