import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { errorMsg, successMsg } from "../../features/user/userSlice";
import API from '../../helpers/api';
import FormWrapper, { Button, Input } from "../form";
import Loading from "../loading/loading";

interface iProps {
  hash?: string
}

export const SignForm = (props: iProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(state => state.user);
  // translation
  const {t} = useTranslation(['general', 'inputs', 'buttons', 'errors', 'success']);
  
  const [formData, setData] = useState({
    name: '',
    password: '',
    confirm_password: ''
  });

  const [loading, setLoading] = useState(false);

   // function to handle inputs
  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setData((prevState) => ({...prevState, [name]: value}));
    // if error msg, remove error msg
    error && dispatch(errorMsg({ msg: '' }))
  }

  const handleSignup = async () => {
    // fill in all data error if needed
    if(formData.name === "" || formData.password === "" || formData.confirm_password === "") return dispatch(errorMsg({ msg: t("input_all_data", {ns: 'errors'}) }));
    if(formData.password.length < 8) return dispatch(errorMsg({ msg: t("invalid_password_repeat", {ns: 'errors'}) }));
    if(formData.password !== formData.confirm_password) return dispatch(errorMsg({ msg: t("invalid_password_lenght", {ns: 'errors'}) }));
    // set loading screen if no errors in field values
    setLoading(true);

    try {
      // make a signup request
      let res = await API.post(`user/signup/${props.hash}`, { query: { ...formData }});
      // save user token to local storage
      localStorage.setItem('token', res.data.token);
      // dispatch success msg and replace to home
      dispatch(successMsg({ msg: t("signup_success", {ns: 'success'}) }));
      navigate('/', { replace: true });
    } catch(e: any) {
      setLoading(false);
      dispatch(errorMsg({ msg: e.response.data.error }));
    }
  }

  if(loading)
    return <Loading />

  return (
    <FormWrapper 
      title={t("signup_title")}
      description={t("signup_description_text")}
      error={error}
    >
      <Input label={t("name_label", {ns:'inputs'})} type="text" name="name" placeholder={t("name_placeholder", {ns:'inputs'})} onChange={handleChanges} />
      <Input label={t("password_label", {ns:'inputs'})} type="password" name="password" placeholder={t("password_placeholder", {ns:'inputs'})} onChange={handleChanges} />
      <Input label={t("password_repeat_label", {ns:'inputs'})} type="password" name="confirm_password" placeholder={t("password_repeat_placeholder", {ns:'inputs'})} onChange={handleChanges} />
      <Button text={t("submit", {ns:'buttons'})} onClick={handleSignup} />
    </FormWrapper>
  )
}