import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { _format } from '../../helpers';
import { invitationAPI } from './invitationAPI'
import { InviteScheme } from '../../components/invite/'

interface invitationScheme {
  hash: string,
  email: string,
  club_name: string,
  slug: string,  
  banner: string,
  role: number
}
const initialState = {
  invitations: {} as Record<string, invitationScheme>,
  sentInvitations: {} as Record<string, InviteScheme>,
};

// get invitations thunk
export const getInvitations = createAsyncThunk('invitations/get', invitationAPI.get);

// get sent invitations thunk
export const getSentInvitations = createAsyncThunk('invitations/sent/get', invitationAPI.getSent);

// accept invitation thunk
export const acceptInvitation = createAsyncThunk('invitations/accept', invitationAPI.accept);

// decline invitation thunk
export const declineInvitation = createAsyncThunk('invitations/decline', invitationAPI.decline);

// revoke invitation thunk
export const revokeInvitation = createAsyncThunk('invitations/revoke', invitationAPI.revoke);


// create user slice
const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    newInvitations: (state, { payload }) => {
      for(const invite of payload) {
        const { domain, num_floors, place_id, postal_code, suspend, type, name, ...inviteData } = invite;
        // find if there is same invitations
        let same_hash = Object.keys(state.sentInvitations).find(hash => state.sentInvitations[hash].slug === invite.slug && state.sentInvitations[hash].email === invite.email);
        // delete if same invitation
        same_hash && delete state.sentInvitations[same_hash];
        // add invite to sent invitations
        state.sentInvitations[invite.hash] = { ...inviteData, club_name: name };
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvitations.fulfilled, (state, { payload }) => {
      // store invitations
      state.invitations = _format(payload, "hash");
    })
    builder.addCase(getSentInvitations.fulfilled, (state, { payload }) => {
      // store sent invitations
      state.sentInvitations = _format(payload, "hash");
    })
    builder.addCase(acceptInvitation.fulfilled, (state, { payload }) => {
      // remove accepted invitation from invitations
      delete state.invitations[payload];
    })
    builder.addCase(declineInvitation.fulfilled, (state, { payload }) => {
      // remove rejected invitation from invitations
      delete state.invitations[payload];
    })
    builder.addCase(revokeInvitation.fulfilled, (state, { payload }) => {
      // remove revoked invitation from sent invitations
      delete state.sentInvitations[payload];
    })
  },
})

export const { newInvitations } = invitationSlice.actions;

export default invitationSlice.reducer;