import { useTranslation } from 'react-i18next';
import { HOSTESS_MODE, VIEW_MODE } from '..';
import { useAppDispatch } from '../../../app/hooks';
import EventsScheme from '../../../features/events/eventsEnum';
import { CloseFormIcon } from '../../icons';
import { ReservationRequestScheme, ReservationScheme, TableScheme } from '../enums';
import { confirmReservation } from './api-calls';
import ReservationDetails from './reservation-details';
import Features from './features';
import TableActions from './table-actions';
import { GOING_PHASE, TAKEOVER_PHASE } from '../../../helpers/eventPhases';
import { useEffect, useState } from 'react';
import NewReservation from './reservation-new';
import EditReservation from './reservation-edit';

interface CanvasFormScheme {
  formState: boolean,
  toggleForm: () => void,
  selectedTable: TableScheme | {},
  selectedReservation: ReservationScheme | {},
  availableTables: Record<string, TableScheme> | {},
  event: EventsScheme,
  event_phase: number,
  mode: number,
  requestToAccept: ReservationRequestScheme & { table_id?: number } | {},
  // for accept request from waiting list
  pickATableMode: boolean,
  exitPickATableMode: () => void,
  onConfirmTablePick: () => void,
  onNewTableSelect: (table_id: number) => void,
  acceptRequestToStack: () => void,
  // for merging tables
  mergeATableMode: boolean,
  startMergeATableMode: () => void,
  exitMergeATableMode: () => void,
  onConfirmTableMerge: () => void,
  // for table change
  changeATableMode: boolean,
  startChangeTableMode: () => void,
  exitChangeATableMode: () => void,
  onConfirmTableChange: () => void,
  moveTableToStack: () => void,
  selectedTableAfterPick: number,
  selectedStackReservationAfterPick: number
}

const CanvasForm = (props: CanvasFormScheme) => {
  // dispatch
  const dispatch = useAppDispatch();
  // states
  let [reservationDetailsPopup, setReservationDetailsPopup] = useState(false);
  let [editingReservation, setEditingReservation] = useState(false);
  // translation
  const { t } = useTranslation(['general', 'errors', 'success', 'buttons']);

  useEffect(() => {
    setEditingReservation(false);
  }, [props.selectedReservation])

  // Accept request mode
  if(props.pickATableMode)
    return (
      <div className={"canvas__form__outer-wrapper opened"}>
        <div className="canvas__form">
          {
            'id' in props.requestToAccept && <h3 className='canvas__form__h3'>{t("pick_a_table_for", {ns: 'general'})} <b>{props.requestToAccept.customer_name}</b></h3>
          }
          <div className="canvas__form__instructions label--gray">{t("pick_a_table_instruction", {ns: 'general'})}</div>
          <div className="canvas__form__actions">
            <div className="button--invert extra-small" onClick={props.exitPickATableMode}>{t("back", {ns: 'buttons'})}</div>
            <div className={"button extra-small" + (('id' in props.requestToAccept) && (!props.requestToAccept.table_id || props.requestToAccept.table_id === -1) ? " disabled" : "")} onClick={props.onConfirmTablePick}>{t("confirm", {ns: 'buttons'})}</div>
            <div className="button--invert extra-small" onClick={props.acceptRequestToStack}>{t("accept_to_stack", { ns: 'buttons' })}</div>
          </div>
        </div>
      </div>
    )


  // function enables and disables "confirm" button on change and merge a table action
  let getActionCondition = () => {
    if(props.changeATableMode)
      return ('id' in props.selectedReservation) && (
        ((props.selectedTableAfterPick === props.selectedReservation.table_id) || (props.selectedTableAfterPick === -1)) && 
        ((props.selectedStackReservationAfterPick === props.selectedReservation.id) || (props.selectedStackReservationAfterPick === -1))
      )
    else if(props.mergeATableMode)
      return ('id' in props.selectedReservation) && (props.selectedTableAfterPick === props.selectedReservation.table_id)
    else
      return false
  }

  let getActionInstruction = () => {
    if(props.changeATableMode)
      return 'change_a_table_instruction'
    else if(props.mergeATableMode)
      return 'merge_a_table_instruction'
    else
      return ''
  }

  // function called on exit action
  let exitAction = () => {
    if(props.changeATableMode)
      props.exitChangeATableMode();
    else if(props.mergeATableMode)
      props.exitMergeATableMode();
  }

  // function to confirm action
  let confirmAction = () => {
    if(props.changeATableMode)
      props.onConfirmTableChange();
    else if(props.mergeATableMode)
      props.onConfirmTableMerge();
  }

  // function to close the form or exit action
  let closeForm = () => {
    if(props.changeATableMode || props.mergeATableMode)
      exitAction()
    else
      props.toggleForm();
    // make sure that after closing form, editing is false
    setEditingReservation(false);
  }

  let confirmPresence = async () => {
    // protection
    if(!('id' in props.selectedReservation)) return;
    // make api call
    await confirmReservation({
      event: props.event,
      selectedReservation: props.selectedReservation
    }, dispatch, t)
  }

  return (
    <>
      <div className={
        "canvas__form__outer-wrapper" +
        (props.formState ? " opened" : "") +
        ((props.changeATableMode || props.mergeATableMode || props.pickATableMode) ? " in-action" : "")
      }>

        {/* Reservation actions */}
        <div className="canvas__form__fast-actions">
          {/* Table actions */}
          <TableActions
            mode={props.mode}
            reservation={props.selectedReservation}
            table={props.selectedTable}
            // start action mode callbacks
            onSwitchTableClick={props.startChangeTableMode}
            onMergeATableClick={props.startMergeATableMode}
            onMoveToStackClick={props.moveTableToStack}
            // this is used when change or merge a table mode is turned on
            isAction={props.changeATableMode || props.mergeATableMode}
            actionInstruction={getActionInstruction()}
            actionCondition={getActionCondition()}
            onExitAction={exitAction}
            onConfirmAction={confirmAction}
          />
          {
            // Confirm presence action
            (props.event_phase === TAKEOVER_PHASE || props.event_phase === GOING_PHASE) && 'id' in props.selectedReservation &&
              <div className={"canvas__form__presence" + (props.selectedReservation.confirmed ? " confirmed" : "")} onClick={confirmPresence}>
                {
                  props.selectedReservation.confirmed ? 
                    t('presence_confirmed', { ns: 'buttons' })
                  :
                    t('confirm_presence', { ns: 'buttons' })
                }
              </div>
          }
          {/* Close form action */}
          <div className="canvas__form__close" onClick={closeForm}>
            <CloseFormIcon />
          </div>
        </div>

        {/* Reservation info */}
        <div className={"canvas__form" + ((props.mode === HOSTESS_MODE && !('id' in props.selectedReservation)) ? " hidden" : "")}>
          {/* If reservation exists */}
          {
            'id' in props.selectedReservation &&
              <div className="canvas__form__reservation-data">
                <div className="canvas__form__guest-info">
                  <div className="canvas__form__label label--gray">{t("name_label", {ns: 'inputs'})}</div>
                  <div className="canvas__form__guest-name">{ props.selectedReservation.customer_name }</div>
                </div>
                {
                  (props.mode === VIEW_MODE) &&
                    <Features 
                      reservation={props.selectedReservation}
                      onReservationDetailsClick={() => setReservationDetailsPopup(prev => !prev)}
                      onEditClick={() => setEditingReservation(prev => !prev)}
                    />
                }
              </div>
          }
          {
            (props.mode === VIEW_MODE) && 'id' in props.selectedReservation &&
              <EditReservation
                event={props.event}
                selectedReservation={props.selectedReservation}
                isEditing={editingReservation}
                stopEditing={() => setEditingReservation(false)}
              />
          }
          {/* New reservation form */}
          {
            (props.mode === VIEW_MODE) && !('id' in props.selectedReservation) && 'id' in props.selectedTable &&
              <NewReservation
                event={props.event}
                selectedTable={props.selectedTable}
              />
          }
        </div>
      </div>
      {/* Reservation details popup */}
      {
        (props.mode === VIEW_MODE) &&
          <ReservationDetails
            reservation={props.selectedReservation}
            isOpened={reservationDetailsPopup}
            close={() => setReservationDetailsPopup(false)}
          />
      }
    </>
  )
}

export default CanvasForm;