import { useState, useLayoutEffect, useRef } from "react";
import * as googlemaps from './loadScript.js';

const SearchLocationInput = (props:any) => {
  const [query, setQuery] = useState(props.address);
  const autoCompleteRef = useRef(null);

  useLayoutEffect(() => {
    googlemaps.loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyClQftK9pzK0ICJ_JDO6A7smJTAOT3OzCM&libraries=places`,
      () => googlemaps.handleScriptLoad(setQuery, autoCompleteRef, props.assignAddress)
    );
  }, []);
  

  return (
    <div className="input-wrapper">
      <div className="label input__label">{props.label}</div>
      <input
        name="club_address"
        ref={autoCompleteRef}
        placeholder={props.placeholder}
        defaultValue={props.address}
      />
    </div>
  );
}

export default SearchLocationInput;