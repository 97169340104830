import { useTranslation } from 'react-i18next';
import {roles} from './rolesHelpers'

interface iProps  {
  max_role: number,
  setRole: (role:number) => void,
  role: number
}

export const SelectRole = (props:iProps) => {

  const {t} = useTranslation(['general', 'roles']);

  return (<div className="staff-details__role input-wrapper">
    <div className="label input__label">{t("role")}</div>
    <div className="select-wrapper">
      <div className="select-arrow"><img src="../assets/icons/select-arrow.svg" /></div>
      <select name="staff-role" value={roles[props.role].value} onChange={ (e) => props.setRole(e.target.selectedIndex) }>
        { roles.map((role, index) => index < props.max_role && <option key={index} value={role.value}>{t(role.name, {ns: 'roles'})}</option>) }
      </select>
    </div>
  </div>);
}

export const DisplayRole = (props: {role:number}) => {
  const {t} = useTranslation(['general', 'roles'])
  return (
  <div className="staff-details__role input-wrapper">
    <div className="label input__label">{t("role")}</div>
    <h2>{t(roles[props.role].name, {ns: 'roles'})}</h2>
  </div>);
}