import * as THREE from 'three';
import { BufferGeometry } from 'three';
import { _format, _load, loadObject, parseHSTore } from '../../helpers';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import config, { canvas_config } from '../../app/config';
import PlacesScheme from '../../features/places/placesEnum';
import THREEx from './threex.domevents';
import { io, Socket } from "socket.io-client";
import CameraScheme, { TableScheme, ReservationsScheme, ReservationTriggerScheme, InProgressTriggerScheme, ServerToClientEvents, ClientToServerEvents, ReservationRequestScheme, RequestTriggerScheme, ReservationScheme, TableReservationScheme, RequestAttributes } from "./enums";
import download2d, { getFloorImage } from './print';
import EventsScheme from '../../features/events/eventsEnum';
import { getLabelBG, getLabelColor, makeTextSprite, updateTextSpriteTexture } from '../../helpers/canvas';

const LIMITED_COLOR = new THREE.Color(0x8E2A2A); //A21414
const DARK_LIMITED_COLOR = new THREE.Color(0x8E2A2A); // 541919
const NORMAL_COLOR = new THREE.Color(0x999999);
const WHITE_COLOR = new THREE.Color(0xFFFFFF);
const RESERVED_COLOR = new THREE.Color(0xC9A911); // 7B9E89
const IN_PROGRESS_COLOR = new THREE.Color(0x666666);
const SELECTED_COLOR = new THREE.Color(0x1AC87C); // 33FFDA // 80FFE8 // 00CCA7
const LEAD_COLOR = new THREE.Color(0x1AC87C); // 0x8E2A2A //A21414 // 80FFE8 // 00CCA7
const PRESENCE_COLOR = new THREE.Color(0x222222);


class CanvasActions {
  mode: number;
  scene = new THREE.Scene();
  camera: THREE.OrthographicCamera;
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
  renderer: THREE.WebGLRenderer;
  tables: Record<string, TableScheme>;
  tablesBySections: Record<string, number[]>;
  geometries: Record<string, BufferGeometry>;
  club: PlacesScheme;
  event_id: string | undefined;
  event: EventsScheme | undefined;
  floor: number;
  locale: THREE.Mesh[];
  domEvents: THREEx.DomEvents;
  onLoaded: () => void;
  setLoading: () => void;
  onDisconnect: () => void;
  onLimit: (id: number) => void;
  updateFloor: (floor: number) => void;
  toggleForm: (state: boolean) => void;
  selectTable: (table: TableScheme | {}) => void;
  setAvailableTables: (tables: Record<string, TableScheme> | {}) => void;
  setSelectedReservation: (reservation: ReservationScheme | {}) => void;
  setLimitedTables: (tables: number[]) => void;
  updateTables: (tables: Record<string, TableScheme> | {}) => void;
  updateReservations: (reservations: Record<string, TableReservationScheme> | {}, reservationsInStack: Record<string, ReservationScheme> | {}) => void;
  updateWaitingList: (waitingList: Record<string, ReservationRequestScheme>) => void;
  tablePicked: (table_id: number, request_id: number, reservation_id?: number) => void;
  formState: boolean;
  socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;
  reservations: Record<string, TableReservationScheme>;
  reservationsInStack: Record<string, ReservationScheme>;
  reservationStackIndex: number;
  selectedTable: number;
  selectedStackReservation: number;
  availableTables: Record<string, TableScheme>;
  inProcess: string[];
  leading: number | null;
  leadingReservation: number | null;
  disconnectMessage: boolean;
  toLimit: number[];
  toDelimit: number[];
  firstLoad: boolean;
  labels: boolean;
  moveTableID: number | null;
  reservationRequests: Record<string, ReservationRequestScheme>;
  requestTable: number;
  requestStackReservation: number;
  requestID: number;
  changeTable: boolean;
  cameraSetup: CameraScheme | undefined;
  stackStartPosition: number[];
  stackRowCount: number;

  constructor(canvas: HTMLCanvasElement, event: EventsScheme | undefined, club: PlacesScheme, mode: number) {
    this.mode = mode;
    this.club = club;
    this.event_id = event ? String(event.id) : undefined;
    this.event = event;
    this.canvas = canvas;
    this.width = canvas.clientWidth;
    this.height = canvas.clientHeight;
    this.tables = {};
    this.tablesBySections = {};
    this.geometries = {};
    this.floor = 0;
    this.locale = [];
    this.socket = undefined;
    this.onLoaded = () => {};
    this.setLoading = () => {};
    this.onDisconnect = () => {};
    this.onLimit = () => {};
    this.updateFloor = () => {};
    this.toggleForm = () => {};
    this.selectTable = () => {};
    this.setLimitedTables = () => {};
    this.setAvailableTables = () => {};
    this.setSelectedReservation = () => {};
    this.updateTables = () => {};
    this.updateReservations = () => {};
    this.updateWaitingList = () => {};
    this.tablePicked = () => {};
    this.formState = false;
    this.selectedTable = -1;
    this.selectedStackReservation = -1;
    this.reservations = {};
    this.reservationsInStack = {};
    this.reservationStackIndex = 0;
    this.availableTables = {};
    this.inProcess = [];
    this.leading = null;
    this.leadingReservation = null;
    this.firstLoad = false;
    this.disconnectMessage = true;
    this.toLimit = [];
    this.toDelimit = [];
    this.labels = false;
    this.moveTableID = null;
    this.reservationRequests = {};
    this.requestTable = -1;
    this.requestStackReservation = -1;
    this.requestID = -1;
    this.changeTable = false;
    this.stackStartPosition = [500, 0, -300];
    this.stackRowCount = 5;
    this.camera = new THREE.OrthographicCamera();
    this.renderer = new THREE.WebGLRenderer();
    this.domEvents = new THREEx.DomEvents();
    this.cameraSetup = undefined;
    // setup scene background
    this.scene.background = new THREE.Color( 0x1C1B19 );
    // load everything up
    this.init();
  }

  // function to load and setup everything
  async init() {
    try {
      // setup camera
      await this.setupCamera();
      // setup renderer
      this.setupRenderer();
      // register dom events on camera and renderer
      this.domEvents = new THREEx.DomEvents(this.camera, this.renderer.domElement)
      // setup orbit controls
      this.setupControls();
      // load and add locale to the scene
      await this.loadLocale();
      // load and setup lights
      await this.setupLights();
      // load tables firstly
      await this.loadTables();
      // then load geometries
      await this.loadGeometries();
      // if event exists (don't connect to socket if creating event)
      if(this.event_id) {
        // connect to the socket
        this.socket = io(config.socket, {
          transports: ["websocket"],
          auth: {
            auth_token: `Bearer ${localStorage.getItem('token')}`,
            club: this.club.slug,
            event_id: this.event_id || ''
          }
        });
        // attach socket listeners
        this.initSocket();
      }
      // and then draw tables on the canvas
      await this.firstRender();
      // check if unsaved limit tables exists
      if(!this.event_id && this.mode === 2)
        this.paintUnsavedLimit()
      // render the scene for the end
      this.renderer.render(this.scene, this.camera);
      // show canvas and remove loader
      this.onLoaded();
      this.firstLoad = true;
    } catch(e) {
      console.log(e);
      throw new Error("Error with canvas")
    }
  }

  // function to setup camera
  async setupCamera() {
    // load camera setup from server
    this.cameraSetup = await _load(`entertainment/camera/${this.club.slug}`);
    // protection
    if(!this.cameraSetup) return;
    // setup camera factor
    let factor = this.cameraSetup.camera_factor;
    // camera setup
    this.camera = new THREE.OrthographicCamera( this.width / -factor, this.width / factor, this.height / factor, this.height / -factor, 1, 1000 );
    this.camera.position.set(0, this.cameraSetup.camera_distance, 0);
    this.camera.lookAt(0, 0, 0);
    // store stack setup
    this.stackStartPosition = this.cameraSetup.stack_start_position;
    this.stackRowCount = this.cameraSetup.stack_row_count;
  }

  // function to setup renderer
  setupRenderer() {
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, preserveDrawingBuffer: true });
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.render(this.scene, this.camera);
  }

  // function to setup orbit controls
  setupControls() {
    let controls = new OrbitControls( this.camera, this.renderer.domElement );
    controls.target.set( 0, 0, 0 );
    // controls.enableRotate = false;
    controls.enableZoom = true;
    // left mouse click used for moving as on 2d map, wheel and right click are not needed
    controls.mouseButtons = { LEFT: THREE.MOUSE.PAN, MIDDLE: THREE.MOUSE.MIDDLE, RIGHT: THREE.MOUSE.RIGHT };
    // one finger is used for moving verticaly or horizontaly, and 2 fingers can do the same and also zoom in or out
    controls.touches = { ONE: THREE.TOUCH.PAN, TWO: THREE.TOUCH.DOLLY_PAN };
    // add event listener on change to re-render the scene
    controls.addEventListener('change', () => this.renderer.render(this.scene, this.camera));
  }

  // function to load locale
  async loadLocale() {
    for(let i = 0; i < this.club.num_floors; i++) {
      // load locale geometry
      let geometry = await loadObject(`${config.assets}${this.club.slug}/objects/interior_${i}.stl`);
      // create new mesh from geometry
      this.locale[i] = new THREE.Mesh(geometry, new THREE.MeshStandardMaterial({ color: 0xAAAAAA }));
      // set locale position
      this.locale[i].position.set(0,0,0);
      // set locale rotation
      this.locale[i].rotation.set(0,0,0);
      // compute vertices, make mesh smoother
      this.locale[i].traverse(child => {
        if( child instanceof THREE.Mesh )
          child.geometry.computeVertexNormals();
      });
      // give a name to locale floor
      this.locale[i].name = `floor_${i}`;
    }
    // add table to the scene
    this.scene.add(this.locale[this.floor]);
  }

  // function to load and setup lights
  async setupLights() {
    let lights = await _load(`lights/${this.club.slug}`);
    for(let light of lights){
      // light.val = new THREE.DirectionalLight( light.color, light.intensity );
      light.val = new THREE.DirectionalLight( 0xffffff, 1 );
      light.val.position.set(...light.position);
      // light.val.lookAt(...light.lookAt);
      this.scene.add(light.val);
    }
  }

  // function to load tables configuration from API
  async loadTables() {
    this.tables = _format(await _load(`tables/${this.club.slug}`), 'id');
    // send to react canvas component state
    this.updateTables({ ...this.tables });
    // group tables by section id
    for(let table of Object.values(this.tables)) {
      // create new section key if it doesn't exist
      if(!this.tablesBySections[table.section_id])
        this.tablesBySections[table.section_id] = []
      // push table to section
      this.tablesBySections[table.section_id].push(table.id)
    }
  }
  
  // function to load table and details geometries
  async loadGeometries() {
    // load table geometries
    await Promise.all(Object.values(this.tables).map(async (obj) => {
      this.geometries[obj.id] = await loadObject(`${config.assets}${this.club.slug}${obj.file}`);
    }));
  }

  // function to create THREE.Mesh and match it with table foreach table, and draw tables from the default floor on the scene
  async firstRender() {
    for(let obj of Object.values(this.tables)){
      // create new mesh from geometry
      obj.mesh = new THREE.Mesh(this.geometries[obj.id], new THREE.MeshStandardMaterial({ color: 0xC2C2C2 }));
      // set table position
      obj.mesh.position.set(obj.position[0], obj.position[1], obj.position[2]);
      // set table rotation
      obj.mesh.rotation.set(obj.rotation[0], obj.rotation[1], obj.rotation[2]);
      // compute vertices, make mesh smoother
      obj.mesh.traverse(child => {
        if( child instanceof THREE.Mesh )
          child.geometry.computeVertexNormals();
      });
      // set object id as mesh name so we can remove it easily
      obj.mesh.name = `${obj.id}`;
      // if it's on floor 0 (first render starting at floor 0)
      if(obj.floor === this.floor) {
        // add event listener on table click and touch for mobiles
        this.domEvents.addEventListener(obj.mesh, 'click', this.onTableClick.bind(this), false);
        this.domEvents.addEventListener(obj.mesh, 'touchstart', this.onTableClick.bind(this), false);
        // add table to the scene
        this.scene.add(obj.mesh);
      }
    }
  }

  // function to change floor
  changeFloor(floor: number, isChangingTable?: boolean) {
    // check if new floor is the same as current 
    if(this.floor === floor) return;
    // restore form state (close form and paint selected table to normal if any is selected)
    isChangingTable === undefined && this.restoreFormState();
    // remove objects from previous floor and add objects from new floor
    this.handleTableView(floor);
    // remove old and add new locale floor
    this.handleLocaleView(floor);
    // if labels are turned on
    if(this.labels)
      this.removeNameLabelsForCurrentFloor()
    // store new floor number
    this.floor = floor;
    // if labels are turned on, display them for new floor
    if(this.labels)
      this.displayNameLabelsForCurrentFloor()
    // render new scene
    this.renderer.render(this.scene, this.camera);
    // change floor in nav
    this.updateFloor(floor);
  }

  // function to handle table view (remove or add table depending on floor number)
  handleTableView(floor: number) {
    // remove objects from previous floor and add objects from new floor
    for(let obj of Object.values(this.tables))
      if(obj.floor === this.floor) {
        // remove it's event listeners
        this.domEvents.removeEventListener(obj.mesh, 'click', this.onTableClick.bind(this), false)
        this.domEvents.removeEventListener(obj.mesh, 'touchstart', this.onTableClick.bind(this), false)
        // remove single table from current floor
        this.scene.remove(obj.mesh);
      } else if(obj.floor === floor){
        // add table from the new floor to the scene
        this.scene.add(obj.mesh);
        // add it's event listeners 
        this.domEvents.addEventListener(obj.mesh, 'click', this.onTableClick.bind(this), false);
        this.domEvents.addEventListener(obj.mesh, 'touchstart', this.onTableClick.bind(this), false);
      }
  }

  // function to handle locale view (remove old and add new locale floor depending on floor number)
  handleLocaleView(floor: number) {
    // remove floor interior from previous floor
    this.scene.remove(this.locale[this.floor])
    // add new floor interior to the scene
    this.scene.add(this.locale[floor]);
  }

  // function called on table click (handle clicks depending on canvas mode)
  onTableClick(e: any) {
    e.stopPropagation();
    // table in process is not clickable, so return
    if(this.inProcess.indexOf(String(e.target.name)) !== -1) return;
    // if pick a table mode is turned on
    if(this.requestID !== -1)
      return this.handlePickATableClick(e);
    // handle table click depending on canvas mode
    if(this.mode === 1) {
      // mode 1 is view reservation mode so handle reservations
      this.handleReservationClick(e);
    } else if (this.mode === 2) {
      // tables reserved by guest can't be clicked in LIMIT mode
      if(this.reservations[e.target.name] && this.reservations[e.target.name].type !== 1) return;
      // mode 2 is restriction mode so handle limit tables
      this.handleLimitClick(e);
    } else if (this.mode === 3) {
      // mode 3 is hostess mode so handle reservations
      this.handleReservationClick(e);
    }
    // render new scene
    this.renderer.render(this.scene, this.camera);
  }

  // function to handle table click for pick a table funcitonalities (accept request, change a table, merge tables)
  handlePickATableClick(e: any) {
    // picked table reservation
    let reservation = this.getReservationByTable(e.target.name);
    // can't pick reserved table
    if(reservation !== -1 && !this.changeTable) return;
    // if other table is already selected, remove it's color
    if(this.requestTable !== -1 && this.requestTable !== this.selectedTable) {
      this.returnTableColor(this.requestTable);
      // return label color from already selected table
      if(this.labels && this.requestTable !== -1 && this.reservations[this.requestTable])
        this.replaceSingleNameLabel(this.reservations[this.requestTable], false)
    }
    // remove color from previously picked stack reservation
    if(this.requestStackReservation !== -1 && this.reservationsInStack[this.requestStackReservation])
      this.replaceSingleNameLabel(this.reservationsInStack[this.requestStackReservation], false);
    // store picked table
    this.requestTable = e.target.name;
    // if picked table has reservation, paint it's name label
    if(this.labels && this.requestTable !== -1 && this.reservations[this.requestTable])
      this.replaceSingleNameLabel(this.reservations[this.requestTable], true)
    // send callback to main component
    this.tablePicked(e.target.name, this.requestID);
    // paint table to selected color
    this.paintTable(this.requestTable, SELECTED_COLOR);
    // stop blinking when user selects table
    if(this.requestID > 0 && this.requestTable !== -1 && this.reservationRequests[this.requestID] && this.reservationRequests[this.requestID].section_id) {
      // paint tables in section
      for(let table_id of this.tablesBySections[this.reservationRequests[this.requestID].section_id]) {
        // we don't blink already reserved tables inside section
        if(this.reservations[table_id] || table_id === this.requestTable) continue;
        // return to normal color
        this.paintTable(table_id, NORMAL_COLOR);
      }
    }
    // render new scene and then exit function
    return this.renderer.render(this.scene, this.camera);
  }

  // function to handle table click in VIEW RESERVATION mode
  handleReservationClick(e: any, table_id?: number) {
    // send only table id (used for doubleClick on guest in guest list)
    if(table_id) {
      e.target = {};
      e.target.name = table_id;
      // change floor number if needed
      if(this.floor !== this.tables[table_id].floor)
        this.changeFloor(this.tables[table_id].floor);
    }
    let shouldToggleForm = true;
    const isTableSelected = this.selectedTable !== -1;
    const isNewTable = this.selectedTable !== e.target.name;
    const isStackReservationSelected = this.selectedStackReservation !== -1;
    // if stack reservation is already selected, return it's color
    if(isStackReservationSelected && this.reservationsInStack[this.selectedStackReservation])
      this.replaceSingleNameLabel(this.reservationsInStack[this.selectedStackReservation], false)
    // return table color based on reservation type and existance
    if(isTableSelected) {
      this.returnTableColor(this.selectedTable);
      // if labels are turned on, add name label if exists to previously selected table
      if(this.labels && this.reservations[this.selectedTable])
        this.replaceSingleNameLabel(this.reservations[this.selectedTable], false)
    }
    // skip form toggling
    if(isNewTable && (isTableSelected || isStackReservationSelected))
      shouldToggleForm = false;
    // store new selected table id
    this.selectedTable = e.target.name;
    // check if is clicked on another table and not selected one
    if(isNewTable)
      this.paintTable(this.selectedTable, SELECTED_COLOR);
    else
      this.selectedTable = -1; // remove selected table because user clicked twice on selected one

    if(this.labels && this.selectedTable !== -1 && this.reservations[this.selectedTable])
      this.replaceSingleNameLabel(this.reservations[this.selectedTable], true)
    // re-render painted table
    this.renderer.render(this.scene, this.camera);
    // hook attached to component state (pass selected table to canvas component)
    this.selectTable(this.selectedTable !== -1 ? this.tables[this.selectedTable] : {});
    // selected table reservation
    let reservation = this.getReservationByTable(this.selectedTable);
    this.setSelectedReservation(reservation !== -1 ? reservation : {});
    // toggle form state
    shouldToggleForm && this.toggleFormState();
  }

  // function to handle table click in LIMIT mode (prereservations)
  handleLimitClick(e: any) {
    if((<any> this.tables[e.target.name].mesh.material).color.equals(LIMITED_COLOR))
      this.returnTableColor(e.target.name);
    else
      this.paintTable(e.target.name, LIMITED_COLOR);
    // call callback
    this.onLimit(e.target.name);
  }

  paintUnsavedLimit() {
    // return all not reserved tables to normal color
    for(let table of Object.keys(this.tables))
      if(this.reservations && this.reservations[table])
        this.paintReservation(this.reservations[table]);
      else
        this.paintTable(parseInt(table), NORMAL_COLOR);
    // limit array (paint to LIMITED)
    for(let table of this.toLimit)
      this.paintTable(table, LIMITED_COLOR);
    // delimit array (return table color)
    for(let table of this.toDelimit)
      this.paintTable(table, NORMAL_COLOR);
  }

  // function to remove limit click on process trigger in LIMIT mode
  removeLimitClick(table_id: number) {
    if((<any> this.tables[table_id].mesh.material).color.equals(LIMITED_COLOR))
      this.onLimit(table_id);
  }

  // function to check if table is in reservation process by it's table color
  isTableInProcess(table_id: number) {
    if((<any> this.tables[table_id].mesh.material).color.equals(IN_PROGRESS_COLOR))
      return true;

    return false;
  }

  // function to attach socket listeners
  initSocket() {
    this.socket!.on("connect", () => this.firstLoad && this.onLoaded());
    this.socket!.on("init", data => this.displayReservations(data));
    this.socket!.on("reservations", data => this.onReservationTrigger(data));
    this.socket!.on("available_tables", data => this.onReservationProgressTrigger(data));
    this.socket!.on("requests", data => this.onRequestTrigger(data));
    this.socket!.on("disconnect", () => this.setLoading());
    // this.socket!.on("disconnect", data => this.disconnectMessage && this.onDisconnect());
  }

  // function to display all reservations fetched while initializing
  displayReservations(data: ReservationsScheme) {
    const { reservations, process_tables, reservations_requests } = data;
    // store reservation requests
    this.reservationRequests = _format(reservations_requests, 'id');
    // send reservation requests to state
    this.updateWaitingList(this.reservationRequests);
    // set all tables as available
    this.availableTables = {...this.tables};
    // store limited tables so we can pass them to redux (needed for unlimiting)
    let limitedTables: number[] = [];
    // loop through reservations
    for(let item of reservations) {
      // if reservation is on table
      if(item.table_id) {
        // store reservation to JSON object
        this.reservations[item.table_id] = item as TableReservationScheme;
        // paint table in dependency of reservation type
        this.paintReservation(item as TableReservationScheme);
        // as this table is reserved, remove it from available tables
        delete this.availableTables[item.table_id];
        // only limited (type 1)
        if(item.type === 1)
          limitedTables.push(item.table_id)
      } else {
        // if reservation is not on table, it means it's in stack so add reservation to stack object
        this.reservationsInStack[item.id] = item;
      }
      // parse request attributes -> only needed on init
      if(item.request_attributes) {
        // parse item request attributes 
        item.request_attributes = parseHSTore(item.request_attributes);
      }
    }
    // loop throught tables that are currently in reservation process
    for(let id of process_tables) {
      this.paintTable(parseInt(id), IN_PROGRESS_COLOR);
      // as this table is in reservation process, remove it from available tables
      delete this.availableTables[id];
    }
    // store tables in process to array (used when changing floors)
    this.inProcess = process_tables;
    // check if unsaved limit tables exists
    if(this.mode === 2)
      this.paintUnsavedLimit()
    // name labels are turned on by default
    if(canvas_config.nameLabels)
      this.toggleNameLabels(true)
    // display stack reservations
    this.displayStackReservations();
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // set available tables
    this.setAvailableTables(this.availableTables);
    // send limited tables to redux
    this.setLimitedTables(limitedTables);
    // send to canvas component state
    this.updateReservations(this.reservations, this.reservationsInStack);
  }

  // function to display stack reservations
  displayStackReservations() {
    // reset stack index (if reconnected to socket now it won't double the same reservations)
    this.reservationStackIndex = 0;
    // loop through stack reservations
    for(let reservation of Object.values(this.reservationsInStack)){
      // if sprite was already defined (on reconnect) remove it
      if(reservation.sprite)
        this.removeSingleNameLabel(reservation);
      // create reservation sprite
      this.appendSingleStackReservation(reservation);
    }
  }

  // function to re-order stack positions if one of reservations from stack got deleted
  reorderReservationStack(fromIndex: number) {
    for(let reservation of Object.values(this.reservationsInStack)) {
      // we want to re-order only those with higher index then one that got deleted
      if(reservation.sprite?.userData.onIndex <= fromIndex) continue;
      // re-calculate positions
      let recalculatedPositions = [
        this.stackStartPosition[0] + Math.floor((reservation.sprite?.userData.onIndex - 1) / this.stackRowCount)*100, 
        this.stackStartPosition[1], 
        this.stackStartPosition[2] + ((reservation.sprite?.userData.onIndex - 1) % this.stackRowCount)*60
      ]
      // update new sprite onIndex value
      reservation.sprite!.userData.onIndex--;
      // set positions
      reservation.sprite?.position.set(recalculatedPositions[0]+(38-(reservation.sprite?.userData.textWidth-38)/8), 150, recalculatedPositions[2]+17-8*(reservation.sprite?.userData.lines.length-1))
    }
    // one reservation got deleted, so update stack index (decrease)
    this.reservationStackIndex--;
  }

  // function to append single stack reservation
  appendSingleStackReservation(reservation: ReservationScheme) {
    this.addSingleNameLabel(reservation, 
      [this.stackStartPosition[0] + Math.floor(this.reservationStackIndex / this.stackRowCount)*100, 
      this.stackStartPosition[1], 
      this.stackStartPosition[2] + (this.reservationStackIndex % this.stackRowCount)*60])
    // increment index where we left of (needed for triggers so we know where to append reservations in stack)
    this.reservationStackIndex++;
  }

  onStackReservationClick(e: any, reservation_id?: number) {
    // on double click from guest list
    if(reservation_id) {
      e.target = {};
      e.target.name = reservation_id;
    }
    // not possible to click stack reservation when accepting request or duplicating tables
    if(this.requestID === -2) return;
    // you can't change reservations inside stack
    if(this.requestID === -3 && this.changeTable && this.selectedTable === -1) return;
    // if change a table mode is turned on (swap table and stack reservations)
    if(this.requestID === -3 && this.changeTable) {
      // picked table reservation
      let reservation = this.reservationsInStack[e.target.name];
      // protection
      if(!reservation) return;
      // if other table is already selected, remove it's color
      if(this.requestTable !== -1 && this.requestTable !== this.selectedTable) {
        this.returnTableColor(this.requestTable);
        // return label color from already selected table
        if(this.labels && this.requestTable !== -1 && this.reservations[this.requestTable])
          this.replaceSingleNameLabel(this.reservations[this.requestTable], false)
      }
      // remove color from previously picked stack reservation
      if(this.requestStackReservation !== -1 && this.requestStackReservation !== reservation.id)
        this.replaceSingleNameLabel(this.reservationsInStack[this.requestStackReservation], false);
      // paint stack reservation
      this.replaceSingleNameLabel(reservation, true);
      // store picked stack reservation
      this.requestStackReservation = reservation.id;
      // send callback to main component
      this.tablePicked(-1, this.requestID, reservation.id);
      // re-render because we painted stack reservation
      return this.renderer.render(this.scene, this.camera);
    }
    // boolean if form should toggle, depends if table is selected
    let shouldToggleForm = true;
    // check if any table is selected
    const isTableSelected = this.selectedTable !== -1;
    const isStackReservationSelected = this.selectedStackReservation !== -1;
    const isNewStackReservation = this.selectedStackReservation !== e.target.name;
    // if any is selected, return table color based on reservation type and existance
    if(isTableSelected) {
      this.returnTableColor(this.selectedTable);
      // if labels are turned on, add name label if exists to previously selected table
      if(this.labels && this.reservations[this.selectedTable])
        this.replaceSingleNameLabel(this.reservations[this.selectedTable], false)
    }
    // if stack reservation is already selected, return it's color
    if(isStackReservationSelected && this.reservationsInStack[this.selectedStackReservation])
      this.replaceSingleNameLabel(this.reservationsInStack[this.selectedStackReservation], false)
    // skip form toggling
    if(isNewStackReservation && (isTableSelected || isStackReservationSelected))
      shouldToggleForm = false;
    // remove selected table (because reservation in stack doesn't have table)
    this.selectedTable = -1;
    // if is new stack reservation selected, paint it
    if(isNewStackReservation) {
      // set new stack reservation selected
      this.selectedStackReservation = e.target.name;
      // paint stack reservation to green (selected)
      if(this.selectedStackReservation != -1 && this.reservationsInStack[this.selectedStackReservation])
        this.replaceSingleNameLabel(this.reservationsInStack[this.selectedStackReservation], true)
    } else {
      this.selectedStackReservation = -1;
    }
    // re-render painted table
    this.renderer.render(this.scene, this.camera);
    // hook attached to component state (pass selected table to canvas component, in this case remove selected table)
    this.selectTable({})
    // selected table reservation
    let reservation = this.reservationsInStack[e.target.name];
    this.setSelectedReservation(reservation ? reservation : {});
    // toggle form state
    shouldToggleForm && this.toggleFormState();
  }

  // funtion to handle database trigger for new or deleted reservation
  async onReservationTrigger(data: ReservationTriggerScheme) {
    const { reservation, status } = data;
    // decide action based on status
    if(status === 'add') {
      // if reservation is added to table
      if(reservation.table_id) {
        // if current user is changing reserved table, stop blinking
        if(this.moveTableID === reservation.table_id)
          this.stopMoving();
        if(this.requestTable == reservation.table_id)
          this.removeRequestTable();
        // remove table from in Process array
        let index = this.inProcess.indexOf(String(reservation.table_id));
        if(index !== -1)
          this.inProcess.splice(index, 1);
        // if form is opened, and reservation for opened tables comes from another dashboard member
        if(this.selectedTable == reservation.table_id) {
          // selected table reservation
          this.setSelectedReservation(reservation);
        } else {
          // paint table in dependency of reservation type
          this.paintReservation(reservation as TableReservationScheme);
        }
        // store or update reservation in JSON object
        this.reservations[reservation.table_id] = reservation as TableReservationScheme;
        // as this table is now reserved, remove it from available tables
        delete this.availableTables[reservation.table_id];
        // if labels is turned on, add name label from new table
        if(this.labels && this.reservations[reservation.table_id])
          this.addSingleNameLabel(this.reservations[reservation.table_id])
      } else {
        // if reservation is added to stack
        // store or update reservation in JSON object
        this.reservationsInStack[reservation.id] = reservation;
        // append new stack reservation
        this.appendSingleStackReservation(reservation)
      }
    } else if(status === 'update') {
      const { old_table_id } = data;
      // temporary variable for stackIndex
      let tempLastStackIndex = -1;
      // if current user is changing reservation table, stop blinking
      if(this.moveTableID === reservation.table_id)
        this.stopMoving();
      if(this.requestTable == reservation.table_id)
        this.removeRequestTable();
      // if there is old_table_id defined
      if(!!old_table_id && this.reservations[old_table_id].id === reservation.id) {
        // if labels is turned on, remove name label from old table
        if(this.labels && this.reservations[old_table_id]) {
          this.removeSingleNameLabel(this.reservations[old_table_id])
        }
        // delete old reservation
        delete this.reservations[old_table_id];
        // remove old table color (set back to normal)
        this.returnTableColor(old_table_id);
        // add this table to available tables since it's now not reserved anymore
        this.availableTables[old_table_id] = this.tables[old_table_id];
      } else {
        // if reservation was in stack, remove it
        if(this.reservationsInStack[reservation.id]) {
          // stack -> stack update (e.g. name change)
          if(!reservation.table_id) {
            // store last Stack Index
            tempLastStackIndex = this.reservationStackIndex;
            // prepare stack index for update
            this.reservationStackIndex = this.reservationsInStack[reservation.id].sprite?.userData.onIndex;
          } else {
            // re-order Stack from deleted reservation
            this.reorderReservationStack(this.reservationsInStack[reservation.id].sprite?.userData.onIndex);
          }
          // remove reservation label
          this.removeSingleNameLabel(this.reservationsInStack[reservation.id]);
          // remove from stack object
          delete this.reservationsInStack[reservation.id];
        }
      }
      if(reservation.table_id) {
        // store or update reservation in JSON object
        this.reservations[reservation.table_id] = reservation as TableReservationScheme;
        // as this table is now reserved, remove it from available tables
        delete this.availableTables[reservation.table_id];
        // paint table in dependency of reservation type
        this.paintReservation(reservation as TableReservationScheme);
        // if labels is turned on, add name label from new table
        if(this.labels)
          this.addSingleNameLabel(this.reservations[reservation.table_id])
      } else {
        // if reservation is added to stack
        // store or update reservation in JSON object
        this.reservationsInStack[reservation.id] = reservation;
        // append new stack reservation
        this.appendSingleStackReservation(reservation)
        // if stack index was changed, return it to normal
        if(tempLastStackIndex !== -1)
          this.reservationStackIndex = tempLastStackIndex;
      }
      // if form is opened, and reservation is updated, update data inside form
      if(this.selectedTable == reservation.table_id || this.selectedStackReservation == reservation.id) {
        // selected table reservation
        this.setSelectedReservation(reservation);
        // paint table back to selected
        if(this.selectedTable)
          this.paintTable(this.selectedTable, SELECTED_COLOR);
      }
    } else if(status === 'delete') {
      // if form is opened, and delete reservation for opened tables comes from another dashboard member
      if(this.selectedTable == reservation.table_id) {
        // selected table reservation
        this.setSelectedReservation({});
        // stop changing table if it's started
        if(this.moveTableID !== null)
          this.stopMoving();
      } else if(reservation.table_id) {
        // return table color to normal
        this.paintTable(reservation.table_id, NORMAL_COLOR);
      } else if(this.selectedStackReservation == reservation.id) {
        // selected table reservation
        this.setSelectedReservation({});
        // close form
        this.toggleFormState(false);
      }
      
      // if reservation was on table
      if(reservation.table_id) {
        // if labels is turned on, remove name label from old table
        if(this.labels && this.reservations[reservation.table_id])
          this.removeSingleNameLabel(this.reservations[reservation.table_id])
        // delete reservation record from JSON object
        delete this.reservations[reservation.table_id];
        // add this table to available tables since it's now not reserved anymore
        this.availableTables[reservation.table_id] = this.tables[reservation.table_id];
      } else {
        if(this.reservationsInStack[reservation.id]) {
          // re-order Stack from deleted reservation
          this.reorderReservationStack(this.reservationsInStack[reservation.id].sprite?.userData.onIndex);
          // if reservation exists in stack
          this.removeSingleNameLabel(this.reservationsInStack[reservation.id]);
          // remove from stack object
          delete this.reservationsInStack[reservation.id];
        }
      }
    }
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // set available tables
    this.setAvailableTables(this.availableTables);
    // send to canvas component state
    this.updateReservations(this.reservations, this.reservationsInStack);
  }

  // function that paints table depending on reservation type
  paintReservation(reservation: TableReservationScheme) {
    // called on reservation trigger status === 'update'
    if(reservation.type !== 1 && !!reservation.confirmed)
      return this.paintTable(reservation.table_id, PRESENCE_COLOR);
    // else paint depending on type
    if(reservation.type === 1)
      this.paintTable(reservation.table_id, reservation.confirmed ? PRESENCE_COLOR : LIMITED_COLOR);
    else
      this.paintTable(reservation.table_id, RESERVED_COLOR);
  }

  // function that paints the given table in given color
  paintTable(table_id: number, color: THREE.Color) {
    if(this.tables[table_id])
      (<any> this.tables[table_id].mesh.material).color = color;
  }

  // function to handle new reservations in progress
  onReservationProgressTrigger(data: InProgressTriggerScheme) {
    const { table_id, status } = data;
    // store all table data in context constant
    const table = this.tables[table_id];

    if(status === 'process') {
      // if current user is changing reserved table, stop blinking
      if(this.moveTableID === table_id)
        this.stopMoving();
      if(this.requestTable == table_id)
        this.removeRequestTable();
      // if selected table is same as table in process
      if(this.selectedTable === table_id) {
        // remove selected table
        this.selectedTable = -1;
        // close form
        this.toggleFormState(false);
      }
      // remove unsaved limited table on process trigger in LIMIT mode
      if(this.mode === 2)
        this.removeLimitClick(table_id);
      // paint table in reservation process
      this.paintTable(table_id, IN_PROGRESS_COLOR);
      // as this table is now in reservation process, remove it from available tables
      delete this.availableTables[table_id];
      // push table to array (array of tables in process)
      if(this.inProcess.indexOf(String(table_id)) === -1)
        this.inProcess.push(String(table_id));
    } else if(status === 'delete') {
      // return table color to normal
      this.paintTable(table_id, NORMAL_COLOR);
      // as this table is not in reservation process anymore, add it to available tables
      this.availableTables[table_id] = table;
      // remove table from in Process array
      let index = this.inProcess.indexOf(String(table_id));
      if(index !== -1)
        this.inProcess.splice(index, 1);
    }
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // set available tables
    this.setAvailableTables(this.availableTables);
  }

  // function called on request trigger
  onRequestTrigger(data: RequestTriggerScheme) {
    const { request, status } = data;
    if(status === 'add' || status === 'update') {
      // add or update reservation request
      this.reservationRequests[request.id] = request;
    } else if(status === 'delete') {
      // delete reservation request
      delete this.reservationRequests[request.id];
    }
    // send reservation requests to state
    this.updateWaitingList(this.reservationRequests);
  }

  // function to handle form state (opened or closed)
  toggleFormState(state?: boolean) {
    // change form's state
    this.formState = typeof state === 'undefined' ? !this.formState : state;
    // toggle form callback
    this.toggleForm(this.formState);
  }

  // function to restore form state
  restoreFormState(shouldReRender?: boolean) {
    // if any table is selected
    if(this.selectedTable !== -1) {
      // stop blinking the table if in changing table animation
      if(this.moveTableID !== null)
        this.stopMoving();
      // return table color based on reservation type and existance
      this.returnTableColor(this.selectedTable);
      // if labels are turned on, add name label if exists to previously selected table
      if(this.labels && this.reservations[this.selectedTable])
        this.replaceSingleNameLabel(this.reservations[this.selectedTable], false)
      // remove selected table
      this.selectedTable = -1;
      // close form if is opened
      this.toggleFormState(false);
    }
    // if any stack reservation is selected
    if(this.selectedStackReservation !== -1) {
      // return selected stack reservation color
      if(this.reservationsInStack[this.selectedStackReservation])
        this.replaceSingleNameLabel(this.reservationsInStack[this.selectedStackReservation], false)
      // remove selected stack reservation
      this.selectedStackReservation = -1;
      // close form if is opened
      this.toggleFormState(false);
    }
    // re-render the scene
    shouldReRender && this.renderer.render(this.scene, this.camera);
  }

  // function to call on component unmount
  unmountSocket() {
    if(this.socket !== undefined) {
      this.disconnectMessage = false;
      // disconnect socket
      this.socket.close();
    }

    this.socket = undefined;
  }

  returnTableColor(table_id: number) {
    // get reservation by table
    let reservation = this.getReservationByTable(table_id);
    // if there is no reservation, paint table to normal color, else decide on reservation type
    if(typeof reservation === 'number') {
      // return selected table to normal color
      this.paintTable(table_id, NORMAL_COLOR);
    } else {
      // if we are in limit mode and reservation type is limited, return to normal color
      if(this.mode === 2 && reservation.type === 1)
        this.paintTable(table_id, NORMAL_COLOR);
      else
        this.paintReservation(reservation);
    }
  }

  // function to get reservation by given table id
  getReservationByTable(table_id: number) {
    if(table_id === -1) return -1;
    // get reservation from table id
    let reservation = this.reservations[table_id];
    // no match, return -1
    return reservation ? reservation : -1;
  }

  isTableLimited(table_id: number) {
    if(table_id === -1) return -1;
    // get reservation from table id
    let reservation = this.reservations[table_id];
    // return boolean or -1 if reservation doesn't exist
    return reservation ? (reservation.type === 1) : false;
  }

  // function to get reservation by shortcode
  getReservationByShortcode(shortcode: string) {
    // loop through reservations (type conversion is needed because shortcode is number and argument is string so leave "==" and not "===")
    for(let reservation of Object.values(this.reservations))
      if(reservation.shortcode == shortcode) return reservation;
    // if reservation is in stack
    for(let reservation of Object.values(this.reservationsInStack))
      if(reservation.shortcode == shortcode) return reservation;
    // no match, return null
    return null;
  }

  // function to lead to the table
  toTheTable(table_id: number) {
    let floor = this.tables[table_id].floor;
    // if needed, change floor
    if(floor !== this.floor)
      this.changeFloor(floor);
    // store table for leading
    this.leading = table_id;
    // lead to the table
    this.lead()
  }

  // function to lead to the stack reservation
  toTheStackReservation(reservation_id: number) {
    // store leading stack reservation id
    this.leadingReservation = reservation_id;
    // lead to the table
    this.lead()
  }

  // function to blink table while leading
  lead(returnPeriod?: boolean) {
    if(this.leading === null && this.leadingReservation === null) return;
    // if leading to stack reservation
    if(this.leadingReservation !== null) {
      // return stack reservation color
      if(returnPeriod)
        this.replaceSingleNameLabel(this.reservationsInStack[this.leadingReservation], false);
      else
        this.replaceSingleNameLabel(this.reservationsInStack[this.leadingReservation], true);
    }
    // if leading to the table
    if(this.leading !== null) {
      // paint table from shortcode
      if((<any> this.tables[this.leading].mesh.material).color.equals((this.reservations[this.leading].type === 1 ? LIMITED_COLOR : RESERVED_COLOR))) {
        // paint leading table
        this.paintTable(this.leading, LEAD_COLOR);
        // paint name label from leading reservation
        if(this.labels)
          this.replaceSingleNameLabel(this.reservations[this.leading], true);
      } else {
        // paint leading table
        this.paintTable(this.leading, (this.reservations[this.leading].type === 1 ? LIMITED_COLOR : RESERVED_COLOR));
        // paint name label from leading reservation
        if(this.labels)
          this.replaceSingleNameLabel(this.reservations[this.leading], false);
      }
    }
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // animate
    setTimeout(() => requestAnimationFrame(this.lead.bind(this, !returnPeriod)), 500);
  }

  stopLeading(skipPaint?: boolean) {
    // if leading to stack reservation
    if(this.leadingReservation) {
      // return stack reservation color
      this.replaceSingleNameLabel(this.reservationsInStack[this.leadingReservation], false);
      // set leading reservation id back to null
      this.leadingReservation = null;
      // re-render the scene
      this.renderer.render(this.scene, this.camera);
    }
    // if leading to table reservation
    if(this.leading == null) return;
    // paint table to presence color (guest taken reservation)
    if(skipPaint) {
      // return table color
      this.paintTable(this.leading, (this.reservations[this.leading].type === 1 ? LIMITED_COLOR : RESERVED_COLOR));
    } else {
      // paint table as presence color
      this.paintTable(this.leading, PRESENCE_COLOR);
    }
    // return name label color from leading reservation to reservation default
    if(this.labels)
      this.replaceSingleNameLabel(this.reservations[this.leading], false);
    // mark leading as null
    this.leading = null;
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
  }

  markNewTable(table_id: number) {
    let floor = this.tables[table_id].floor;
    // if needed, change floor but don't close form
    if(floor !== this.floor)
      this.changeFloor(floor, true);
    // store table for moving to
    this.moveTableID = table_id;
    // blink the table
    this.movingTable()
  }

  // function to blink table while changing reservation
  movingTable() {
    if(this.moveTableID === null) return;
    // paint table
    if((<any> this.tables[this.moveTableID].mesh.material).color.equals(NORMAL_COLOR))
      this.paintTable(this.moveTableID, SELECTED_COLOR);
    else
      this.paintTable(this.moveTableID, NORMAL_COLOR);
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // animate
    setTimeout(() => requestAnimationFrame(this.movingTable.bind(this)), 500);
  }

  stopMoving() {
    if(this.moveTableID === null) return;
    // paint table to reservation color
    this.returnTableColor(this.moveTableID);
    // mark moveTableID as null
    this.moveTableID = null;
  }

  blinkSection(returnPeriod: boolean) {
    // exit blink mode
    if(this.requestID < 0 || this.requestTable !== -1) return;
    // paint tables in section
    for(let table_id of this.tablesBySections[this.reservationRequests[this.requestID].section_id]) {
      // we don't blink already reserved tables inside section
      if(this.reservations[table_id]) continue;
      // blink tables inside section
      if(!returnPeriod)
        this.paintTable(table_id, SELECTED_COLOR);
      else
        this.paintTable(table_id, NORMAL_COLOR);
    }
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
    // animate
    setTimeout(() => requestAnimationFrame(this.blinkSection.bind(this, !returnPeriod)), 500);
  }

  // function to accept user reservation request
  acceptRequest(request_id: number) {
    if(request_id === -3)
      this.changeTable = true;
    // stop accept request proccess if -1 is sent as argument
    if(request_id === -1) {
      // return table color if one is already selected
      if(this.requestTable !== -1)
        this.returnTableColor(this.requestTable)
      // return label color if one is already selected
      if(this.labels && this.reservations[this.requestTable])
        this.replaceSingleNameLabel(this.reservations[this.requestTable], false);
      // return label color for selected stack reservation
      if(this.requestStackReservation && this.reservationsInStack[this.requestStackReservation])
        this.replaceSingleNameLabel(this.reservationsInStack[this.requestStackReservation], false);
      // return tables color inside section (if blinking)
      if(this.requestID > 0 && this.reservationRequests[this.requestID] && this.reservationRequests[this.requestID].section_id)
        for(let table_id of this.tablesBySections[this.reservationRequests[this.requestID].section_id]) {
          // we don't blink already reserved tables inside section
          if(this.reservations[table_id]) continue;
          // return to normal color
          this.paintTable(table_id, NORMAL_COLOR);
        }
      // set as -1
      this.requestTable = -1;
      this.requestID = -1;
      // remove selected stack reservation after pick
      this.requestStackReservation = -1;
      // re-render and return
      return this.renderer.render(this.scene, this.camera);
    }
    // for protection
    if(this.requestID !== -1) return;
    // set request id
    this.requestID = request_id;
    // blink section selected in request
    if(request_id > 0 && this.reservationRequests[request_id] && this.reservationRequests[request_id].section_id)
      this.blinkSection(false);
  }

  // called when new reservation comes on the same table that is picked
  removeRequestTable() {
    // stop changing table option if it's turned on
    this.changeTable = false;
    // remove request table
    this.requestTable = -1;
    // propagate to form
    this.tablePicked(-1, -1);
  }

  // function to show name labels
  toggleNameLabels(state?: boolean) {
    // toggle labels flag, or if state is defined, set as state
    this.labels = state === undefined ? !this.labels : state;
    if(this.labels)
      this.displayNameLabelsForCurrentFloor();
    else
      this.removeNameLabelsForCurrentFloor();
    // re-render the scene
    this.renderer.render(this.scene, this.camera);
  }

  displayNameLabelsForCurrentFloor() {
    // get reservations for current floor
    let floorReservations = this.filterReservationsByFloor(this.floor);
     // loop through reservations
    for(let reservation of floorReservations)
      this.addSingleNameLabel(reservation);
  }
  
  // function to remove name labels for current fllor
  removeNameLabelsForCurrentFloor() {
    // get reservations for current floor
    let floorReservations = this.filterReservationsByFloor(this.floor);
     // loop through reservations
    for(let reservation of floorReservations)
      this.removeSingleNameLabel(reservation);
  }

  // function to remove single name label
  removeSingleNameLabel(reservation: ReservationScheme) {
    if(reservation.table_id && this.tables[reservation.table_id] && this.tables[reservation.table_id].sprite)
      this.scene.remove(this.tables[reservation.table_id].sprite);
    else if(this.reservationsInStack[reservation.id] && this.reservationsInStack[reservation.id].sprite) {
      // remove it's event listeners 
      this.domEvents.removeEventListener(this.reservationsInStack[reservation.id].sprite!, 'click', this.onStackReservationClick.bind(this), false);
      this.domEvents.removeEventListener(this.reservationsInStack[reservation.id].sprite!, 'touchstart', this.onStackReservationClick.bind(this), false);
      // remove from scene
      this.scene.remove(this.reservationsInStack[reservation.id].sprite!)
    }
  }
  
  // function to add single name label
  // there are different logis for reservations on tables and reservations in stack
  // reservation in stack is clicable, and reservation on table depends on table click etc.
  addSingleNameLabel(reservation: ReservationScheme, position?: number[]) {
    // don't create label for reservations without customer name
    if(!reservation.customer_name || !reservation.customer_name.length) return 0;
    // paint label based on reservation type
    let parameters = {
      backgroundColor: getLabelBG(reservation),
      textColor: getLabelColor(reservation)
    };
    // remove if duplicate exists
    this.removeSingleNameLabel(reservation);
    // if reservation is on table
    if(reservation.table_id) {
      // if reservations is just created (added on already selected table) paint label to green (selected color)
      if(reservation.table_id == this.selectedTable)
        parameters = { backgroundColor: { r: 26, g: 200, b: 124, a: 1 }, textColor: { r: 255, g: 255, b: 255, a: 1 } }
      // make text sprite
      let sprite = makeTextSprite(reservation.customer_name, reservation.arrival_confirmed, parameters, this.tables[reservation.table_id].position);
      if(!sprite) return 0;
      // save sprite to reservation table
      this.tables[reservation.table_id].sprite = sprite;
      // add sprite to the scene
      this.scene.add(this.tables[reservation.table_id].sprite);
    } else if(position) {
      // if reservation is in stack
      // make text sprite
      let sprite = makeTextSprite(reservation.customer_name, reservation.arrival_confirmed, parameters, position);
      if(!sprite) return 0;
      // store reservation id to sprite, so we can handle on click event
      sprite.name = `${reservation.id}`;
      // store current stack index (location of that reservation)
      sprite.userData.onIndex = this.reservationStackIndex;
      // store sprite in reservation stack
      this.reservationsInStack[reservation.id].sprite = sprite;
      // add sprite to the scene
      this.scene.add(this.reservationsInStack[reservation.id].sprite!);
      // add it's event listeners 
      this.domEvents.addEventListener(this.reservationsInStack[reservation.id].sprite!, 'click', this.onStackReservationClick.bind(this), false);
      this.domEvents.addEventListener(this.reservationsInStack[reservation.id].sprite!, 'touchstart', this.onStackReservationClick.bind(this), false);
    }
    // return success
    return 1;
  }

  // function to change color on single name label (we have to update sprite texture, we redraw canvas in texture)
  replaceSingleNameLabel(reservation: ReservationScheme, isSelected: boolean) {
    // set default label colors based on reservation properties
    let parameters = {
      backgroundColor: getLabelBG(reservation),
      textColor: getLabelColor(reservation)
    };
    // if table chould be selected, paint label to green
    if(isSelected)
      parameters = { backgroundColor: { r: 26, g: 200, b: 124, a: 1 }, textColor: { r: 255, g: 255, b: 255, a: 1 } };
    // return new texture
    const { texture } = updateTextSpriteTexture(reservation.customer_name, reservation.arrival_confirmed, parameters)
    // protection
    if(!texture) return;
    // find cooresponding sprite depending if reservation is on table or in stack
    if(reservation.table_id) {
      this.tables[reservation.table_id].sprite!.material.map = texture;
    } else {
      reservation.sprite!.material.map = texture
    }
  }

  // function to download 2D Guest list
  download2DGuestList() {
    // floor images array
    let floorImages = [];
    // store last floor
    let lastFloor = this.floor;
    // loop through floors
    for(let floor = 0; floor < this.club.num_floors; floor++) {
      // change floor
      this.changeFloor(floor);
      // copy scene
      let sceneCopy = this.scene.clone();
      // get floor image with reservation labels
      floorImages[floor] = getFloorImage(sceneCopy, this.tables, this.filterReservationsByFloor(floor), this.cameraSetup);
    }
    // return last floor
    this.changeFloor(lastFloor);
    // create and download pdf
    download2d(floorImages, this.event);
  }

  // function to filter reservations by floor
  filterReservationsByFloor(floor: number) {
    return Object.values(this.reservations).filter(a => this.tables[a.table_id].floor === floor)
  }

  // force re-render
  forceRender() {
    this.renderer.render(this.scene, this.camera);
  }
}

export default CanvasActions;