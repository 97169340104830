import API from '../../helpers/api';

export const staffAPI = {
  get,
  editStaff
}

// function for getting all staff
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user staff request
    let req = await API.get('/staff/');
    // return staff
    return req.data.staff;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for edit staff
async function editStaff(params: {query: unknown}, { dispatch, rejectWithValue }:any) {
  try {
    // assign new staff
    let res = await API.post('/staff/new', params);
    return {invites: res.data.newInvites, query: params.query};
  } catch (err) {
    return rejectWithValue();
  }
}