import API from '../../helpers/api';

export const placesAPI = {
  get,
  getSingle,
  updatePlace
}

// function for getting all use places
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let req = await API.get('/places/');
    // return places
    return req.data.places;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for getting single place
async function getSingle(params: string, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let req = await API.get(`/places/single/${params}`);
    // return place
    return req.data.place;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for updating place
async function updatePlace(params: {formData: any, query: any}, { dispatch, rejectWithValue }:any) {
  try {
    // update place by slug
    let res = await API.put(`/places/update`, params.formData);
    // assign new banner
    params.query.banner = res.data.new_banner;
    // assign new logo
    params.query.logo = res.data.new_logo;
    // return update data
    return params.query;
  } catch (err) {
    return rejectWithValue();
  }
}