import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackLink } from "../link";
import QRScan from "./qr-scan";

interface ScannerScheme {
  opened?: boolean,
  event_id: string,
  closeScanner?: () => void,
  onScan?: (shortcode: string) => void
}

const Scanner = (props: ScannerScheme) => {
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const inputRefs = [input1Ref, input2Ref, input3Ref, input4Ref];
  const [shortcode, setShortcode] = useState({"1": "", "2": "", "3": "", "4": ""});
  const [qrscan, setQRScan] = useState(false);
  const [QRShortCode, setQRShortCode] = useState('');

  // translation
  const {t} = useTranslation(['general']);

  useEffect(() => {
    // on open focus input, on close set shortcode to initial state
    if(props.opened)
      input1Ref.current?.focus();
    else {
      // qr scan
      setQRShortCode('');
      setQRScan(false);
      // reset shortcode
      setShortcode({"1": "", "2": "", "3": "", "4": ""});
    }
  }, [props.opened]);
  
  useEffect(() => {
    if(shortcode[4] !== "") {
      // remove focus from last input
      input4Ref.current?.blur();
      // do on scan action
      props.onScan && props.onScan(Object.values(shortcode).join(""));
    }
  }, [shortcode]);
  
  useEffect(() => {
    if(QRShortCode.length >= 4) 
      props.onScan && props.onScan(QRShortCode);
  }, [QRShortCode]);

  const onChange = (e: FormEvent<HTMLInputElement>) => {
    if(!props.opened) return;
    // values
    let digit = parseInt(e.currentTarget.name, 10);
    let value = e.currentTarget.value;
    if(value === "" || isNaN(parseInt(value, 10)) || value.length !== 1) return;
    // set changed shortcode digit
    setShortcode((prev) => ({...prev, [digit]: value}));
    // focusIn next input
    if(digit < 4)
      inputRefs[digit].current?.focus();
  }
  
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(!props.opened || e.key !== 'Backspace') return;
    // digit 1-4
    let digit = parseInt(e.currentTarget.name, 10);
    // set changed shortcode digit
    setShortcode((prev) => ({...prev, [digit]: ""}));
    // focus previous input
    if(digit > 1)
      inputRefs[digit-2].current?.focus();
  }

  const toggleQRScanner = () => {
    qrscan ? setQRScan(false) : setQRScan(true);
  }

  return (
    <div className={"scanner__wrapper" + (props.opened ? " opened" : "")}>
      <BackLink text="Povratak" hideTextOnMob={true} onClick={props.closeScanner} />
      <div className="scanner__content">
        <div className="scanner__title">{qrscan ? t("scan_qr") : t("manual_qr")}</div>
        {
          !qrscan ?
            <div className="scanner__numbers">
              <input type="number" inputMode="numeric" pattern="[0-9]{1}" ref={input1Ref} name="1" maxLength={1} value={shortcode[1]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
              <input type="number" inputMode="numeric" pattern="[0-9]{1}" ref={input2Ref} name="2" maxLength={1} value={shortcode[2]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
              <input type="number" inputMode="numeric" pattern="[0-9]{1}" ref={input3Ref} name="3" maxLength={1} value={shortcode[3]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
              <input type="number" inputMode="numeric" pattern="[0-9]{1}" ref={input4Ref} name="4" maxLength={1} value={shortcode[4]} onChange={onChange} onKeyDown={onKeyDown} className="scanner__input" />
            </div>
          :
            <QRScan event_id={props.event_id} opened={qrscan} closeCamera={() => setQRScan(false)} setQRCode={setQRShortCode}/>
        }
        <div className="scanner__scan-qr" onClick={toggleQRScanner}>{qrscan ? t("manual_qr") : t("scan_qr")}</div>
      </div>
    </div>
  )
}

export default Scanner;