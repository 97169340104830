import config from "../app/config";
import PlacesScheme from "../features/places/placesEnum";
import API from './api';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { BufferGeometry } from 'three';
import axios from "axios";

const weekDays = ["sunday", "monday", "thuesday", "wednesday", "thursday", "friday", "saturday"];
const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;
const _day = _hour * 24;

// format date function
export const formatDate = (date: Date, type: number, t?: any) => {
  if(type === 1)
    return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + date.getFullYear() + ", " + t!(weekDays[date.getDay()], {ns: 'general'});
  else if(type === 2)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  else if(type === 3)
    return ((date.getHours() > 9) ? date.getHours() : '0' + date.getHours()) + ':' + ((date.getMinutes() > 9) ? date.getMinutes() : '0'+date.getMinutes());
  else return '';
}

export const formatReservationTime = (date: Date, t?: any) => {
  // calculate dates distance
  let distance: any = (new Date() as any) - (date as any);
  // extract hours from distance and add + 1 (because we want to display countdown as "less then X hours", so we have to add +1)
  let hours = Math.floor(distance / _hour);
  let minutes = Math.floor(distance / _minute);
  let seconds = Math.floor(distance / _second);
  
  if(seconds < 60)
    return t("before_seconds", { ns: 'general', seconds: seconds })
  else if(minutes < 60)
    return t("before_minutes", { ns: 'general', minutes: minutes })
  else if(hours < 24)
    return t("before_hours", { ns: 'general', hours: hours })
  else
    return t("format_date_time", { ns: 'general', date: formatDate(date, 2), time: formatDate(date, 3) })
}

// function to check if days have passed
export const DaysPast = (date:Date, num_days: number) : boolean => {
  return new Date(date.getTime() + 1000*60*60*24*num_days) > new Date();
}

// function to move time
export const ReverseDate = (date:Date, num_hours: number) : Date => {
  return new Date(date.getTime() + 1000*60*60*num_hours);
}

// function to return countdown hours
export function countdownHours(event_start: Date) {
  // calculate dates distance
  let distance: any = (new Date(event_start) as any) - (new Date() as any);
  // extract hours from distance and add + 1 (because we want to display countdown as "less then X hours", so we have to add +1)
  let hours = Math.floor(distance / _hour) + 1;
  // return hours
  return hours;
}

export function displayCountdown(event_start: Date, dayString: string) {
  // calculate dates distance
  let distance: any = (new Date(event_start) as any) - (new Date() as any);
  // get days
  let days = Math.floor(distance / _day);
  // extract hours from distance and add + 1 (because we want to display countdown as "less then X hours", so we have to add +1)
  let hours = Math.floor(distance / _hour) + 1;
  // return display message
  if(days == 1)
    return days + " " + dayString
  else
    return hours + " h"
}

// function to format array to object
export function _format(array:any[], lead:string) {
  var formated:any = {};
  // loop through elements
  for(let item of array) 
    formated[item[lead]] = item;
  // return formated object
  return formated;
}

// function that returns request data directly (simple loading from API)
export async function _load(slug: string) {
  let req = await API.get(config.api+slug);
  return req.data;
}

// function to get max role
export function maxRole(places: Record<string, PlacesScheme>) {
  var max_role = 0;
  for(const place in places)
    if(places[place].role > max_role)
      max_role = places[place].role;
  return max_role;
}

// STL loader part (custom async)
const loader = new STLLoader();
// function to load object geometry (async)
export const loadObject = (link: string) => {
  return new Promise<BufferGeometry>((resolve) => {
    // call loader
    loader.load(link, (geometry: BufferGeometry) => {
      // resolve promise
      resolve(geometry);
    });
  })
}

// init on reservation sequilize
export function parseHSTore<T>(string: any): T{
  var result = {} as any,
  //using [\s\S] to match any character, including line feed and carriage return,
      r = /(["])(?:\\\1|\\\\|[\s\S])*?\1|NULL/g,
      matches = string.match(r),
      i,
      l,
      clean = function (value:any) {
          // Remove leading double quotes
          value = value.replace(/^\"|\"$/g, "");
          // Unescape quotes
          value = value.replace(/\\"/g, "\"");
          //Unescape backslashes
          value = value.replace(/\\\\/g,"\\");
          //Unescape single quotes
          value = value.replace(/''/g,"'");

          return value;
      };

  if(matches) {
    for (i = 0, l = matches.length; i < l; i+= 2) {
      if (matches[i] && matches[i + 1]) {
        var key = clean(matches[i]);
        var value = matches[i + 1];
        result[key] = value=="NULL"?null:clean(value);
      }
    }
  }
  return result;
}


// get maintaince status
export const getMaintainceStatus = async () => {
  try {
    // get user places request
    let res = await axios.get(`${config.assets}status.json?t=${(new Date()).getTime()}`);
    // return places
    return res.data;
  } catch {
    return {};
  }
}