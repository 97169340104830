import * as THREE from 'three';
import { ReservationScheme, TableReservationScheme, TableScheme } from '../components/canvas/enums';

// function to add reservation customer name labels to the scene
export let addLabels = (scene: THREE.Scene, tables: Record<string, TableScheme>, reservations: TableReservationScheme[], forPrint?: boolean) => {
  // loop through reservations
  for(let reservation of reservations) {
    // don't create label for reservations without customer name
    if(!reservation.customer_name || !reservation.customer_name.length) continue;
    // paint label based on reservation type
    let label_bg = reservation.type === 0 ? { r:255, g:231, b:112, a:1 } : (forPrint ? { r:31, g:31, b:31, a:1 } : { r:142, g:42, b:42, a:1 });
    let label_color = reservation.type === 0 ? { r:0, g:0, b:0, a:1 } : { r:255, g:255, b:255, a:1 };
    // make text sprite
    let sprite = makeTextSprite(reservation.customer_name, reservation.arrival_confirmed, { backgroundColor: label_bg, textColor: label_color }, tables[reservation.table_id].position);
    if(!sprite) continue;
    // add sprite to the scene
    scene.add(sprite);
  }
  // return new scene
  return scene;
}

export function updateTextSpriteTexture(message: string, guestDoubleConfirmation: boolean, parameters: any) {
  // protection
  if(!message || !message.length) return { texture: undefined, lines: undefined, textWidth: undefined };
  // initialize parameters
  if ( parameters === undefined ) parameters = {};

  // parameters
  let fontface = "Arial";
  let fontsize = 30;
  let lineheight = 38;
  let borderThickness = 10;
	let backgroundColor = parameters["backgroundColor"] || { r:255, g:255, b:255, a:1.0 };
	let textColor = parameters["textColor"] || { r:255, g:255, b:255, a:1.0 };

  // create canvas
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if(!context) return { texture: undefined, lines: undefined, textWidth: undefined };
  context.font = "400 " + fontsize + "px " + fontface;

  // check should beautify name label, if needed, beautify
  let lines = message.length < 11 ? [message] : beautifyNameLabel(message);
  // did guest double confirmed his arrival
  if(guestDoubleConfirmation)
    lines.push("✅");
  // calculate label length
  var textWidth = calculateLabelWidth(lines, context);

  // draw a rectangular around text
  context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," + backgroundColor.b + "," + backgroundColor.a + ")";
  roundRect(context, borderThickness, borderThickness/2, textWidth + borderThickness, lineheight*lines.length + borderThickness, 0);
  // fill text
  context.fillStyle = "rgba(" + textColor.r + "," + textColor.g + "," + textColor.b + "," + textColor.a + ")";
  for(var i = 0; i < lines.length; i++)
    context.fillText(lines[i], borderThickness, fontsize + borderThickness + (i*lineheight));

  // canvas contents will be used for a texture
  var texture = new THREE.Texture(canvas) 
  texture.needsUpdate = true;

  return { texture: texture, lines: lines, textWidth: textWidth };
}

// function to create and position text sprite
export function makeTextSprite(message: string, guestDoubleConfirmation: boolean, parameters: any, tablePosition: number[]) {
	// create texture for Sprite
  const { texture, lines, textWidth } = updateTextSpriteTexture(message, guestDoubleConfirmation, parameters);
  // protection
  if(!texture || !lines || !textWidth) return;

  var spriteMaterial = new THREE.SpriteMaterial({ map: texture });
  var sprite = new THREE.Sprite(spriteMaterial);
  sprite.userData.lines = lines;
  sprite.userData.textWidth = textWidth;
  sprite.scale.set(100, 50, 1.0);
  sprite.position.set(tablePosition[0]+(38-(textWidth-38)/8), 150, tablePosition[2]+17-8*(lines.length-1));
  return sprite;	
}

// function to calculate width for name label
function calculateLabelWidth(lines: string[], context: CanvasRenderingContext2D) {
  var metrics = { width: 0 };
  // loop through name, surname...
  for(var i = 0; i < lines.length; i++) {
    // measure text length
    let measure = context.measureText(lines[i]);
    // check which line is the longest one
    if(measure.width > metrics.width)
      metrics = measure;
  }
  // return longest line width
  return metrics.width;
}

// function for drawing rectangles
function roundRect(ctx:any, x:any, y:any, w:any, h:any, r:any) {
  ctx.beginPath();
  ctx.moveTo(-x, -y);
  ctx.lineTo(w+x, -y);
  ctx.lineTo(w+x, y+h);
  ctx.lineTo(-x, y+h);
  ctx.lineTo(-x, -y);
  ctx.closePath();
  ctx.fill();
}

// returns label background color based on reservation
export function getLabelBG(reservation: ReservationScheme) {
  // if reservation is confirmed
  if(reservation.confirmed)
    return { r:34, g:34, b:34, a:1 }
  // color depending on reservation type (if not confirmed)
  if(reservation.type === 0)
    return { r:255, g:231, b:112, a:1 }
  else
    return { r:142, g:42, b:42, a:1 }
}

// returns label background color based on reservation
export function getLabelColor(reservation: ReservationScheme) {
  // if reservation is confirmed
  if(reservation.confirmed)
    return { r:255, g:255, b:255, a:1 }
  // color depending on reservation type (if not confirmed)
  if(reservation.type === 0)
    return { r:0, g:0, b:0, a:1 }
  else
    return { r:255, g:255, b:255, a:1 }
}

function beautifyNameLabel(name: string) {
  // separate name and surname into new lines
  let lines = name.split(" ");
  // beautify name display
  let index = 0;
  while(index < lines.length) {
    // remove empty space
    if(lines[index] === "") {
      lines.splice(index, 1);
      continue;
    }
    // check if name and surname can go on the same line
    if(!(index > 0 && (lines[index].length + lines[index-1].length <= 10))) {
      index++;
      continue;
    }
    // connect 2 lines if possible
    lines[index-1] += " " + lines[index];
    // remove second part of concatination from array
    lines.splice(index, 1);
  }
  // return beautified array
  return lines;
}