import { PhoneDetails } from "./main-details";
import { PhoneLabel } from "./phoneDetails";
import { PlacesDisplay } from "./placesDisplay";

const PhonePopups = {
  PhoneDetails,
  PhoneLabel,
  PlacesDisplay
}

export default PhonePopups;