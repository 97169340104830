import config from "../../app/config";
import { roles } from "../popup/staff/rolesHelpers";
import { useAppDispatch } from '../../app/hooks';
import { revokeInvitation } from "../../features/invitations/invitationSlice";
import { InviteScheme } from "../invite";
import { useTranslation } from "react-i18next";
import { Image } from "../image";

const SentInvite = (props: InviteScheme) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation(['roles', 'buttons'])
  return (
    <div className="invite">
      <div className="invite__info">
        <div className="invite__img">
          <Image src={config.assets + props.slug + props.banner} alt="" fadeIn />
        </div>
        <div>
          <h2 className="invite__h2">{t(roles[props.role].name, {ns: 'roles'})} @ {props.club_name}</h2>
          <div className="label--gray">{props.email}</div>
        </div>
      </div>
      <div className="invite__buttons">
        <div className="button--invert" onClick={async () => await dispatch(revokeInvitation(props.hash))}>{t("revoke", {ns: 'buttons'})}</div>
      </div>
    </div>
  )
}

export default SentInvite;