import { useState } from "react";
import { Button, Input } from "../form";
import { SectionTitle } from "../titles";
import { changeData } from "../../features/user/userSlice";
import { useAppDispatch } from "../../app/hooks";
import Loading from "../loading/loading";
import { newMessage } from "../../features/messages/messagesSlice";
import { useTranslation } from "react-i18next";

interface iProps {
  name: string
}

export const GeneralSettings = (props: iProps) => {

  const [name, setName] = useState(props.name);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  // translation
  const {t} = useTranslation(['general', 'inputs', 'buttons', 'success']);

  const submitChange = () => {
    if(loading) return; // ne diraj, zbog toga da se ne moze vise zahtjeva istih poslat
    if(name === '' || name === props.name) return;
    // dodat disable atribut na botun tako da kad uneses promjenu mos stisnist spremi promejna

    // assign loading to true
    setLoading(true);
    // dispatch change data
    dispatch(changeData({name})).then(() => dispatch(newMessage({content: t("personal_data_success", {ns: 'success'}), type: 'success'})));
    // set loading to false
    setLoading(false);
  }
  
  if(loading)
    return <Loading />

  return (
    <div className='col-4'>
      <SectionTitle title={t("personal_data_title")} />
      <Input label={t("name_label", {ns: 'inputs'})} type="text" name="name" placeholder={t("name_placeholder", {ns: 'inputs'})} value={name} onChange={(e) => setName(e.currentTarget.value)} />
      <Button text={t("save", {ns: 'buttons'})} type="save" onClick={submitChange}/> 
    </div>
  );
}