import { DocumentIcon, PenIcon, PhoneIcon } from "../../icons";
import { ReservationScheme } from "../enums";

interface FeaturesScheme {
  reservation: ReservationScheme,
  onReservationDetailsClick: () => void,
  onEditClick: () => void
}

const Features = (props: FeaturesScheme) => (
  <div className="canvas__form__features">
    {
      props.reservation.phone_number &&
        <a className="canvas__form__feature-button" href={`tel:${props.reservation.phone_number}`}>
          <div className="feature-button__icon">
            <PhoneIcon />
          </div>
        </a>
    }
    <div className="canvas__form__feature-button" onClick={props.onReservationDetailsClick}>
      <div className="feature-button__icon">
        {/* <div className="feature-button__notification">2</div> */}
        <DocumentIcon />
      </div>
    </div>
    <div className="canvas__form__feature-button" onClick={props.onEditClick}>
      <div className="feature-button__icon">
        <PenIcon />
      </div>
    </div>
  </div>
)

export default Features;