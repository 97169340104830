import { place_roles } from "../../../app/config";
import PlacesScheme from "../../../features/places/placesEnum";

export interface PhoneState extends PlacesScheme {
  is_blocked?: boolean
}

// filter places that user can select by selected role
export const filterPlacesForPhones = (places: Record<string, PlacesScheme>, blocked_slugs: string[]) =>
{
  const filtered_places:PhoneState[] = [];

  Object.keys(places).forEach((slug) => {

    // if user role is managment or higher
    if(places[slug].role >= place_roles.manager) {

      // create new object
      let new_object = Object.assign({}, places[slug]);

      // assign to filtered_places
      let new_id = filtered_places.push(new_object);

      if(blocked_slugs.findIndex(blocked_slug => blocked_slug === slug) !== -1)
        filtered_places[new_id-1].is_blocked = true;
      else
        filtered_places[new_id-1].is_blocked = false;
    }
  });

  return filtered_places;
}

// format places query and params for reducers
export const formatPlaces = (adds: PhoneState[], removes: PhoneState[]) => {
  let places = '';
  let slugs = '';
  for(const add of adds)
  {
    slugs  += `"${add.slug}": "add",`
    places += `"${add.place_id}": "add",`
  }

  for(const remove of removes) {
    slugs  += `"${remove.slug}": "delete",`
    places += `"${remove.place_id}": "delete",`
  }

  // remove last comma
  places = places.slice(0, -1);
  slugs = slugs.slice(0, -1);

  // add {}
  places = '{'+places+'}';
  slugs = '{'+slugs+'}';

  return {places: JSON.parse(places), slugs: JSON.parse(slugs)};
}