import { LoginParams } from './userSlice';
import API from '../../helpers/api';

export const userAPI = {
  login,
  checkSession,
  changeData,
  getNotificationsCount
}

// function for login
async function login({ email, password, returnURL, rememberMe }: LoginParams, { dispatch, rejectWithValue }:any) {
  try {
    // login request
    let user = await API.post('/user/login', { email: email, password: password, rememberMe: rememberMe });
    // save user token to local storage
    localStorage.setItem('token', user.data.token);
    // replace window location
    window.location.replace(returnURL === null ? "/" : `/${returnURL}`);
  } catch (err) {
    return rejectWithValue();
  }
}

// function to check if user is logged in
async function checkSession(params: boolean, { dispatch, rejectWithValue }:any) {
  try {
    // get user data
    let user = await API.get('/user/');
    // return user data
    return user.data;
  } catch (err) {
    return rejectWithValue();
  }
}

// function to change data
async function changeData(params: {name: string}, { dispatch, rejectWithValue }:any) {
  try {
    // change data
    await API.put('/user/change', {query: params});

    // return params
    return params;
  } catch (err) {
    return rejectWithValue();
  }
}

// function to get notification number
async function getNotificationsCount(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // change data
    let res = await API.get('/user/notifications/count');
    
    // return number
    return res.data;
  } catch (err) {
    return rejectWithValue();
  }
}

// logout function
export function logout() {
  // remove token from local storage
  localStorage.removeItem('token');
  // replace location directly to login (so Private Route doesn't load unneccesserly)
  window.location.replace('/login');
}