import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { _format } from '../../helpers';
import { placesAPI } from './placesAPI';
import PlacesScheme from './placesEnum';

// places scheme
interface placesState {
  places: Record<string, PlacesScheme>
}

// initial state
const initialState = {
  places: {},
} as placesState;

// get all places
export const getPlaces = createAsyncThunk('places/get', placesAPI.get);
export const getPlace = createAsyncThunk('places/get-single', placesAPI.getSingle);

// update place data
export const updatePlace = createAsyncThunk('places/update', placesAPI.updatePlace);


// create user slice
const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getPlaces.fulfilled, (state, { payload }) => {
      // format sections
      for(let item of payload)
        item.sections = _format(item.sections, 'id')
      // store places
      state.places = _format(payload, 'slug');
    })
    builder.addCase(getPlace.fulfilled, (state, { payload }) => {
      // store place payload
      state.places[payload.slug] = payload;
      // format sections
      state.places[payload.slug].sections = _format(payload.sections as any, 'id')
    })
    builder.addCase(updatePlace.fulfilled, (state, { payload }) => {
      // update state
      Object.assign(state.places[payload.slug], payload);
    })
  },
})


export default placesSlice.reducer;