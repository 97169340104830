import { Navigate, useParams, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

const Layout = () => {

  const { places } = useAppSelector(state => state.places );

  // get slug from link so we can fetch events for selected club
  let { slug } = useParams();

  // if slug doesn't exist or club suspended, redirect
  if(!places[slug!] || places[slug!].suspend)
    return <Navigate to="/" replace />
  
  return <Outlet />
}

export default Layout;