import { BackLink } from "../link";

interface ContentTitleProps {
  title: string,
  back?: string,
  button?: string,
  onClick?: () => void
}

interface SectionTitleProps {
  title: string
}

export const ContentTitle = (props: ContentTitleProps) => (
  <div className={"content-title" + (!!props.button ? "--row" : "")}>
    {
      !!props.back && <BackLink text={props.back} />
    }
    <h1>{props.title}</h1>
    {
      !!props.button && <div className="content-title__button button--invert" onClick={props.onClick}>{props.button}</div>
    }
  </div>
)

export const SectionTitle = (props: SectionTitleProps) => (
  <div className="section-title">
    <h2>{props.title}</h2>
  </div>
)