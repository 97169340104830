import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg'; 

export interface LinkProps {
  to: string,
  icon: React.ReactElement,
  text: string,
  role: number,
  notifications?: number,
  onNavigate?: () => void
}

interface BackLinkProps {
  text: string,
  hideTextOnMob?: boolean,
  onClick?: () => void
}

export const BackLink = (props: BackLinkProps) => {

  let navigate = useNavigate();
  const {t} = useTranslation(['menu']);
  
  return (
    <div className="back-link" onClick={() => props.onClick ? props.onClick() : navigate(-1)}>
      <BackArrow />
      { 
        !(props.hideTextOnMob && (window.innerWidth < 800)) && <div className="label--gray">{t(props.text, {ns: 'menu'})}</div>
      }
    </div>
  )
}

const Link = (props: LinkProps) => {
  const {t} = useTranslation(['menu']);
  return (
    <li>
      <NavLink to={props.to} onClick={props.onNavigate}>
        <div className='icon-wrapper'>
          {props.notifications !== undefined && props.notifications !== 0 && <div className='icon__notifications'>{props.notifications}</div>}
          {props.icon}   
        </div>
        <>{t(props.text, {ns: 'menu'})}</>
      </NavLink>
    </li>
  );
}

export default Link;