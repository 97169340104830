import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../../app/config";
import PlacesScheme from "../../features/places/placesEnum";
import { Image } from "../image";
import { roles } from "../popup/staff/rolesHelpers";

const Club = (props: PlacesScheme) => {
  const {t} = useTranslation(['status', 'roles'])
  let navigate = useNavigate();

  return (
    <div className="club" onClick={() => !props.suspend && navigate("/club/" + props.slug)}>
      {
        props.suspend && <div className="club__suspended">{t("suspended", {ns: 'status'})}</div>
      }
      <div className="club__banner">
        <Image src={config.assets + props.slug + props.banner} alt="" fadeIn />
      </div>
      <div className="club__info">
        <h2 className="club__h2">{props.name}</h2>
        <div className="label--gray">{t(roles[props.role].name, {ns: 'roles'})}</div>
      </div>
    </div>
  )
}

export default Club;