import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/form/";
import Scanner from "./scanner";

interface ScanScheme {
  isLeading: boolean,
  event_id: string,
  onScan?: (shortcode: string) => void,
  confirmPresence?: () => void,
  exit: () => void
}

const Scan = (props: ScanScheme) => {
  const [scannerState, setScannerState] = useState(false);

  const {t} = useTranslation(['buttons']);

  let onScan = (shortcode: string) => {
    // close scanner
    setScannerState(false);
    // propagate on scan
    props.onScan && props.onScan(shortcode);
  }

  return (
    <>
      <Scanner opened={scannerState} closeScanner={() => setScannerState(false)} onScan={onScan} event_id={props.event_id}/>
      <div className="scan__wrapper">
        {
          !props.isLeading ?
            <Button className="no-margin" text={t("scan_table", {ns: 'buttons'})} onClick={() => setScannerState(true)} />
          :
            <div>
              <div className="button no-margin" onClick={props.confirmPresence}>{t("confirm_reservation", {ns: 'buttons'})}</div>
              <div className="button--invert small-margin" onClick={props.exit}>{t("back", {ns: 'buttons'})}</div>
            </div>
        }
      </div>
    </>
  )
}

export default Scan;