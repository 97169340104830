import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormWrapper, { Button, Input } from "../../components/form";
import Loading from "../../components/loading/loading";
import { errorMsg, successMsg } from "../../features/user/userSlice";
import API from '../../helpers/api';

const PasswordRecovery = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // get key from params
  let { key } = useParams();
  // translation
  const {t} = useTranslation(['general', 'inputs', 'buttons', 'errors', 'success']);
  
  // error success messages
  const { error } = useAppSelector(state => state.user);

  // state
  const [form, setForm] = useState({password: '', confirm_password: ''});

  // state
  const [loaded, setLoaded] = useState(true);

  // key checks
  if(!key)
    navigate('/', {replace:true});

  if(key!.length < 20)
    navigate('/', {replace:true})

  // if loading
  if(!loaded) // manage loading page
    return <Loading />

  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setForm((prevState) => ({...prevState, [name]: value}));
  }
  
  const submitRecovery = () => {
    if(form.password === '') return dispatch(errorMsg({ msg: t("input_all_data", {ns: 'errors'}) }))
    if(form.confirm_password !== form.password) return dispatch(errorMsg({ msg: t("invalid_password_repeat", {ns: 'errors'}) }))
    if(form.password.length < 8) return dispatch(errorMsg({ msg: t("invalid_password_lenght", {ns: 'errors'}) }))

    setLoaded(false);

    // change password
    API.put('/user/recover/'+key, {
      query: form
    }).then(() => {
      // dispatch message
      dispatch(successMsg({msg: t("password_reset_success", {ns: 'success'})}));
      // redirect to login
      navigate('/login');
    }).catch(() => { dispatch(errorMsg({msg: t("invalid_key", {ns: 'errors'})})); setLoaded(true); } );

    
  }

  return (
    <FormWrapper 
      title={t("password_reset_title")}
      description={t("password_reset_text")}
      error={error}
    >
      <Input label={t("new_password_label", {ns: 'inputs'})} type="password" name="password" value={form.password} placeholder={t("new_password_placeholder", {ns: 'inputs'})} onChange={handleChanges} />
      <Input label={t("new_password_repeat_label", {ns: 'inputs'})} type="password" name="confirm_password" value={form.confirm_password} placeholder={t("new_password_repeat_placeholder", {ns: 'inputs'})} onChange={handleChanges}/>
      <Button text={t("submit", {ns: 'buttons'})} onClick={submitRecovery}/>
    </FormWrapper>
  )
}

export default PasswordRecovery;