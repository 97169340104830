import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { _format } from '../../helpers';
import { DownloadIcon, List1DIcon, List2DIcon } from '../icons';
import { BackLink } from '../link';
import { ReservationScheme, TableReservationScheme, TableScheme } from './enums';
import EventsScheme from '../../features/events/eventsEnum';
import { download1d } from './print';
import { SectionsScheme } from '../../features/places/placesEnum';

interface GuestListScheme {
  opened: boolean,
  close: () => void,
  tables: Record<string, TableScheme>,
  sections: Record<string, SectionsScheme>,
  reservations: Record<string, TableReservationScheme>,
  reservationsInStack: Record<string, ReservationScheme>,
  event: EventsScheme,
  onGuestDoubleClick: (table_id: number, reservation_id: number) => void,
  download2d: () => void,
  isHostess: boolean
}

interface SearchScheme extends ReservationScheme {
  table_label?: string,
  isVIP?: boolean,
  isLimited?: boolean,
  section_name?: string,
  phoneSearch?: boolean,
  shortcodeSearch?: boolean
}

export interface SectionItem {
  id: number,
  table_id: number,
  table_label: string,
  customer_name: string,
  isLimited: boolean,
  confirmed: boolean,
  isVIP: boolean,
  section_name: string,
  arrival_confirmed: boolean,
  assigned_by_name?: string,
  approved_by_name?: string,
  phone_number?: string,
  shortcode?: string
}

let reA = /[^a-zA-Z]/g;
let reN = /[^0-9]/g;

const GuestList = (props: GuestListScheme) => {
  const printRef = useRef<HTMLDivElement>(null);

  const [search, updateSearch] = useState<Record<string, SearchScheme[]>>({});
  const [list, updateList] = useState<Record<string, SectionItem[]>>({});
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState(false);
  const ref = useRef<any>(null);

  // translate
  const {t} = useTranslation(['general', 'inputs'])

  useEffect(() => {
    // function to be called on outside click
    function handleClickOutside(event: MouseEvent) {
      if(ref.current && !ref.current.contains(event.target)) {
        setOptions(false);
        // Unbind the event listener
        document.removeEventListener("mousedown", handleClickOutside, false);
      }
    }
    // Bind the event listener
    options && document.addEventListener("mousedown", handleClickOutside, false);
    // on component unmount
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [ref, options]);

  let toggleOptions = () => {
    // change display state
    setOptions(options ? false : true);
  }

  useEffect(() => {
    // divide into groups
    let sections = Object.values(props.tables).reduce((acc, table) => {

      if(!acc[table.section_id ? props.sections[table.section_id].section_name : 'unsorted'])
        acc[table.section_id ? props.sections[table.section_id].section_name : 'unsorted'] = [];

      acc[table.section_id ? props.sections[table.section_id].section_name : 'unsorted'].push({ 
        id: props.reservations[table.id]?.id,
        table_id: table.id,
        table_label: table.label,
        customer_name: props.reservations[table.id]?.customer_name,
        isLimited: props.reservations[table.id]?.type === 1,
        confirmed: props.reservations[table.id]?.confirmed,
        isVIP: table.type === 1,
        section_name: table.section_id ? props.sections[table.section_id].section_name : 'unsorted',
        arrival_confirmed: props.reservations[table.id]?.arrival_confirmed,
        assigned_by_name: props.reservations[table.id]?.assigned_by_name,
        approved_by_name: props.reservations[table.id]?.approved_by_name,
        phone_number: props.reservations[table.id]?.phone_number,
        shortcode: props.reservations[table.id]?.shortcode
      });

      return acc;
    }, {} as Record<string, SectionItem[]>);

    if(Object.keys(props.reservationsInStack).length)
      sections['Gosti bez stola'] = [];

    Object.values(props.reservationsInStack).map(reservation => {
      sections['Gosti bez stola'].push({
        id: props.reservationsInStack[reservation.id]?.id,
        table_id: -1,
        table_label: "",
        customer_name: reservation.customer_name,
        isLimited: reservation.type === 1,
        confirmed: reservation.confirmed,
        isVIP: false,
        section_name: 'Gosti bez stola',
        arrival_confirmed: reservation.arrival_confirmed,
        assigned_by_name: reservation.assigned_by_name,
        approved_by_name: reservation.approved_by_name,
        phone_number: reservation.phone_number,
        shortcode: reservation.shortcode
      })
    })
    
    for(let section_name of Object.keys(sections)) 
      // sort reservations list
      sections[section_name].sort((a: any, b: any) => {
        // sort tables that have no reservation by their label
        if(!a.customer_name && !b.customer_name) {
          var aA = a.table_label.replace(reA, "");
          var bA = b.table_label.replace(reA, "");
          if (aA === bA) {
            var aN = parseInt(a.table_label.replace(reN, ""), 10);
            var bN = parseInt(b.table_label.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
          } else {
            return aA > bA ? 1 : -1;
          }
        }
        // push those with reservation to the top of list
        if(!a.customer_name) return 1;
        if(!b.customer_name) return -1;
        // if both tables have reservation, compare their customer names
        return a.customer_name.localeCompare(b.customer_name);
      })
    
    // store as state and re-render
    updateList(sections);
  }, [props.reservations, props.reservationsInStack, props.tables, props.opened]);

  const handleSearch = (e: FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value.toUpperCase();
    // toggle search
    if(value === "")
      return setIsSearching(false);
    else
      setIsSearching(true);
    
    let searchList: Record<string, SearchScheme[]> = {};
    // do search
    let founded: SearchScheme[] = Object.values(props.reservations).filter((a) => (
      a.customer_name?.toUpperCase().includes(value) || 
      (a.phone_number && a.phone_number.includes(value)) || 
      (a.shortcode && (a.shortcode.toString()).includes(value))
    ));
    // connect table lable with reservation
    for(let item of founded) {
      // if reservation is on table
      if(item.table_id) {
        // create section if not in search list already
        if(!searchList[props.tables[item.table_id].section_id ? props.sections[props.tables[item.table_id].section_id].section_name : 'unsorted'])
          searchList[props.tables[item.table_id].section_id ? props.sections[props.tables[item.table_id].section_id].section_name : 'unsorted'] = []
        // merge data
        item.table_label = props.tables[item.table_id].label;
        item.isVIP = props.tables[item.table_id].type === 1;
        item.isLimited = item.type === 1;
        // check is it maybe phone search or shortcode search
        item.phoneSearch = item.phone_number ? item.phone_number?.includes(value) : false;
        item.shortcodeSearch = item.shortcode ? item.shortcode.toString().includes(value) : false;
        // push merged data to list
        searchList[props.tables[item.table_id].section_id ? props.sections[props.tables[item.table_id].section_id].section_name : 'unsorted'].push(item);
      }
      // missing else if reservations is on stack
    }
    // update results
    updateSearch(searchList);
  }

  return (
    <div className={"guest-list" + (props.opened ? " opened" : "")}>
      <BackLink text="Povratak" hideTextOnMob={true} onClick={props.close} />
      <div className="guest-list__actions">
        <input type="text" placeholder={t("search_by_name", {ns: 'inputs'})} onChange={handleSearch} />
        <div className='guest-list__download' ref={ref} onClick={toggleOptions}>
          <div className="canvas__options-menu-trigger no-border"><DownloadIcon /></div>
          <div className={"canvas__options-menu" + (options ? " opened" : "")}>
            <div className="canvas__option" onClick={() => download1d(list, props.event)}>
              <div className="canvas__option__text">
                <List1DIcon />
                <p className="canvas__option__p">{t("list_1d", {ns: 'general'})}</p>
              </div>
            </div>
            <div className={"canvas__option"} onClick={props.download2d}>
              <div className="canvas__option__text">
                <List2DIcon />
                <p className="canvas__option__p">{t("list_2d", {ns: 'general'})}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={printRef} className='guest-list__wrapper'>
        <div className='guest-list__header'>
          <div className='guest-list__header-item short'>{t("table")}</div>
          <div className='guest-list__header-item'>{t("name_label", {ns: 'inputs'})}</div>
        </div>
        <div className='guest-list__body'>
          {
            // Sections are sorted in alphabetical order using .sort((a, b) => a[0].localeCompare(b[0]))
            Object.entries(!isSearching ? list : search).sort((a, b) => a[0].localeCompare(b[0])).map(([section_name, reservations]) => (
              <>
                { 
                  (isSearching || !isSearching && Object.keys(list).length > 1) && 
                    <div className='guest-list__row'>
                      <div className='guest-list__section-name'>{section_name}</div>
                    </div>
                }
                {
                  (reservations as (SearchScheme[] | SectionItem[])).map((reservation, index) => {
                    return <div key={reservation.id} 
                      onDoubleClick={() => 
                        (reservation.customer_name || reservation.isLimited) && 
                        reservation.table_id && 
                        props.onGuestDoubleClick(reservation.table_id, reservation.id)
                      } 
                      className={'guest-list__row' + (reservation.confirmed ? " confirmed" : "")}>
                      <div className='guest-list__cell short'>{ reservation.table_label }{reservation.isVIP && <div className="vip-text">VIP</div>}</div>
                      <div className='guest-list__cell'>
                        <div className="guest-list__cell-index">{index + 1}</div>
                        <div>
                          { reservation.customer_name }
                          { ('phoneSearch' in reservation) && reservation.phoneSearch && <div className='guest-list__cell-phone'>{reservation.phone_number}</div> }
                          { ('shortcodeSearch' in reservation) && reservation.shortcodeSearch && <div className='guest-list__cell-phone'>Shortcode: <span>{reservation.shortcode}</span></div> }
                        </div>
                        { reservation.arrival_confirmed ? "✅" : "" } 
                        { reservation.isLimited && (!reservation.customer_name ? <div className="limited-text">{t("limited_table_label", { ns: 'general' })}</div> : <div className="limited-text">{t("vip_guest_table_label", { ns: 'general' })}</div>) } 
                        { !props.isHostess && reservation.assigned_by_name && <div className="reservation-assigned-by">{t("assigned_by_text", { ns: 'general' })} {reservation.assigned_by_name}</div> }
                        { !props.isHostess && reservation.approved_by_name && <div className="reservation-assigned-by">{t("approved_by_text", { ns: 'general' })} {reservation.approved_by_name}</div> }
                      </div>
                    </div>
                  })
                }
              </>
              )
            )
          }
        </div>
      </div>
    </div>
  )
}

export default GuestList;