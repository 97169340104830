import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading/loading';
import { getInvitation } from '../../helpers/api/invitations';
import Invite from '../../components/invite';
import { logout } from '../../features/user/userAPI';

const Invitation = () => {
  // navigate
  const navigate = useNavigate();
  // fetch api
  const [data, setData] = useState({ hash: '', email: '', club_name: '', role: 0, slug: '', banner: '' });
  const [loading, setLoaded] = useState(false);

  // get slug from link so we can fetch events for selected club
  let { key } = useParams();

  useEffect(() => {
    // get invitation (API request)
    getInvitation(key).then(res => {
      // do action based on response status
      // STATUS: 0 - user logged in, -1 - user must create account, 1 - user must login, 2 - user is logged in with different account
      switch(res.status) {
        case -1:
          navigate(`/signup/${key}`, { replace: true });
          break;
        case 0:
          setData(res.invitation);
          break;
        case 1:
          navigate(`/login?returnURL=invitation/${key}`, { replace: true });
          break;
        case 2:
          logout();
          window.location.reload();
          break;
        default: 
          navigate('/', { replace: true });
          break;
      }
      // remove loader from page and display data
      setLoaded(true);
    }).catch(e => navigate('/', { replace: true }));
  }, [key]);

  if(!loading)
    return <Loading />

  return (
    <div className='form-wrapper'>
      <div className='invite-wrapper'>
        <Invite {...data} onAction={() => navigate('/', { replace: true })} />
      </div>
    </div>
  )
}

export default Invitation;