import { StaffState } from "./rolesHelpers";

export const PlaceLabel = (props: StaffState & {index: number, assignRole: (i: number, r:boolean) => void}) => {

  let data_checked = props.has_role ? { "data-checked" : "" } : {};

  return (
    <div className="staff__checkbox checkbox" {...data_checked} onClick={() => props.assignRole(props.index, !props.has_role)}>
      <div className="checkbox__box"></div>
      <div>
        <h3>{props.name}</h3>
        <div className="eyebrow">{props.address && props.address.split(',')[props.address.split(',').length - 2]}</div>
      </div>
    </div>
  )
};