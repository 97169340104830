import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { Button } from "../form";

interface SaveButtonScheme {
  onClick?: () => void
}

const SaveButton = (props: SaveButtonScheme) => {
  const { limitTables, delimitTables } = useAppSelector(state => state.events);
  const [isVisible, setVisibility] = useState(false);

  const {t} = useTranslation(['buttons']);

  useEffect(() => {
    if(limitTables.length !== 0 || delimitTables.length !== 0)
      setVisibility(true);
    else
      setVisibility(false);
  }, [limitTables, delimitTables]);

  return (
    <div className={"scan__wrapper" + (isVisible ? "" : " hidden")}>
      <Button className="no-margin" text={t("save", {ns: 'buttons'})} onClick={props.onClick} />
    </div>
  )
}

export default SaveButton;