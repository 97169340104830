import { FormEvent, useEffect, useState } from "react";
import { isValidEmail } from "../../../helpers/regex"
import API from '../../../helpers/api'
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg'
import { useTranslation } from "react-i18next";

var timer: ReturnType<typeof setTimeout>;

// function to check if user exist
const checkEmail = async (email: string) => {
  // check if user with entered email exist
  let res = await API.post('/user/exists', { email });
  // return user name 
  return res.data.name;
}

export const EmailInput = (props: { assignEmail: (email: string) => void, email: string }) => {

  const [displayInfo, setInfo] = useState({ email: '', name: '' });
  const [isUserSelected, selectUser] = useState(false);
  // translation
  const {t} = useTranslation(['inputs'])
  useEffect(() => {
    if(props.email === '')
    {
      setInfo({ email: '', name: '' });
      selectUser(false);
    }
  }, [props.email]);

  const emailInput = (e: FormEvent<HTMLInputElement>) => {
    // set temp mail
    var email = e.currentTarget.value;
    // check if email is valid
    if(isValidEmail(email)) {
      // clear timeout
      clearTimeout(timer);
      // assign mail 
      props.assignEmail(email);
      // set new timer
      timer = setTimeout(async () => {
        try {
          // check if user with email exist
          let name = await checkEmail(email);
          // if user exist, set his name
          setInfo({ email: email, name: name });
        } catch {
          // if user doesn't exist, name is empty
          setInfo({ email: '', name: '' })
        }
      }, 1000);
    }
  }

  return (
    <div className="input-wrapper">
      <div className="label input__label">{t("email_label", {ns: 'inputs'})}</div>
      {
        !isUserSelected ? 
          <input type="text" placeholder={t("email_placeholder", {ns: 'inputs'})} onInput={emailInput}/>
        :
          <div className="selected-user" onClick={() => { selectUser(false); setInfo({ email: '', name: '' }) }}>
            <div className="select-user__name">{ displayInfo.name }</div>
            <div className="select-user__email">{ displayInfo.email }</div>
          </div>
      }
      {
        !isUserSelected && displayInfo.email !== '' && (
          <div className="select-user" onClick={() => selectUser(true)}>
            <div className="select-user__icon">
              <EmailIcon />
            </div>
            <div>
              <div className="select-user__name">{ displayInfo.name }</div>
              <div className="select-user__email">{ displayInfo.email }</div>
            </div>
          </div>
        )
      }
    </div>
  );
}