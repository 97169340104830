import { useTranslation } from "react-i18next";
import PlacesScheme from "../../features/places/placesEnum";
import { BackLink } from "../link";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { WaitingListIcon } from "../icons";
import { CustomCheckbox } from "../form";
import { handleLimit } from "../../features/events/eventsSlice";
import { TableReservationScheme } from "./enums";

let floors = ["ground_floor", "floor_1", "floor_2", "floor_3"];

const CanvasNav = (props: PlacesScheme & { changeFloor: (floor: number) => void, activeFloor: number }) => {
  const {t} = useTranslation(['general']);
  return (
  <div className="canvas__nav">
    <BackLink text="Povratak" />
    {/* <div className="floor__nav">
      {
        Array(props.num_floors).fill(1).map((v, i) => {
          // invesed array index is real floor index
          let floor_index = props.num_floors - 1 - i;
          // return element
          return <p onClick={() => props.changeFloor(floor_index)} key={i} className={floor_index == props.activeFloor ? "active" : ""}>
              { t(floors[floor_index]) }
            </p>
        })
      }
    </div> */}
  </div>
  );
}

interface FastLimitScheme {
  tablesBySections: Record<string, number[]>,
  reservations: Record<string, TableReservationScheme>
}

export const FastLimit = (props: FastLimitScheme) => {
  // dispatch and reducers
  const dispatch = useAppDispatch();
  const { limitTables, delimitTables } = useAppSelector( state => state.events );
  const { places } = useAppSelector( state => state.places );
  let { slug } = useParams();
  // states
  let [notLimited, setNotLimited] = useState<Record<string, number[]> | undefined>(undefined);
  let [opened, setOpened] = useState(false);
  // translation
  const { t } = useTranslation(['general']);

  useEffect(() => {
    let leftToLimit: Record<string, number[]> = {};
    // loop through tables in sections 
    for(let section_id of Object.keys(props.tablesBySections))
      for(let table_id of props.tablesBySections[section_id])
        if(
          (!props.reservations[table_id] && (!limitTables.includes(table_id) || delimitTables.includes(table_id))) ||
          ((props.reservations[table_id] && props.reservations[table_id].customer_name === null) && delimitTables.includes(table_id))
        ) {
          // create section array if doesn't exist already
          if(!leftToLimit[section_id])
            leftToLimit[section_id] = [];
          // push table id to section
          leftToLimit[section_id].push(table_id);
        }
    // update not limited tables state
    setNotLimited(leftToLimit)
  }, [props, limitTables, delimitTables])

  // function to limit section
  let limitSection = (e: ChangeEvent<HTMLInputElement>) => {
    // protection
    if(!notLimited) return;
    // if all tables are limited
    if(notLimited[e.target.value]) {
      // forward process (limit all tables in section)
      for(let table_id of props.tablesBySections[e.target.value]) {
        // skip reserved tables
        if(props.reservations[table_id] && props.reservations[table_id].customer_name !== null) continue;
        // if it's for delimit, remove it from delimit
        if(props.reservations[table_id] && delimitTables.includes(table_id))
          dispatch(handleLimit(table_id))
        // add it to limit tables
        if(!props.reservations[table_id] && !limitTables.includes(table_id))
          dispatch(handleLimit(table_id))
      }
    } else {
      for(let table_id of props.tablesBySections[e.target.value]) {
        // skip reserved tables
        if(props.reservations[table_id] && props.reservations[table_id].customer_name !== null) continue;
        // remove it from limit tables
        if(!props.reservations[table_id] && limitTables.includes(table_id))
          dispatch(handleLimit(table_id))
        // delimit if it was limited
        if(props.reservations[table_id] && !delimitTables.includes(table_id))
          dispatch(handleLimit(table_id))
      }
    }

  }

  return (
    <div className="fast-limit">
      <div className="fast-limit__cta" onClick={() => setOpened(prev => !prev)}><WaitingListIcon />{t("limit_sections")}</div>
      <div className={"canvas__options-menu custom-paddings" + (opened ? " opened" : "")}>
        {
          notLimited && slug &&
          Object.values(places[slug].sections).sort((a, b) => a.section_name.localeCompare(b.section_name)).map(section => (
            <label className="canvas__option">
              <CustomCheckbox
                className="in-list"
                label={section.section_name}
                name="fast-limit-section"
                value={section.id}
                checked={!(notLimited && notLimited[section.id] && notLimited[section.id].length)}
                onChange={limitSection}
              />
            </label>
          ))
        }
      </div>
    </div>
  )
}

export default CanvasNav;