import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import config, { place_roles } from "../../app/config";
import EventsScheme from "../../features/events/eventsEnum";
import { formatDate } from "../../helpers";
import { GuestsIcon, NameLabelsIcon, OptionOnIcon, ScannerIcon, WaitingListIcon } from "../icons";
import { GOING_PHASE, TAKEOVER_PHASE } from "../../helpers/eventPhases";
import PlacesScheme from "../../features/places/placesEnum";
import { Image } from "../image";

interface EventBadgeSceheme {
  event: EventsScheme,
  club: PlacesScheme,
  onGuestList: () => void,
  scanOption: boolean,
  toggleScanOption: () => void,
  eventPhase: number,
  onOptionsClick: (state: boolean) => void,
  nameLabelsOption: boolean,
  toggleNameLabelsOption: () => void,
  waitingListLength: number,
  toggleWaitingListOption: () => void
}

const EventBadge = (props: EventBadgeSceheme) => {
  const {t} = useTranslation(['general']);

  const [options, setOptions] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    // function to be called on outside click
    function handleClickOutside(event: MouseEvent) {
      if(ref.current && !ref.current.contains(event.target)) {
        setOptions(false);
        // Unbind the event listener
        document.removeEventListener("mousedown", handleClickOutside, false);
      }
    }
    // Bind the event listener
    options && document.addEventListener("mousedown", handleClickOutside, false);
    // on component unmount
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [ref, options]);

  let toggleOptions = () => {
    // change display state
    setOptions(options ? false : true);
    // return callback to canvas index
    props.onOptionsClick(options);
  }

  return (
    <div className="event-badge-holder">
      <div className="event-badge">
        <div className="event-badge__img">
          <Image src={`${config.assets}${props.club.slug}${props.event.banner}`} alt="" fadeIn />
        </div>
        <div>
          <div className="event-badge__title">{ props.event.name }</div>
          <div className="event-badge__date label--gray">{ (new Date(props.event.event_start) > new Date()) ? formatDate(new Date(props.event.event_start), 1, t) : t("event_going_text") }</div>
        </div>
      </div>
      <div className="canvas__options" ref={ref}>
        <div className="canvas__options-menu-trigger" onClick={toggleOptions}>
          <div></div><div></div><div></div>
          {
            props.club.role >= place_roles.manager && (new Date(props.event.reservation_takeover) >= new Date()) && props.waitingListLength > 0 &&
              <div className="canvas__options__notifications">{props.waitingListLength}</div>
          }
        </div>
        <div className={"canvas__options-menu" + (options ? " opened" : "")}>
          <div className="canvas__option" onClick={props.onGuestList}>
            <div className="canvas__option__text">
              <GuestsIcon />
              <p className="canvas__option__p">{t("guest_list_label", {ns: 'general'})}</p>
            </div>
          </div>
          <div className="canvas__option" onClick={props.toggleNameLabelsOption}>
            <div className="canvas__option__text">
              <NameLabelsIcon />
              <p className="canvas__option__p">{t("name_label_option", {ns: 'general'})}</p>
            </div>
            { props.nameLabelsOption && <OptionOnIcon /> }
          </div>
          {
            props.club.role >= place_roles.manager && (new Date(props.event.reservation_takeover) >= new Date()) &&
              <div className="canvas__option" onClick={props.toggleWaitingListOption}>
                <div className="canvas__option__text">
                  <WaitingListIcon />
                  <p className="canvas__option__p">{t("requests_option", {ns: 'general'})}</p>
                  { props.waitingListLength > 0 && <div className="canvas__option__notifications">({props.waitingListLength})</div> }
                </div>
              </div>
          }
          <div className={"canvas__option" + (props.eventPhase === TAKEOVER_PHASE || props.eventPhase === GOING_PHASE ? "" : " unavailable")} onClick={props.toggleScanOption}>
            <div className="canvas__option__text">
              <ScannerIcon />
              <p className="canvas__option__p">{t("take_to_table_label", {ns: 'general'})}</p>
            </div>
            { props.scanOption && (props.eventPhase === TAKEOVER_PHASE || props.eventPhase === GOING_PHASE) && <OptionOnIcon /> }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventBadge;