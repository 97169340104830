import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { maxRejectMessageLength } from "../../app/config";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { newMessage } from "../../features/messages/messagesSlice";
import { rejectRequest } from "../../helpers/api/requests";
import { GroupSizeIcon, PhoneIcon } from "../icons";
import { BackLink } from "../link";
import { ReservationRequestScheme } from "./enums";
import { SectionsScheme } from "../../features/places/placesEnum";
import { formatReservationTime } from "../../helpers";

interface WaitingListScheme {
  list: Record<string, ReservationRequestScheme>,
  isOpened: boolean,
  place_id: number,
  event_id: string,
  sections: Record<string, SectionsScheme>,
  close?: () => void,
  acceptSingle: (request: ReservationRequestScheme) => void
}

interface RequestSingleScheme extends ReservationRequestScheme {
  accept: (request: ReservationRequestScheme) => void,
  place_id: number,
  event_id: string,
  section_name: string
}

const WaitingList = (props: WaitingListScheme) => {

  const { t } = useTranslation(['general', 'buttons']);

  return (
    <div className={"waiting-list" + (props.isOpened ? " opened" : "")}>
      <BackLink text={t("back2", {ns: 'buttons'})} onClick={props.close} />
      <h1 className="waiting-list__h1">{t("reservation_requests_title", {ns: 'general'})}</h1>
      {
        Object.values(props.list).map((request) => 
          <RequestSingle 
            key={request.id} 
            { ...request } 
            accept={props.acceptSingle} 
            place_id={props.place_id} 
            section_name={(props.sections && props.sections[request.section_id]) ? props.sections[request.section_id].section_name : ""} 
            event_id={props.event_id}
          />
        )
      }
    </div>
  )
}

// single request compoennt
const RequestSingle = (props: RequestSingleScheme) => {
  // separate props
  const { accept, place_id, event_id, ...reservation } = props;
  // dispatch and translate
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['buttons', 'general', 'inputs', 'success']);
  // component states
  const [shouldReject, setShouldReject] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  // function to handle textarea on change
  const handleRejectMessage = (e: FormEvent<HTMLTextAreaElement>) => {
    // set max character length
    if(e.currentTarget.value.length > maxRejectMessageLength) return;
    // store new value to state
    setRejectMessage(e.currentTarget.value)
  }

  // function to reject request
  const reject = async () => {
    try {
      // send API request
      await rejectRequest(rejectMessage, reservation.id, place_id, event_id);
      // successfully rejected, dispatch success message
      dispatch(newMessage({ content: t("request_rejected", {ns: 'success'}), type: 'success' }));
    } catch(e: any) {
      dispatch(newMessage({ content: t(e.response.data.error), type: 'error' }));
    }
  }

  return (
    <div className="request-single">
      <div className="request-single__header">
        <div className="request-sigle__guest">
          <div className="request-single__time label--gray">{ 
            'created_at' in props &&
            formatReservationTime(new Date(props.created_at!), t)
          }</div>
          <h3>{props.customer_name}</h3>
          <div className="request-single__persons label--gray"><GroupSizeIcon /><span>{props.group_size}</span></div>
          {
            props.section_id && <div className="request-single__section label--gray">{t("requested_section", {ns: 'general'})}  <span>{props.section_name}</span></div>
          }
          <div className="request-single__age label--gray">{t("age_average", {ns: 'general'})} {props.age_average}</div>
        </div>
        {
          // accept and reject buttons
          !shouldReject &&
          <div className="request-single__actions-wrapper">
            {
              props.phone_number && 
              <a href={"tel:" + props.phone_number}>
                <div className="request-single__call-guest">
                  <PhoneIcon />
                </div>
              </a>
            }
            <div className="request-single__actions">
              <div className="button extra-small" onClick={() => accept(reservation)}>{t("accept", {ns: 'buttons'})}</div>
              <div className="button--invert extra-small" onClick={() => setShouldReject(true)}>{t("decline", {ns: 'buttons'})}</div>
            </div>
          </div>
        }
      </div>
      {
        // additional note
        props.note && <div className="request-single__note label--gray">{props.note}</div>
      }
      {
        // reject confirmation and enter reject message
        shouldReject &&
          <div className="request-single__decline">
            <div className="input-wrapper">
              <div className="label input__label">{t("request_decline_reason", {ns: 'general'})}</div>
              <textarea onChange={handleRejectMessage} value={rejectMessage} name="" id="" placeholder={t("request_decline_placeholder", {ns: 'inputs'})}></textarea>
              <div className={"request-single__characters" + ((maxRejectMessageLength - rejectMessage.length) < 3 ? " warning" : "")}>
                {rejectMessage.length} / {maxRejectMessageLength}
              </div>
            </div>
            <div className="request-single__decline-actions grid--2">
              <div className="button--invert no-margin" onClick={() => setShouldReject(false)}>{t("back", {ns: 'buttons'})}</div>
              <div className={"button no-margin" + (rejectMessage.length < 5 ? " disabled" : "")} onClick={reject}>{t("decline", {ns: 'buttons'})}</div>
            </div>
          </div>
      }
    </div>
  )
}

export default WaitingList;