import { LinkProps } from '../link';
import { NotificationsIcon, SettingsIcon, LockIcon, StaffIcon, CoctailIcon } from '../icons';
import { place_roles } from '../../app/config';

const links: LinkProps[] = [
  {
    to: "/",
    icon: <CoctailIcon className='nav-icon' />,
    text: "my_clubs",
    role: 0
  },
  {
    to: "/staff",
    icon: <StaffIcon className='nav-icon' />,
    text: "staff",
    role: place_roles.manager,
  },
  {
    to: "/block-list",
    icon: <LockIcon className='nav-icon' />,
    text: "blocked",
    role: place_roles.manager,
  },
  {
    to: "/notifications",
    icon: <NotificationsIcon className='nav-icon' />,
    text: "notifications",
    role: 0,
  },
  {
    to: "/settings",
    icon: <SettingsIcon className='nav-icon' />,
    text: "settings",
    role: 0
  }
];

export default links;