import { FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormWrapper, { Button, Input } from "../../components/form";
import { errorMsg, successMsg } from "../../features/user/userSlice";
import { isValidEmail } from "../../helpers/regex";
import API from '../../helpers/api';
import { useTranslation } from "react-i18next";

const EmailRecovery = () => {

  const [form, setForm] = useState({email: ''});
  const dispatch = useAppDispatch();
  const { error, success } = useAppSelector(state => state.user);
  // translation
  const {t} = useTranslation(['general', 'inputs', 'buttons', 'errors', 'success']);
  
  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setForm((prevState) => ({...prevState, [name]: value}));
  }

  const submitRecovery = () => {
    if(!isValidEmail(form.email)) return dispatch(errorMsg({ msg: t("invalid_email", {ns: 'errors'}) }))
    
    // send email
    API.put('/user/forgot/'+form.email);

    // reset error msg
    dispatch(errorMsg({msg: ""}));

    // dispatch success msg
    dispatch(successMsg({msg: t("recovery_success", {ns: 'success'})}));

    // reset email beacuse of duplicate messages
    setForm({email: ''});
  }


  return (
    <FormWrapper 
      title={t("recovery_title")}
      description={t("recovery_description_text")}
      error={error}
      success={success}
    >
      <Input label={t("email_label", {ns: 'inputs'})} type="text" name="email" value={form.email} placeholder={t("email_placeholder", {ns: 'inputs'})} onChange={handleChanges}/>
      <Button text={t("submit", {ns: 'buttons'})} onClick={submitRecovery}/>
    </FormWrapper>
  )
}

export default EmailRecovery;