import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'

interface TelInputInterface {
  phone_number: string,
  updatePhoneNumber: (params: any) => void
}

const TelInput = (props: TelInputInterface) => {

  // translation
  const {t} = useTranslation(['inputs']);

  const handleInput = (phone:any) => {
    props.updatePhoneNumber(phone);
  }
  
  return (
    <PhoneInput
      defaultCountry='HR'
      value={props.phone_number}
      onChange={handleInput}
      placeholder={t("numbers_placeholder", {ns: 'inputs'})}
      limitMaxLength
      />
  )
}

export default TelInput;
