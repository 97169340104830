import { AppDispatch } from '../../../app/store';
import { updatePlace } from '../../../features/places/placesSlice';
import { fileCompress } from '../../../helpers/fileCompress';

interface updatePlaceForm {
  name: string,
  banner: string | File,
  logo: string | File,
  contact_number: string,
  email: string,
  automatic_blocking: boolean,
  place_id: number,
  slug: string,
  facebook: string,
  instagram: string,
  address: string,
  country_code: string
}
 
export const placeUpdateSubmit = async (updateForm: updatePlaceForm, dispatch: AppDispatch) => {
  try {
    if(updateForm.name === '')
      return { error: 'input_all_data' };

    var query = {
      place_id: updateForm.place_id,
      name: updateForm.name,
      contact_number: updateForm.contact_number,
      email: updateForm.email,
      automatic_blocking: updateForm.automatic_blocking,
      slug: updateForm.slug,
      facebook: updateForm.facebook,
      instagram: updateForm.instagram,
      country_code: updateForm.country_code,
      address: updateForm.address
    };

    let formData = new FormData();

    formData.append('query', JSON.stringify(query));

    if(typeof updateForm.banner !== 'string')
    {
      // compress file
      var image = await fileCompress(updateForm.banner);
      if(image.size > 1.5 * 1000000)
        return {error: 'file_large'}
      formData.append('banner', image);
    }

    if(typeof updateForm.logo !== 'string')
    {
      // compress file
      var image = await fileCompress(updateForm.logo);
      if(image.size > 1.5 * 1000000)
        return {error: 'file_large'}
      formData.append('logo', image);
    }

    await dispatch(updatePlace({formData, query}));

    return {success: 'place_success_edit'};
  } catch {
    return { error: 'something_wrong' };
  }
}