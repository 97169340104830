import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormWrapper, { Button, CustomCheckbox, Input } from "../../components/form";
import LanguageSwitcher from "../../components/language";
import { login, errorMsg, successMsg } from "../../features/user/userSlice";

const Login = () => {
  const dispatch = useAppDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const { error, success } = useAppSelector(state => state.user);

  // translation
  const {t} = useTranslation(['general', 'inputs', 'buttons', 'errors']);
  // email and password states
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // if error exists, and user started typing again, remove error
    if(error !== "") dispatch(errorMsg({ msg: "" }));
  }, [email, password]);

  // function to handle login button click
  const tryToLogin = async () => {
    // fill in all data error if needed
    if(email === "" || password === "") return dispatch(errorMsg({ msg: t("input_all_data", {ns: 'errors'}) }));
    // if all data filled, dispatch login
    let response = await dispatch(login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      returnURL: searchParams.get('returnURL')
    }));
    if(response.type === "users/login/rejected")
      // something wrong
      dispatch(errorMsg({ msg: t("wrong_credentials", {ns: 'errors'}) }));
  }

  return (
    <FormWrapper
      title={t("login_title")}
      description={t("login_description_text")}
      isLogin={true}
      error={error}
      success={success}
    >
      <LanguageSwitcher isLogin={true} />
      <Input label={t("email_label", {ns: 'inputs'})} type="text" name="email" placeholder={t("email_placeholder", {ns: 'inputs'})} onChange={e => setEmail(e.target.value)} onKeyDown={e => e.key === 'Enter' && tryToLogin()} />
      <Input label={t("password_label", {ns: 'inputs'})} type="password" name="password" placeholder={t("password_placeholder", {ns: 'inputs'})} onChange={e => setPassword(e.target.value)} onKeyDown={e => e.key === 'Enter' && tryToLogin()} />
      <CustomCheckbox label={t("remember_me_label", {ns: 'inputs'})} name="rememberMe" value="true" checked={rememberMe} onChange={e => setRememberMe(prev => !prev)} />
      <Button text={t("login_button", {ns: 'buttons'})} onClick={async () => await tryToLogin()} />
    </FormWrapper>
  )
}

export default Login;