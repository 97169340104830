import { StaffState, formatPlaces } from "./rolesHelpers";
import { _format } from "../../../helpers";
import { AppDispatch } from "../../../app/store";
import { assignStaff } from "../../../features/staff/staffSlice";
import { newInvitations } from "../../../features/invitations/invitationSlice";
import { isValidEmail } from "../../../helpers/regex";

export const editStaff = async (initialState:StaffState[], currentState:StaffState[], role: number, email: string, dispatch:AppDispatch) => {

  try {
    if(!isValidEmail(email)) return { error:'invalid_email' }
    // format places before N
    const places_before = _format(initialState, 'place_id');
    // currentState.role == true && initialState == false
    const create_places = currentState.filter(place => !!place.has_role && !places_before[place.place_id].has_role);

    // currentState.role == false && initialState == true
    const delete_places = currentState.filter(place => !place.has_role && !!places_before[place.place_id].has_role);

    if(!create_places.length && !delete_places.length) return {error: 'no_changes'};

    let places = formatPlaces(create_places, delete_places);

    // format query
    var query = {
      email,
      role,
      places
    }
    
    await dispatch(assignStaff({query})).then((res) =>
      // dispatch sent invitations on assign staff fullified
      dispatch(newInvitations(res.payload.invites))
    );

    if(!create_places.length && delete_places.length > 0)
      return {sucess: 'staff_success_revoke'};
    else if(!delete_places.length && create_places.length > 0)
      return {sucess: 'staff_success_assign'};
    else 
      return {sucess: 'staff_success_edit'};
  }
  catch {
    return {error: 'not_authorized'};
  }
}