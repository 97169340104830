import API from '../../helpers/api';

export const invitationAPI = {
  get,
  getSent,
  accept,
  decline,
  revoke
}

// function to get invitations 
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get invitations
    let res = await API.get('/user/invitations/');
    // return invitations
    return res.data.invitations;
  } catch {
    return rejectWithValue();
  }
}

// function to get invitations 
async function getSent(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get invitations
    let res = await API.get('/user/invitations/sent');
    // return invitations
    return res.data.invitations;
  } catch {
    return rejectWithValue();
  }
}

// function to accept invitations
async function accept(params: string, { dispatch, rejectWithValue }:any) {
  try {
    // accept invitation
    await API.put(`/user/invitations/${params}/accept`);
    // return hash
    return params;
  } catch {
    return rejectWithValue();
  }
}

// function to decline invitations
async function decline(params: string, { dispatch, rejectWithValue }:any) {
  try {
    // decline invitation
    await API.delete(`/user/invitations/${params}/reject`);
    // return hash
    return params;
  } catch {
    return rejectWithValue();
  }
}

// function to revoke invitations
async function revoke(params: string, { dispatch, rejectWithValue }:any) {
  try {
    // decline invitation
    await API.delete(`/user/invitations/${params}/revoke`);
    // return hash
    return params;
  } catch {
    return rejectWithValue();
  }
}