import { StaffDetails } from "./main-details"
import { SelectRole, DisplayRole } from "./select-role"
import { PlacesDisplay } from "./placesDisplay";
import { EmailInput } from "./emailInput"

const StaffPopups = {
  SelectRole,
  StaffDetails, 
  PlacesDisplay,
  EmailInput,
  DisplayRole
}

export default StaffPopups;