

import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useAppDispatch } from '../../app/hooks';
import { newMessage } from '../../features/messages/messagesSlice';

interface QRScanScheme {
  opened: boolean, 
  closeCamera: () => void, 
  setQRCode: (code: string) => void
  event_id: string
}

const QRScan = (props:QRScanScheme) => {
  const [data, setData] = useState('');
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    setData('');
  }, [props.opened]);

  useEffect(() => {
    if(data !== '') {
      // qr data = "small_code#event_id"
      let qr_data = data.split("#", 2);
      // close if no data
      if(!qr_data[0] && !qr_data[1])
        props.closeCamera();
      // event id on qr code and currently event id not compatibile
      if(props.event_id !== qr_data[1]) {
        // dispatch error message
        dispatch(newMessage({content: 'Invalid QR code.', type: 'error'}));
        // close camera
        props.closeCamera();
      }
      else {
        // set code!
        props.setQRCode(qr_data[0]);
        // close camera
        props.closeCamera();
      }
    }
  }, [data]);

  if(!props.opened)
    return <></>
  return (
    props.opened && <>
      <QrReader onResult={(result, error) => {
          if (!!result) {
            setData(result.getText());
          }
        }}
        className="qr-scanner-wrapper"
        constraints={{ facingMode: 'environment' }}
      />
    </>
  );
};

export default QRScan;
