import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSlice from '../features/user/userSlice';
import placesSlice from '../features/places/placesSlice';
import eventsSlice from '../features/events/eventsSlice';
import staffSlice from '../features/staff/staffSlice';
import invitationSlice from '../features/invitations/invitationSlice';
import messagesSlice from '../features/messages/messagesSlice';
import blockedSlice from '../features/blocked-numbers/blockedSlice';
import promptSlice from '../features/prompt/promptSlice';
import currencySlice from '../features/currency-list/currencySlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    places: placesSlice,
    events: eventsSlice,
    staff: staffSlice,
    invitations: invitationSlice,
    messages: messagesSlice,
    blocked_numbers: blockedSlice,
    prompt: promptSlice,
    currencies: currencySlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
