import { useEffect, useState } from 'react';
import { ContentTitle } from '../../components/titles';
import StaffPopup from '../../components/popup/staff-popup';
import NewStaffPopup from '../../components/popup/new-staff-popup';
import StaffSection from './section';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getStaff } from '../../features/staff/staffSlice';
import StaffScheme from '../../features/staff/staffEnum';
import Loading from '../../components/loading/loading';
import Invitations from '../../components/invitations';
import { useTranslation } from 'react-i18next';
import { place_roles } from '../../app/config';

// function to sort staff
const sortStaff = (staffs: Record<string, StaffScheme>, role: number) => {

  var role_members: StaffScheme[] = [];

  for(const staff in staffs) {
    // new staff object
    let new_staff = {
      email: staffs[staff].email,
      name: staffs[staff].name,
      id: staffs[staff].id,
      places: {}
    } as StaffScheme;
    // filter places
    for(const [key, place] of Object.entries( staffs[staff].places ).filter( ( [key, value] ) => value.role === role ) ) 
      new_staff.places[key] = place;
    // if place is not empty push to managers 
    if(Object.keys(new_staff.places).length > 0)
      role_members.push(new_staff)
  }
  // return sorted array
  return role_members.sort((a, b) => a.name.localeCompare(b.name));
}

const Staff = () => {
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'menu', 'buttons', 'roles'])
  // state for popup
  let [staffPopupOpened, setStaffPopupState] = useState({email: '', role: 0});
  let [newStaffPopupOpened, setNewStaffPopupState] = useState(false);

  // staff from redux
  const { staff } = useAppSelector(state => state.staff);

  // open and close popup functions
  const openStaffPopup = (email: string, role: number) => {setStaffPopupState({email, role}); closeNewStaffPopup()};
  const openNewStaffPopup = () => {setNewStaffPopupState(true); closeStaffPopup()};
  const closeStaffPopup = () => setStaffPopupState({email: '', role: 0});
  const closeNewStaffPopup = () => setNewStaffPopupState(false);

  useEffect(() => {
    // function to load staff
    (async () => {
      await dispatch(getStaff(true));
      setLoaded(true)
    })();
  }, [])

  if(!loaded) // manage loading page
    return <Loading />

  // sort staff
  const managers = sortStaff(staff, place_roles.manager);
  const barman = sortStaff(staff, place_roles.barmen);
  const hostesses = sortStaff(staff, place_roles.hostess);

  return (
    <>
      <ContentTitle title={t("staff", {ns: "menu"})} button={t("add_staff", {ns: 'buttons'})} onClick={openNewStaffPopup} />
      { !managers.length && !hostesses.length && !barman.length && <div className='empty-section'>{t("no_staff")}</div> }
      <StaffSection title={t("managers", {ns: 'roles'})} personnel={managers} current_role={place_roles.manager} onPersonnelClick={openStaffPopup} />
      <StaffSection title={t("barman", {ns: 'roles'})} personnel={barman} current_role={place_roles.barmen} onPersonnelClick={openStaffPopup} />
      <StaffSection title={t("hostesses", {ns: 'roles'})} personnel={hostesses} current_role={place_roles.hostess} onPersonnelClick={openStaffPopup} />
      <StaffPopup selected={staffPopupOpened} isOpened={(staffPopupOpened.email === '') ? false : true} close={closeStaffPopup} />
      <NewStaffPopup isOpened={newStaffPopupOpened} close={closeNewStaffPopup} />
      <Invitations title={t("sent_invites")} />
    </>
  )
}

export default Staff;