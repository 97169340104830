import { place_roles } from "../../../app/config";
import PlacesScheme from "../../../features/places/placesEnum";
import StaffScheme from "../../../features/staff/staffEnum";

export const roles = [
  {
   name: "choose",
   value: "",
   role: 0
 },
 {
   name: "hostess",
   value: "hostess",
   role: 1
 },
 {
  name: "barmen",
  value: "barmen",
  role: 2
 },
 {
   name: "manager",
   value: "manager",
   role: 3
 },
 {
   name: "owner",
   value: "owner",
   role: 4
 }
]

export interface StaffState extends PlacesScheme {
  has_role?: boolean
}

// filter places that user can select by selected role
export const filterPlaces = (places: Record<string, PlacesScheme>, role:number, staff: StaffScheme) =>
{
  // selected role zero return empty array
  if(role === 0) return [];

  const filtered_places:StaffState[] = [];

  Object.keys(places).forEach((slug) => {

    if(places[slug].role > role && places[slug].role >= place_roles.manager) {

      // create new object
      let new_object = Object.assign({}, places[slug]);

      // assign to filtered_places
      let new_id = filtered_places.push(new_object);

      // assign role if has
      if(staff && staff.places[slug] && staff.places[slug].role === role)
        filtered_places[new_id-1].has_role = true;
      else 
        filtered_places[new_id-1].has_role = false;

    }
    
  })
  return filtered_places;
}

// format places query
export const formatPlaces = (adds: StaffState[], removes: StaffState[]) => {
  let places = '';

  for(const add of adds)
    places += `"${add.place_id}": "invite",`

  for(const remove of removes)
    places += `"${remove.place_id}": "delete",`

  // remove last comma
  places = places.slice(0, -1);

  // add {}
  places = '{'+places+'}';

  return JSON.parse(places);
}
