import { DaysPast } from "../../../helpers"
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg'
import { SaveIcon } from '../../icons'
import { useTranslation } from "react-i18next"

interface iProps {
  cancelOperation: () => void,
  submitOperation: () => void,
  changeStatus: () => void,
  event_status: boolean | null,
  action: "create" | "edit",
  isReservationStart: boolean,
  isEventCancelled: boolean
}

export const EventActions = (props: iProps) => {
  const {t} = useTranslation(['buttons']);

  if(props.isEventCancelled)
    return <></>

  return (
    <div className="edit__actions grid">
      {
        props.action !== "create" ?
          <div className="edit__button button--invert" onClick={props.changeStatus}>
            { !props.isReservationStart ? t("delete_event") : t("cancel_event") }
          </div>
        :
          <div className="edit__button button--invert" onClick={props.cancelOperation}>{t("back")}</div>
      }
      <div className="edit__button button--back" onClick={props.submitOperation}>
        {(props.action === "create") ? t("add_event") : t("save")}
        <SaveIcon className="button__icon" />
      </div>
    </div>
  );
}