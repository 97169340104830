import { useAppSelector } from "../../app/hooks";

const Prompt = () => {

  const { prompt } = useAppSelector(state => state.prompt);
  
  return (
    <div className={"prompt-container" + (prompt.message !== '' ? " opened" : "")}>
      <div className="prompt-box">
        {/* <div className="prompt-box__title">
          <h3>{prompt.title}</h3>
        </div> */}
        <div className="prompt-box__message">
          <h2>{prompt.message}</h2>
        </div>
        <div className="prompt-box__actions grid">
          <div className="button--invert" onClick={prompt.decline!}>
            {prompt.decline_text}
          </div>
          <div className={`button ${(prompt.type === "delete") ? "decline" : "accept"}`} onClick={prompt.accept!}>
            {prompt.accept_text}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Prompt;