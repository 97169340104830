import { useTranslation } from "react-i18next";
import { PlaceLabel } from "./placeLabel";
import {StaffState} from './rolesHelpers'

interface iProps {
  staff: StaffState[],
  assignRole: (index:number, role:boolean) => void
}

export const PlacesDisplay = (props: iProps) => {
  const { t } = useTranslation(['general']);

  return (
    <div className="staff-details__role input-wrapper">
      <div className="label input__label">{(props.staff.length !== 0) ? t("places_display_label") : t("no_places_display") }</div>
      <div className="checkbox-container">
        {props.staff.map((place, index) => <PlaceLabel key={index} {...place} index={index} assignRole={props.assignRole}/>)} 
      </div>
    </div>
  );
}