import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { blockedAPI } from './blockedAPI';


export interface BlockedScheme {
  phone_number: string,
  total_count: number,
  places: Record<string, number>
}

const initialState = {
  blocked_numbers: {} as Record<string, BlockedScheme>
};

// get invitations thunk
export const getBlockedNumbers = createAsyncThunk('blocked/get', blockedAPI.get);
// edit staff thunk
export const editBlockedNumber = createAsyncThunk('blocked/edit', blockedAPI.editBlockedNumber);

// create user slice
const blockedSlice = createSlice({
  name: 'blocked_numbers',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getBlockedNumbers.fulfilled, (state, { payload }) => {
      // store blocked numbers
      state.blocked_numbers = payload;
    })
    builder.addCase(editBlockedNumber.fulfilled, (state, { payload }) => {
      //copy places
      let new_state = { ...state.blocked_numbers[payload.phone] };
      let { places } = new_state;
      // get places slugs
      for(let slug of Object.keys(payload.slugs)) {
        if(payload.slugs[slug] === 'add')
          places![slug] = 3;
        else 
          delete places![slug];
      }
    })
  },
})

export const { } = blockedSlice.actions;

export default blockedSlice.reducer;