import { ReactComponent as CircleAdd } from '../../assets/icons/circle-add.svg';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const NewEvent = () => {
  let navigate = useNavigate();
  const {t} = useTranslation(['general']);
  return (
    <div className="new-event" onClick={() => navigate("../events/new")}>
      <CircleAdd />
      <h2 className="new-event__h2">{t("new_event")}</h2>
    </div>
  );
}

export default NewEvent;