import CroatianSVG from '../../assets/icons/croatian.svg';
import EnglishSVG from '../../assets/icons/english.svg';

interface IconScheme {
  className?: string
}

const Logo = (props: IconScheme) => (
  <svg width="84" height="24" viewBox="0 0 84 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 19.64C10.5 19.82 9.42 21.41 7.86 21.41C6.51 21.41 5.04 20.36 5.04 18.41V9.8H9.57V7.97H5.04V2.99L3.06 3.23V7.97H0.66V9.8H3.06V18.86C3.06 21.86 5.16 23.24 7.5 23.24C10.02 23.21 11.49 21.17 11.49 21.17L10.5 19.64ZM26.0742 8V10.43C24.6642 8.78 22.5642 7.76 20.2242 7.76C15.9342 7.76 12.4842 11.21 12.4842 15.5C12.4842 19.76 15.9342 23.24 20.2242 23.24C22.5642 23.24 24.6642 22.19 26.0742 20.57V23H28.0542V8H26.0742ZM20.2542 21.35C17.0442 21.35 14.4642 18.77 14.4642 15.5C14.4642 12.23 17.0442 9.65 20.2542 9.65C23.4942 9.65 26.0142 12.23 26.0142 15.5C26.0142 18.77 23.4942 21.35 20.2542 21.35ZM40.2844 7.76C37.9444 7.76 35.8144 8.81 34.4044 10.46V0.679999H32.4244V23H34.4044V20.51C35.8144 22.19 37.9444 23.24 40.2844 23.24C44.5744 23.24 48.0244 19.76 48.0244 15.5C48.0244 11.21 44.5744 7.76 40.2844 7.76ZM40.2244 21.35C37.0144 21.35 34.4644 18.77 34.4644 15.5C34.4644 12.23 37.0144 9.65 40.2244 9.65C43.4344 9.65 45.9844 12.23 45.9844 15.5C45.9844 18.77 43.4344 21.35 40.2244 21.35ZM57.9586 23.24C62.2186 23.24 65.6986 19.76 65.6986 15.5C65.6986 11.21 62.2186 7.76 57.9586 7.76C53.6686 7.76 50.2186 11.21 50.2186 15.5C50.2186 19.76 53.6686 23.24 57.9586 23.24ZM57.9886 21.35C54.7786 21.35 52.2286 18.77 52.2286 15.5C52.2286 12.23 54.7786 9.62 57.9886 9.62C61.2286 9.62 63.7486 12.23 63.7486 15.5C63.7486 18.77 61.2286 21.35 57.9886 21.35ZM75.6539 23.24C79.9139 23.24 83.3939 19.76 83.3939 15.5C83.3939 11.21 79.9139 7.76 75.6539 7.76C71.3639 7.76 67.9139 11.21 67.9139 15.5C67.9139 19.76 71.3639 23.24 75.6539 23.24ZM75.6839 21.35C72.4739 21.35 69.9239 18.77 69.9239 15.5C69.9239 12.23 72.4739 9.62 75.6839 9.62C78.9239 9.62 81.4439 12.23 81.4439 15.5C81.4439 18.77 78.9239 21.35 75.6839 21.35Z" fill="white"/>
  </svg>
)

const BigLogo = (props: IconScheme) => (
  <svg width="126" height="35" viewBox="0 0 126 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 28.96C15.75 29.23 14.13 31.615 11.79 31.615C9.765 31.615 7.56 30.04 7.56 27.115V14.2H14.355V11.455H7.56V3.985L4.59 4.345V11.455H0.99V14.2H4.59V27.79C4.59 32.29 7.74 34.36 11.25 34.36C15.03 34.315 17.235 31.255 17.235 31.255L15.75 28.96ZM39.1113 11.5V15.145C36.9963 12.67 33.8463 11.14 30.3363 11.14C23.9013 11.14 18.7263 16.315 18.7263 22.75C18.7263 29.14 23.9013 34.36 30.3363 34.36C33.8463 34.36 36.9963 32.785 39.1113 30.355V34H42.0813V11.5H39.1113ZM30.3813 31.525C25.5663 31.525 21.6963 27.655 21.6963 22.75C21.6963 17.845 25.5663 13.975 30.3813 13.975C35.2413 13.975 39.0213 17.845 39.0213 22.75C39.0213 27.655 35.2413 31.525 30.3813 31.525ZM60.4266 11.14C56.9166 11.14 53.7216 12.715 51.6066 15.19V0.519999H48.6366V34H51.6066V30.265C53.7216 32.785 56.9166 34.36 60.4266 34.36C66.8616 34.36 72.0366 29.14 72.0366 22.75C72.0366 16.315 66.8616 11.14 60.4266 11.14ZM60.3366 31.525C55.5216 31.525 51.6966 27.655 51.6966 22.75C51.6966 17.845 55.5216 13.975 60.3366 13.975C65.1516 13.975 68.9766 17.845 68.9766 22.75C68.9766 27.655 65.1516 31.525 60.3366 31.525ZM86.9379 34.36C93.3279 34.36 98.5479 29.14 98.5479 22.75C98.5479 16.315 93.3279 11.14 86.9379 11.14C80.5029 11.14 75.3279 16.315 75.3279 22.75C75.3279 29.14 80.5029 34.36 86.9379 34.36ZM86.9829 31.525C82.1679 31.525 78.3429 27.655 78.3429 22.75C78.3429 17.845 82.1679 13.93 86.9829 13.93C91.8429 13.93 95.6229 17.845 95.6229 22.75C95.6229 27.655 91.8429 31.525 86.9829 31.525ZM113.481 34.36C119.871 34.36 125.091 29.14 125.091 22.75C125.091 16.315 119.871 11.14 113.481 11.14C107.046 11.14 101.871 16.315 101.871 22.75C101.871 29.14 107.046 34.36 113.481 34.36ZM113.526 31.525C108.711 31.525 104.886 27.655 104.886 22.75C104.886 17.845 108.711 13.93 113.526 13.93C118.386 13.93 122.166 17.845 122.166 22.75C122.166 27.655 118.386 31.525 113.526 31.525Z" fill="white"/>
  </svg>
)

const NotificationsIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33334 4.16667C8.33334 3.72464 8.50894 3.30072 8.8215 2.98816C9.13406 2.67559 9.55798 2.5 10 2.5C10.442 2.5 10.866 2.67559 11.1785 2.98816C11.4911 3.30072 11.6667 3.72464 11.6667 4.16667C12.6237 4.61919 13.4395 5.32361 14.0267 6.20442C14.6139 7.08523 14.9504 8.10923 15 9.16667V11.6667C15.0627 12.1848 15.2462 12.6809 15.5357 13.1151C15.8252 13.5493 16.2126 13.9095 16.6667 14.1667H3.33334C3.78746 13.9095 4.17485 13.5493 4.46433 13.1151C4.75381 12.6809 4.9373 12.1848 5.00001 11.6667V9.16667C5.04964 8.10923 5.38609 7.08523 5.9733 6.20442C6.56051 5.32361 7.37634 4.61919 8.33334 4.16667" strokeLinecap="round" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M7.5 14.1667V15C7.5 15.663 7.76339 16.2989 8.23223 16.7678C8.70107 17.2366 9.33696 17.5 10 17.5C10.663 17.5 11.2989 17.2366 11.7678 16.7678C12.2366 16.2989 12.5 15.663 12.5 15V14.1667" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  </svg>  
);

const SettingsIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.60417 3.5975C8.95917 2.13417 11.0408 2.13417 11.3958 3.5975C11.4491 3.81733 11.5535 4.02148 11.7006 4.19333C11.8477 4.36518 12.0332 4.49988 12.2422 4.58645C12.4512 4.67303 12.6776 4.70904 12.9032 4.69156C13.1287 4.67407 13.3469 4.60359 13.54 4.48583C14.8258 3.7025 16.2983 5.17417 15.515 6.46083C15.3974 6.65388 15.327 6.87195 15.3096 7.09731C15.2922 7.32267 15.3281 7.54897 15.4146 7.75782C15.5011 7.96666 15.6356 8.15215 15.8073 8.29921C15.9789 8.44627 16.1829 8.55075 16.4025 8.60417C17.8658 8.95917 17.8658 11.0408 16.4025 11.3958C16.1827 11.4491 15.9785 11.5535 15.8067 11.7006C15.6348 11.8477 15.5001 12.0332 15.4135 12.2422C15.327 12.4512 15.291 12.6776 15.3084 12.9032C15.3259 13.1287 15.3964 13.3469 15.5142 13.54C16.2975 14.8258 14.8258 16.2983 13.5392 15.515C13.3461 15.3974 13.1281 15.327 12.9027 15.3096C12.6773 15.2922 12.451 15.3281 12.2422 15.4146C12.0333 15.5011 11.8479 15.6356 11.7008 15.8073C11.5537 15.9789 11.4492 16.1829 11.3958 16.4025C11.0408 17.8658 8.95917 17.8658 8.60417 16.4025C8.5509 16.1827 8.44648 15.9785 8.29941 15.8067C8.15233 15.6348 7.96676 15.5001 7.75779 15.4135C7.54882 15.327 7.32236 15.291 7.09685 15.3084C6.87133 15.3259 6.65313 15.3964 6.46 15.5142C5.17417 16.2975 3.70167 14.8258 4.485 13.5392C4.60258 13.3461 4.67296 13.1281 4.6904 12.9027C4.70785 12.6773 4.67187 12.451 4.58539 12.2422C4.49892 12.0333 4.36438 11.8479 4.19273 11.7008C4.02107 11.5537 3.81714 11.4492 3.5975 11.3958C2.13417 11.0408 2.13417 8.95917 3.5975 8.60417C3.81733 8.5509 4.02148 8.44648 4.19333 8.29941C4.36518 8.15233 4.49988 7.96676 4.58645 7.75779C4.67303 7.54882 4.70904 7.32236 4.69156 7.09685C4.67407 6.87133 4.60359 6.65313 4.48583 6.46C3.7025 5.17417 5.17417 3.70167 6.46083 4.485C7.29417 4.99167 8.37417 4.54333 8.60417 3.5975Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const LockIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33334 6.66667V5C3.33334 4.55797 3.50893 4.13405 3.82149 3.82149C4.13405 3.50893 4.55798 3.33333 5 3.33333H6.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.33334 13.3333V15C3.33334 15.442 3.50893 15.866 3.82149 16.1785C4.13405 16.4911 4.55798 16.6667 5 16.6667H6.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 3.33333H15C15.442 3.33333 15.866 3.50893 16.1785 3.82149C16.4911 4.13405 16.6667 4.55797 16.6667 5V6.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 16.6667H15C15.442 16.6667 15.866 16.4911 16.1785 16.1785C16.4911 15.866 16.6667 15.442 16.6667 15V13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.5 9.16666H7.5C7.03976 9.16666 6.66666 9.53976 6.66666 10V12.5C6.66666 12.9602 7.03976 13.3333 7.5 13.3333H12.5C12.9602 13.3333 13.3333 12.9602 13.3333 12.5V10C13.3333 9.53976 12.9602 9.16666 12.5 9.16666Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.33334 9.16667V7.5C8.33334 7.05797 8.50893 6.63405 8.82149 6.32149C9.13405 6.00893 9.55798 5.83333 10 5.83333C10.442 5.83333 10.866 6.00893 11.1785 6.32149C11.4911 6.63405 11.6667 7.05797 11.6667 7.5V9.16667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const StaffIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49999 9.16667C9.34094 9.16667 10.8333 7.67428 10.8333 5.83333C10.8333 3.99238 9.34094 2.5 7.49999 2.5C5.65904 2.5 4.16666 3.99238 4.16666 5.83333C4.16666 7.67428 5.65904 9.16667 7.49999 9.16667Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 2.60833C14.0504 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0504 8.88308 13.3333 9.06667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CoctailIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66667 17.5H13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.5V17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.1667 2.5H5.83333L5 8.33333C5 10.8433 7.23833 12.5 10 12.5C10.7271 12.5046 11.4494 12.3819 12.1342 12.1375C12.819 11.8931 13.5476 11.4695 14.1225 10.7833C14.6974 10.0972 15.0085 9.22843 15 8.33333L14.1667 2.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const SaveIcon = (props: IconScheme) => (
  <svg width="15" height="15" viewBox="0 0 15 15" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 2.5H10L12.5 5V11.25C12.5 11.5815 12.3683 11.8995 12.1339 12.1339C11.8995 12.3683 11.5815 12.5 11.25 12.5H3.75C3.41848 12.5 3.10054 12.3683 2.86612 12.1339C2.6317 11.8995 2.5 11.5815 2.5 11.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 10C8.19036 10 8.75 9.44036 8.75 8.75C8.75 8.05964 8.19036 7.5 7.5 7.5C6.80964 7.5 6.25 8.05964 6.25 8.75C6.25 9.44036 6.80964 10 7.5 10Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.75 2.5V5H5V2.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const LogoutIcon = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.5H1M1 4.5L5 1M1 4.5L5 8" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const StepBackIcon = (props: IconScheme) => (
  <svg width="12" height="6" viewBox="0 0 12 6" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3905 0.687656C11.563 0.903287 11.528 1.21793 11.3124 1.39044L6.00004 5.64032L0.687696 1.39044C0.472064 1.21793 0.437103 0.903287 0.609609 0.687656C0.782113 0.472025 1.09676 0.437064 1.31239 0.609569L6.00004 4.35969L10.6877 0.609569C10.9033 0.437064 11.218 0.472025 11.3905 0.687656Z" fill="white" fillOpacity="0.7"/>
  </svg>
);

const ClockIcon = (props: IconScheme) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 14C11.0899 14 14 11.0898 14 7.49998C14 3.91013 11.0899 0.999985 7.5 0.999985C3.91015 0.999985 1 3.91013 1 7.49998C1 11.0898 3.91015 14 7.5 14ZM7.5 15C11.6421 15 15 11.6421 15 7.49998C15 3.35785 11.6421 -1.52588e-05 7.5 -1.52588e-05C3.35786 -1.52588e-05 0 3.35785 0 7.49998C0 11.6421 3.35786 15 7.5 15Z" fill="#EEEEEE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3.49998C7.77614 3.49998 8 3.72384 8 3.99998V7.3486L9.91603 10.2226C10.0692 10.4524 10.0071 10.7628 9.77735 10.916C9.54759 11.0692 9.23715 11.0071 9.08397 10.7773L7.08397 7.77734C7.02922 7.6952 7 7.5987 7 7.49998V3.99998C7 3.72384 7.22386 3.49998 7.5 3.49998Z" fill="white"/>
  </svg>
);

const SelectArrow = (props: IconScheme) => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1L6 5L1 1" stroke="white" strokeOpacity="0.7" strokeLinecap="round"/>
  </svg>
);

const LanguageSelectArrow = (props: IconScheme) => (
  <svg className={props.className} width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.25L3.5 3.75L6 1.25" stroke="#C2C2C2" strokeLinecap="round"/>
  </svg>
);

const ButtonArrow = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.5L11 4.5M11 4.5L7 8M11 4.5L7 1" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const BackArrow = (props: IconScheme) => (
  <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 7L1 7M1 7L6 13M1 7L6 1" stroke="#C2C2C2" strokeLinecap="round"/>
  </svg>
);

const EditIcon = (props: IconScheme) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_109_3371)">
      <path d="M11.8789 2.94967L9.05025 0.120961C8.97281 0.0435152 8.86771 0 8.75815 0C8.64859 0 8.54349 0.0435152 8.46605 0.120961L0.209726 8.37728C0.135364 8.45175 0.0921246 8.55167 0.0889298 8.65682L0.000191847 11.5743C-0.00327835 11.6882 0.0404572 11.7985 0.120988 11.879C0.198599 11.9566 0.303697 12 0.41309 12C0.417277 12 0.421463 11.9999 0.425704 11.9998L3.3431 11.9109C3.44825 11.9077 3.54822 11.8646 3.62264 11.7902L11.879 3.53393C12.0402 3.37259 12.0402 3.11101 11.8789 2.94967ZM3.15416 11.0901L0.839374 11.1606L0.909825 8.8457L5.92917 3.82631L8.17378 6.07053L3.15416 11.0901ZM8.75804 5.48633L6.51337 3.24216L8.75809 0.997379L11.0025 3.24183L8.75804 5.48633Z" fill="#C2C2C2"/>
    </g>
    <defs><clipPath id="clip0_109_3371"><rect width="12" height="12" fill="white"/></clipPath></defs>
  </svg>
);

const DownloadIcon = (props: IconScheme) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 14.875V16.625C3.5 17.0891 3.68437 17.5342 4.01256 17.8624C4.34075 18.1906 4.78587 18.375 5.25 18.375H15.75C16.2141 18.375 16.6592 18.1906 16.9874 17.8624C17.3156 17.5342 17.5 17.0891 17.5 16.625V14.875" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.125 9.625L10.5 14L14.875 9.625" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5 3.5V14" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const GuestsIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1482 3.86298C16.1482 4.54647 15.8767 5.20196 15.3934 5.68526C14.9101 6.16857 14.2546 6.44007 13.5711 6.44007C12.8876 6.44007 12.2321 6.16857 11.7488 5.68526C11.2655 5.20196 10.994 4.54647 10.994 3.86298C10.994 3.17949 11.2655 2.524 11.7488 2.0407C12.2321 1.5574 12.8876 1.28589 13.5711 1.28589C14.2546 1.28589 14.9101 1.5574 15.3934 2.0407C15.8767 2.524 16.1482 3.17949 16.1482 3.86298Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3572 13.5087C18.3572 12.2393 17.8529 11.022 16.9554 10.1244C16.0578 9.22688 14.8405 8.72266 13.5711 8.72266C12.3018 8.72266 11.0845 9.22688 10.1869 10.1244C9.80511 10.5062 9.49448 10.9459 9.26355 11.4228" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.0061 6.44013C9.0061 7.12362 8.73459 7.77911 8.25129 8.26241C7.76799 8.74572 7.1125 9.01722 6.42901 9.01722C5.74552 9.01722 5.09005 8.74572 4.60674 8.26241C4.12342 7.77911 3.85192 7.12362 3.85192 6.44013C3.85192 5.75664 4.12342 5.10115 4.60674 4.61785C5.09005 4.13455 5.74552 3.86304 6.42901 3.86304C7.1125 3.86304 7.76799 4.13455 8.25129 4.61785C8.73459 5.10115 9.0061 5.75664 9.0061 6.44013Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.2148 16.0858C11.2148 14.8165 10.7106 13.5992 9.81305 12.7016C8.91549 11.804 7.69815 11.2998 6.42881 11.2998C5.15946 11.2998 3.94214 11.804 3.04458 12.7016C2.14701 13.5992 1.64279 14.8165 1.64279 16.0858" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const WaitingListIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_880_146)">
    <path d="M2.625 4.125L3.75 5.25L5.625 3.375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.625 8.625L3.75 9.75L5.625 7.875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.625 13.125L3.75 14.25L5.625 12.375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.25 4.5H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.25 9H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.25 13.5H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_880_146">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const NameLabelsIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_827_146)">
    <path d="M5.89425 4.5H3.76875C3.36595 4.5 2.97965 4.66001 2.69483 4.94483C2.41001 5.22965 2.25 5.61595 2.25 6.01875V8.14425C2.25 8.547 2.40975 8.93325 2.69475 9.21825L7.28175 13.8052C7.42278 13.9463 7.59022 14.0582 7.7745 14.1345C7.95877 14.2109 8.15629 14.2502 8.35575 14.2502C8.55521 14.2502 8.75273 14.2109 8.937 14.1345C9.12128 14.0582 9.28872 13.9463 9.42975 13.8052L11.5552 11.6797C11.6963 11.5387 11.8082 11.3713 11.8845 11.187C11.9609 11.0027 12.0002 10.8052 12.0002 10.6057C12.0002 10.4063 11.9609 10.2088 11.8845 10.0245C11.8082 9.84022 11.6963 9.67278 11.5552 9.53175L6.9675 4.94475C6.68283 4.66012 6.2968 4.50016 5.89425 4.5V4.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.1798 13.8053L15.3053 11.6798C15.4463 11.5388 15.5582 11.3714 15.6346 11.1871C15.7109 11.0028 15.7502 10.8053 15.7502 10.6058C15.7502 10.4064 15.7109 10.2088 15.6346 10.0246C15.5582 9.84029 15.4463 9.67286 15.3053 9.53182L9.96753 4.19482" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.49993 6.75H4.49243" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_827_146">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const VoucherIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_715_281)">
    <path d="M3.75 15.75V3.75C3.75 3.35218 3.90804 2.97064 4.18934 2.68934C4.47064 2.40804 4.85218 2.25 5.25 2.25H12.75C13.1478 2.25 13.5294 2.40804 13.8107 2.68934C14.092 2.97064 14.25 3.35218 14.25 3.75V15.75L12 14.25L10.5 15.75L9 14.25L7.5 15.75L6 14.25L3.75 15.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5 6H8.625C8.32663 6 8.04048 6.11853 7.8295 6.3295C7.61853 6.54048 7.5 6.82663 7.5 7.125C7.5 7.42337 7.61853 7.70952 7.8295 7.9205C8.04048 8.13147 8.32663 8.25 8.625 8.25H9.375C9.67337 8.25 9.95952 8.36853 10.1705 8.5795C10.3815 8.79048 10.5 9.07663 10.5 9.375C10.5 9.67337 10.3815 9.95952 10.1705 10.1705C9.95952 10.3815 9.67337 10.5 9.375 10.5H7.5M9 10.5V11.625M9 4.875V6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_715_281">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const ScannerIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_715_268)">
    <path d="M3 5.25V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 12.75V13.5C3 13.8978 3.15804 14.2794 3.43934 14.5607C3.72064 14.842 4.10218 15 4.5 15H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 3H13.5C13.8978 3 14.2794 3.15804 14.5607 3.43934C14.842 3.72064 15 4.10218 15 4.5V5.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 15H13.5C13.8978 15 14.2794 14.842 14.5607 14.5607C14.842 14.2794 15 13.8978 15 13.5V12.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.5 8.25H3.75V9.75H4.5V8.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 8.25V9.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.25 8.25H10.5V9.75H11.25V8.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.25 8.25V9.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_715_268">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const OptionOnIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_715_278)">
    <path d="M3.75 9L7.5 12.75L15 5.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_715_278">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const List2DIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_826_146)">
    <path d="M13.5 3H4.5C3.67157 3 3 3.67157 3 4.5V13.5C3 14.3284 3.67157 15 4.5 15H13.5C14.3284 15 15 14.3284 15 13.5V4.5C15 3.67157 14.3284 3 13.5 3Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 12V12.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 9V9.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 6V6.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 12V12.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 9V9.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 6V6.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 6V6.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 12V12.0075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_826_146">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const List1DIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_826_157)">
    <path d="M9.75 3.75H15.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.75 6.75H13.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.75 11.25H15.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.75 14.25H13.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 3H3C2.58579 3 2.25 3.33579 2.25 3.75V6.75C2.25 7.16421 2.58579 7.5 3 7.5H6C6.41421 7.5 6.75 7.16421 6.75 6.75V3.75C6.75 3.33579 6.41421 3 6 3Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 10.5H3C2.58579 10.5 2.25 10.8358 2.25 11.25V14.25C2.25 14.6642 2.58579 15 3 15H6C6.41421 15 6.75 14.6642 6.75 14.25V11.25C6.75 10.8358 6.41421 10.5 6 10.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_826_157">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const GroupSizeIcon = (props: IconScheme) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_887_466)">
    <path d="M5.99992 7.33333C7.47268 7.33333 8.66659 6.13943 8.66659 4.66667C8.66659 3.19391 7.47268 2 5.99992 2C4.52716 2 3.33325 3.19391 3.33325 4.66667C3.33325 6.13943 4.52716 7.33333 5.99992 7.33333Z" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 14V12.6667C2 11.9594 2.28095 11.2811 2.78105 10.781C3.28115 10.281 3.95942 10 4.66667 10H7.33333C8.04058 10 8.71885 10.281 9.21895 10.781C9.71905 11.2811 10 11.9594 10 12.6667V14" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6667 2.08643C11.2404 2.23329 11.7488 2.56689 12.1118 3.03463C12.4749 3.50237 12.672 4.07765 12.672 4.66976C12.672 5.26187 12.4749 5.83715 12.1118 6.30489C11.7488 6.77262 11.2404 7.10623 10.6667 7.25309" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 14.0001V12.6668C13.9966 12.0782 13.7986 11.5073 13.4368 11.043C13.0751 10.5788 12.5699 10.2472 12 10.1001" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_887_466">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const AddIcon = (props: IconScheme) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 3.125V11.875" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.125 7.5H11.875" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ChangeTablesIcon = (props: IconScheme) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 5.13784C11.4583 3.30458 18.3333 6.05461 16.0417 12.8355M16.0417 12.8355L17.875 11.5546M16.0417 12.8355C16.0417 12.8355 15.6511 11.7182 14.6667 11.0022" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.292 16.0439C10.0837 17.8772 3.20866 15.1272 5.50033 8.34623M5.50033 8.34623L3.66699 9.62716M5.50033 8.34623C5.50033 8.34623 5.89088 9.46358 6.87533 10.1796" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PhoneIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const DocumentIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.25 12.75H6.75M11.25 9.75H6.75M11.25 6.75H6.75M4.5 15.75C4.30109 15.75 4.11032 15.671 3.96967 15.5303C3.82902 15.3897 3.75 15.1989 3.75 15V5.25L6.75 2.25H13.5C13.6989 2.25 13.8897 2.32902 14.0303 2.46967C14.171 2.61032 14.25 2.80109 14.25 3V15C14.25 15.1989 14.171 15.3897 14.0303 15.5303C13.8897 15.671 13.6989 15.75 13.5 15.75H4.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PenIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 15H6L13.875 7.12498C14.072 6.928 14.2282 6.69415 14.3348 6.43678C14.4415 6.17941 14.4963 5.90356 14.4963 5.62498C14.4963 5.34641 14.4415 5.07056 14.3348 4.81319C14.2282 4.55582 14.072 4.32197 13.875 4.12498C13.678 3.928 13.4442 3.77174 13.1868 3.66514C12.9294 3.55853 12.6536 3.50366 12.375 3.50366C12.0964 3.50366 11.8206 3.55853 11.5632 3.66514C11.3058 3.77174 11.072 3.928 10.875 4.12498L3 12V15Z" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.125 4.875L13.125 7.875" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CloseFormIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7.5L10 12.5L15 7.5" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const MoveToStackIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 9H3.00003" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5 9L7.50003 12" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5 8.99994L7.50003 5.99994" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 3.00006V15.0001" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const MoveToTableIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49997 9H15" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.49997 9L10.5 12" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.49997 8.99994L10.5 5.99994" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.00003 3.00006V15.0001" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const DoubleTableIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 7.12506C15 8.21908 14.5654 9.26829 13.7918 10.0419C13.0182 10.8155 11.969 11.2501 10.875 11.2501C9.78095 11.2501 8.73174 10.8155 7.95815 10.0419C7.18457 9.26829 6.74997 8.21908 6.74997 7.12506C6.74997 6.03104 7.18457 4.98183 7.95815 4.20825C8.73174 3.43466 9.78095 3.00006 10.875 3.00006C11.969 3.00006 13.0182 3.43466 13.7918 4.20825C14.5654 4.98183 15 6.03104 15 7.12506Z" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.25 10.875C11.25 11.969 10.8154 13.0182 10.0418 13.7918C9.26823 14.5654 8.21902 15 7.125 15C6.03098 15 4.98177 14.5654 4.20818 13.7918C3.4346 13.0182 3 11.969 3 10.875C3 9.78098 3.4346 8.73177 4.20818 7.95818C4.98177 7.1846 6.03098 6.75 7.125 6.75C8.21902 6.75 9.26823 7.1846 10.0418 7.95818C10.8154 8.73177 11.25 9.78098 11.25 10.875Z" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const SwitchTableIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 4.5C2.25 5.09674 2.48705 5.66903 2.90901 6.09099C3.33097 6.51295 3.90326 6.75 4.5 6.75C5.09674 6.75 5.66903 6.51295 6.09099 6.09099C6.51295 5.66903 6.75 5.09674 6.75 4.5C6.75 3.90326 6.51295 3.33097 6.09099 2.90901C5.66903 2.48705 5.09674 2.25 4.5 2.25C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5Z" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.75 8.25V6C15.75 5.60218 15.592 5.22064 15.3107 4.93934C15.0294 4.65804 14.6479 4.5 14.25 4.5H9.75003M9.75003 4.5L12 6.75M9.75003 4.5L12 2.25" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.25 9.75006V12.0001C2.25 12.3979 2.40804 12.7794 2.68934 13.0607C2.97064 13.342 3.35218 13.5001 3.75 13.5001H8.25M8.25 13.5001L6 11.2501M8.25 13.5001L6 15.7501" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.25 13.5C11.25 14.0967 11.4871 14.669 11.909 15.091C12.331 15.5129 12.9033 15.75 13.5 15.75C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5C15.75 12.9033 15.5129 12.331 15.091 11.909C14.669 11.4871 14.0967 11.25 13.5 11.25C12.9033 11.25 12.331 11.4871 11.909 11.909C11.4871 12.331 11.25 12.9033 11.25 13.5Z" stroke="white" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ListDownIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 4.5H9.75" stroke="#DDDDDD" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 9H8.25" stroke="#DDDDDD" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 13.5H8.25" stroke="#DDDDDD" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.25 11.25L13.5 13.5L15.75 11.25" stroke="#DDDDDD" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5 4.5V13.5" stroke="#DDDDDD" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
  
const English = (props: IconScheme) => (
  <img src={EnglishSVG} alt="English" />
);

const Croatian = (props: IconScheme) => (
  <img src={CroatianSVG} alt="Croatian" />
);

const LanguageIcon = (props: { language: string }) => {
  if(props.language === 'en')
    return <English />
  else if(props.language === 'hr')
    return <Croatian />
  else
    return <English />
}

export {
  LanguageSelectArrow,
  NotificationsIcon,
  ChangeTablesIcon,
  WaitingListIcon,
  SwitchTableIcon,
  DoubleTableIcon,
  MoveToStackIcon,
  MoveToTableIcon,
  NameLabelsIcon,
  GroupSizeIcon,
  CloseFormIcon,
  SettingsIcon,
  DownloadIcon,
  LanguageIcon,
  DocumentIcon,
  OptionOnIcon,
  StepBackIcon,
  ListDownIcon,
  ButtonArrow,
  VoucherIcon,
  ScannerIcon,
  CoctailIcon,
  SelectArrow,
  LogoutIcon,
  List1DIcon,
  List2DIcon,
  GuestsIcon,
  StaffIcon,
  BackArrow,
  ClockIcon,
  PhoneIcon,
  SaveIcon,
  LockIcon,
  EditIcon,
  AddIcon,
  BigLogo,
  PenIcon,
  Logo
}