import { useEffect, useState } from 'react';
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Event from '../../components/event';
import NewEvent from '../../components/event/new-event';
import { ContentTitle, SectionTitle } from '../../components/titles';
import EventPopup from '../../components/popup/event-popup';
import EventsScheme, { OldEventsScheme } from '../../features/events/eventsEnum';
import { getOldEvents } from '../../helpers/api/oldEvents';
import Loading from '../../components/loading/loading';
import OldEvent from '../../components/event/old-events';
import OldEventPopup from '../../components/popup/oldevent-popup';
import { EditIcon } from '../../components/icons';
import { useTranslation } from 'react-i18next';
import { place_roles } from '../../app/config';

// function to sort events
function sortEvents(events: Record<number, EventsScheme>, place_id:number): EventsScheme[] {
  let sortedEvents = [];

  for(var event_id in events)
    if(events[event_id].place_id === place_id)
      sortedEvents.push(events[event_id]);

  sortedEvents.sort((a, b) => ((new Date(a.event_start)).getTime() - (new Date(b.event_start)).getTime()));
  return sortedEvents;
}

const ClubDetails = () => {

  // state of events
  const [eventsDisplay, setEvents] = useState<EventsScheme[]>([]);
  const [oldEvents, setOldEvents] = useState<OldEventsScheme[]>([]);
  const [loading, setLoading] = useState(true);


  // get edited event!
  const location = useLocation();
  const navigate = useNavigate();
  // currently edited 
  const loc_state = location.state as { event_edited: number };

  // translation
  const {t} = useTranslation(['general', 'buttons']);
  // state for popup
  let [eventSelected, setEventSelected] = useState((loc_state === null) ? -1 : loc_state.event_edited );
  let [oldEventSelected, setOldEventSelected] = useState<OldEventsScheme | null>(null);

  // redux get
  const { events } = useAppSelector( state => state.events );
  const { places } = useAppSelector( state => state.places );

  // get slug from link so we can fetch events for selected club
  let { slug } = useParams();

  useEffect(() => {
    // sort and set events if is user place
    if(places[slug!]) {
      const load_events = async () => {
        setEvents(sortEvents(events, places[slug!].place_id));
      //console.log(events[event.id] + event.updated_at.getTime())
        if(places[slug!].role >= place_roles.manager) 
          setOldEvents(await getOldEvents(places[slug!].place_id));
      }
      load_events().then(() => setLoading(false)).catch(() => console.log('404!'));

    }
  }, [slug]);

  if(loading)
    return <Loading />

  // open and close popup functions
  const openPopup = (event_id: number) => { closeOldPopup(); setEventSelected(event_id); };
  const closePopup = () => setEventSelected(-1);
  const editPopup = (places[slug!].role >= place_roles.manager) ? `/club/${slug}/events` : '';
  
  // open and close old event popup functions
  const openOldEventPopup = (event: OldEventsScheme) => { closePopup(); setOldEventSelected(event); }
  const closeOldPopup = () => setOldEventSelected(null);

  return (
    <>
      <ContentTitle title={places[slug!].name} back={t("all_clubs")} />
      {
        places[slug!].role > place_roles.manager && 
          <div className='club__edit' onClick={() => navigate('edit')}><EditIcon />{t("edit_club", {ns: 'buttons'})}</div>
      }
      <section className="content-section">
        <SectionTitle title={t("upcoming_events")} />
        <div className="grid">
          { eventsDisplay.map((event, index) => <Event slug={slug!} key={event.id} id={event.id} onClick={() => openPopup(event.id)} />) }
          { places[slug!].role >= place_roles.manager && <NewEvent /> }
        </div>
      </section>
      {
        oldEvents.length !== 0 && <>
          <section className="content-section">
            <SectionTitle title={t("old_events")} />
            <div className='grid'>
              { oldEvents.map((event, index) => <OldEvent slug={slug!} key={event.id} {...event} onClick={() => openOldEventPopup(event)}/>)}
            </div>
          </section>
          <OldEventPopup slug={slug!} event={oldEventSelected} total_tables={places[slug!].tables_num} isOpened={(oldEventSelected === null) ? false : true} close={closeOldPopup} />
        </>
      }
      <EventPopup slug={slug!} event={eventSelected} total_tables={places[slug!].tables_num} isOpened={(eventSelected === -1) ? false : true} close={closePopup} edit={editPopup} />
      {/* RENDER CANVAS AS A CHILD ELEMENT */}
      <Outlet />
    </>
  )
}

export default ClubDetails;