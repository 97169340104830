import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import config from "../app/config";

i18next
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .use(LanguageDetector) // get preffered language
  .init({
    fallbackLng: "en",
    lng: (localStorage.getItem('i18nextLng')) ? localStorage.getItem('i18nextLng')! :  "hr",
    ns: ['general', 'menu', 'roles', 'inputs', 'buttons', 'success', 'errors', 'status'],
    defaultNS: 'general',
    supportedLngs: Object.keys(config.supportedLngs),
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // load from main page
      loadPath: config.languages + '{{lng}}/{{ns}}.json'
    },
    react: {
      useSuspense: false
    },
    debug: false
  });
export default i18next;