import { confirmGuestPresence, createReservation, deleteReservation, markTable, unMarkTable, updateReservation } from "../../../helpers/api/reservations";
import { newMessage } from "../../../features/messages/messagesSlice";
import { ReservationScheme, TableScheme } from "../enums";
import EventsScheme from "../../../features/events/eventsEnum";
import { Dispatch } from "@reduxjs/toolkit";
import { closePrompt, showPrompt } from "../../../features/prompt/promptSlice";
import { isValidPhoneNumber } from "react-phone-number-input";

interface ChangeReservationProps {
  customer_name: string,
  event: EventsScheme,
  selectedReservation: ReservationScheme
}
// function for changing reservation data
export const changeReservation = async (props: ChangeReservationProps, dispatch: Dispatch, t?: any) => {
  try {
    // no name input
    if(props.customer_name === '')
      return dispatch(newMessage({ content: t("input_all_data", {ns: 'errors'}), type: "error" }));
    // api call to update reservation
    await updateReservation({
      reservation_id: props.selectedReservation.id,
      customer_name: props.customer_name,
      event_id: props.event.id,
      place_id: props.event.place_id,
    });

    dispatch(newMessage({ content: t("change_reservation_success", {ns: 'success'}), type: "success" }));
    // props.toggleForm(); should do in component
  } catch {
    dispatch(newMessage({ content: t("something_wrong", {ns: 'errors'}), type: "error" }));
  }
}

interface RevokeReservationProps {
  event: EventsScheme,
  selectedReservation: ReservationScheme
}
// function for revoking reservation
export const revokeReservation = async (props: RevokeReservationProps, dispatch: Dispatch, t?: any) => {
  // show prompt
  dispatch(showPrompt({title: t("revoke_reservation_title"), message: t("revoke_reservation_confirm"), accept: async () => { 
    try {
      // api call to delete reservation      
      await deleteReservation({
        reservation_id: props.selectedReservation.id,
        event_id: props.event.id,
        place_id: props.event.place_id,
      });

      dispatch(newMessage({ content: t("revoke_reservation_success", {ns: 'success'}), type: "success" }));
      // props.toggleForm(); should do in component
      dispatch(closePrompt());
    } catch {
      dispatch(newMessage({ content: t("something_wrong", {ns: 'errors'}), type: "error" }));
    }
  }, 
  decline: () => dispatch(closePrompt()), 
  accept_text: t("revoke_r", {ns: 'buttons'}), decline_text: t("back", {ns: 'buttons'}), type: "delete"}))
}

interface ConfirmReservationProps {
  event: EventsScheme,
  selectedReservation: ReservationScheme
}
// function for confirm reservation
export const confirmReservation = async (props: ConfirmReservationProps, dispatch: Dispatch, t?: any) => {
  try {
    // api call to confirm guest presence    
    await confirmGuestPresence({ event_id: props.event.id, place_id: props.event.place_id, reservation_id: props.selectedReservation.id });

    dispatch(newMessage({ content: t("confirm_reservation_success", {ns: 'success'}), type: "success" }));
    // props.toggleForm(); should do in component
  } catch {
    dispatch(newMessage({ content: t("something_wrong", {ns: 'errors'}), type: "error" }));
  }
}

interface NewReservationProps {
  customer_name: string,
  phone_number: string,
  event: EventsScheme,
  selectedTable: TableScheme
}
// function for new reservation
export const newReseration = async (props: NewReservationProps, dispatch: Dispatch, t?: any) => {
  try {
    // protection    
    if(props.customer_name === '')
      return dispatch(newMessage({ content: t("input_at_least_name_dashboard", {ns: 'errors'}), type: "error" }));
    // phone number protection
    if(props.phone_number !== '' && !isValidPhoneNumber(props.phone_number))
      return dispatch(newMessage({ content: t("wrong_number", {ns: 'errors'}), type: "error" }));
    // api call to create reservation
    await createReservation({
      table_id: props.selectedTable.id,
      event_id: props.event.id,
      place_id: props.event.place_id,
      customer_name: props.customer_name,
      phone_number: props.phone_number
    });

    dispatch(newMessage({ content: t("new_reservation_success", {ns: 'success'}), type: "success" }));
    // props.toggleForm(); should do in component
  } catch(e: any) {
    dispatch(newMessage({ content: t(e.response.data.error, {ns: 'errors'}), type: "error" }));
  }
}

interface MarkToggleReservationProps {
  event: EventsScheme,
  selectedTable: TableScheme
}
// function for marking table
export const markReservation = async (props: MarkToggleReservationProps, dispatch: Dispatch, t?: any) => {
  try {
    // api call to mark a table
    await markTable({
      table_id: props.selectedTable.id,
      event_id: props.event.id,
      place_id: props.event.place_id
    });

    dispatch(newMessage({ content: t("mark_reservation_success", {ns: 'success'}), type: "success" }));
    // props.toggleForm(); should do in component
  } catch {
    dispatch(newMessage({ content: t("table_reserved", {ns: 'errors'}), type: "error" }));
  }
}
// function for unmarking table
export const unMarkReservation = async (props: MarkToggleReservationProps, dispatch: Dispatch, t?: any) => {
  try {
    // api call to unmark a table
    await unMarkTable({
      table_id: props.selectedTable.id,
      event_id: props.event.id,
      place_id: props.event.place_id
    });

    dispatch(newMessage({ content: t("unmark_reservation_success", {ns: 'success'}), type: "success" }));
    // props.toggleForm(); should do in component
  } catch {
    dispatch(newMessage({ content: t("table_not_reserved", {ns: 'errors'}), type: "error" }));
  }
}
