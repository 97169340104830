import { useEffect, useState } from "react";
import PopupWrapper, { PopupActions, PopupProps } from ".";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { filterPlaces, StaffState } from "./staff/rolesHelpers";
import StaffPopups from "./staff/"
import { editStaff } from "./staff/staffSubmit";
import Loading from "../loading/loading";
import { newMessage } from "../../features/messages/messagesSlice";
import { useTranslation } from "react-i18next";

const StaffPopup = (props: PopupProps & {selected: {email: string, role:number}}) => {

  // dispatch for editing staff
  const dispatch = useAppDispatch();
  
  // staff pull
  const { staff } = useAppSelector(state => state.staff);
  // places pull
  const { places } = useAppSelector(state => state.places);

  // for easier manipulate
  const { email } = props.selected;

  // selected role
  const [role, setRole] = useState(props.selected.role);
  const [loading, setLoading] = useState(false);
  const [initialState, setInitial] = useState<StaffState[]>([]);
  const [currentState, setCurrent] = useState<StaffState[]>([]);
  // translation
  const {t} = useTranslation(['buttons']);
  
  useEffect(() => {
    // get new_state
    let new_state = filterPlaces(places, role, staff[email]);

    // when selected role change change initial state
    setInitial(new_state);

    // assign also and current state
    setCurrent(structuredClone(new_state));

  }, [role, email]);

  useEffect(() => {
    setRole(props.selected.role);
  }, [props.isOpened, email, props.selected.role]);

  if(loading)
    return <Loading />

  // function to assign role
  const assignRole = (index:number, role:boolean) =>  {
    let new_obj = [...currentState];
    new_obj[index].has_role = role;
    setCurrent(new_obj);
  }

  const Submit = async () => {
    setLoading(true);
    await (async () => {
      // dispatch edit staff function
      let response = await editStaff(initialState, currentState, role, email, dispatch);
      if(response.sucess) {
        props.close();
        dispatch(newMessage({content: t(response.sucess, {ns: 'success'}), type: "success" }))
      }
      else 
        dispatch(newMessage({content: t(response.error!, {ns: 'errors'}), type: "error" }))
    })();
    setLoading(false);
  }
  return (
    <PopupWrapper opened={props.isOpened}>
      <div>
        <PopupActions close={props.close} />
        {email && <>
        <StaffPopups.StaffDetails email={email} name={staff[email].name} />
        <StaffPopups.DisplayRole role={role} />
        <StaffPopups.PlacesDisplay staff={currentState} assignRole={assignRole}/>
        </>}
      </div>
      <div>
        <div className="button--invert popup__button" onClick={ () => { setRole(0); props.close() } }>{t("back", {ns: 'buttons'})}</div>
        {role !== 0 && <div className="button--back" onClick={Submit}>{t("save", {ns: 'buttons'})}<img src="../assets/icons/save.svg" /></div>}
      </div>
    </PopupWrapper>
  )
}

export default StaffPopup;