import PopupWrapper, { PopupActions, PopupProps } from ".";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { countdownHours, displayCountdown, formatDate } from "../../helpers";
import config, { canvas_config, place_roles } from "../../app/config";
import { useEffect, useState } from "react";
import { getReservationCount } from "../../helpers/api/reservations";
import Loading from "../loading/loading";
import { ClockIcon } from "../icons";
import { getEventPhase, GOING_PHASE, END_PHASE, DECLINED_PHASE, DISPLAY_PHASE, RESERVATION_PHASE, TAKEOVER_PHASE } from "../../helpers/eventPhases";
import { useTranslation } from "react-i18next";
import { Image } from "../image";
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { sendReceipts, setEventBookedStatus,  } from "../../features/events/eventsSlice";
import { newMessage } from "../../features/messages/messagesSlice";

const EventPopup = (props: PopupProps & { event:number, edit:string, slug: string, total_tables: number }) => {

  const dispatch = useAppDispatch();
  const navigate =  useNavigate();
  const { events } = useAppSelector(state => state.events);
  const { currencies } = useAppSelector(state => state.currencies);
  const { places } = useAppSelector(state => state.places );

  const [reservations, setReservations] = useState({
    total_reservations: 0,
    user_reservations: 0,
    arrival_confirmed: 0,
    table_reservations: 0,
    limited_tables: {
      total_limited: "0",
      total_table_limited: "0",
      total_limited_with_name: "0"
    }
  });
  const [loading, setLoading] = useState(false);
  const [eventPhase, setEventPhase] = useState(0);
  const [receiptsSending, setReceiptsSending] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'status', 'buttons', 'errors', 'success']);
  // get url params
  let url_params = useParams();

  useEffect(() => {
    if(props.event !== -1) {
      setLoading(true);
      // event does not exist
      if(!props.event || !events[props.event])
        // event deleted so close popup
        props.close();
      // set event phase
      setEventPhase(getEventPhase(events[props.event]));
      // get reservations count
      (async () => {
        // set reservations count
        setReservations(await getReservationCount(props.event));
        setLoading(false);
      })();
    }
  }, [props.event, url_params]);

  useEffect(() => {
    if(places[props.slug!] && events[props.event]) {
      // if place is full of reservations set event book status to true
     if(reservations.table_reservations >=  places[props.slug!].tables_num)
      dispatch(setEventBookedStatus({id: props.event, status:true}))
      // if place is booked, and there is free table unbook it
     else {
      if(events[props.event].event_booked)
        dispatch(setEventBookedStatus({id: props.event, status:false}));
     }
      
    }
      
  }, [reservations.table_reservations])

  let demandConfirmations = async () => {
    try {
      // update loading state
      setReceiptsSending(true);
      // send receipts to guests
      let req: any = await dispatch(sendReceipts({ event_id: props.event, body: {query : {place_id: places[props.slug!].place_id } } }));
      // check if dispatch rejected
      if(req.type === 'events/sendReceipts/rejected')
        throw req.payload.error
      // dispatch success message
      dispatch(newMessage({ content: t(req.payload.success, { ns: 'success' }), type: 'success' }))
    } catch(error: any) {
      // update loading state
      setReceiptsSending(false);
      // dispatch error message
      dispatch(newMessage({ content: t(error, { ns: 'errors' }), type: 'error' }))
    }
  }

  return (
    <PopupWrapper opened={props.isOpened}>
      <div>
        <PopupActions close={props.close} edit={(props.edit !== '' && (props.event !== -1 && new Date(events[props.event].event_start) > new Date() && !events[props.event].event_cancelled)) ? () => navigate(props.edit + '/edit/'+props.event) : undefined } />
        {/* Protection that event is loaded */}
        {props.event !== -1 && <> 
          {
            places[props.slug!] && places[props.slug!].role >= place_roles.manager && <>
            {
              countdownHours(events[props.event].event_start) <= canvas_config.demandConfirmationsStart &&
              <div className={"data-box event-details__confirmations" + ((events[props.event].receipt_sent || countdownHours(events[props.event].event_start) <= canvas_config.demandConfirmationsEnd) ? " confirmations-sent" : (receiptsSending ? " confirmations-sending" : ""))}>
                {/* Event countdown */}
                {
                  countdownHours(events[props.event].event_start) >= canvas_config.demandConfirmationsEnd && !events[props.event].receipt_sent  && 
                  <div className="event-details__countdown">
                    <h5 className="event-details__countdown-text">{t("event_countdown", { ns: 'general' })}</h5>
                    <h2 className="event-details__countdown-clock">{displayCountdown(events[props.event].event_start, t("day", { ns: 'general' }))}</h2>
                    <div className="button" onClick={demandConfirmations}>{t("demand_confirmations", { ns: 'general' })}</div>
                    <div className="event-details__demand-notice">{t("demand_notice", { ns: 'general' })}</div>
                  </div>
                }
                {/* Loading when clicked on Confirm Arrivals */}
                <div className="event-details__countdown-loader">
                  <Loading />
                </div>
                {/* User Confirmal Status */}
                {
                  events[props.event].receipt_sent &&
                    <div className='event-details__confirmations-data'>
                      <div className="event-details__confirmations-status">
                        <SuccessIcon />
                        <div className='event-details__confirmations-text'>{t("arrival_confirmations", { ns: 'general' })}</div>
                      </div>
                      <h5 className='event-details__confirmations-number'>{reservations.arrival_confirmed}/{reservations.user_reservations}</h5>
                    </div>
                }
                {
                  countdownHours(events[props.event].event_start) <= canvas_config.demandConfirmationsEnd && !events[props.event].receipt_sent &&
                    <div className='event-details__confirmations-data'>
                      <div className="event-details__confirmations-status">
                        <SuccessIcon />
                        <div className='event-details__confirmations-text'>{t("did_not_demand_confirmations", { ns: 'general' })}</div>
                      </div>
                    </div>
                }
              </div>
              }
            </>
          }
          {/* <!-- Event details banner --> */}
          <div className="event-details__banner">
            <Image src={config.assets + props.slug + events[props.event].banner} alt="" />
            {
              (eventPhase >= RESERVATION_PHASE && eventPhase <= GOING_PHASE) && events[props.event].request_reservations_count && events[props.event].request_reservations_count! > 0 && <div className="event__requests">{t("reservation_requests_count", {ns: 'general'})} { events[props.event].request_reservations_count }</div>
            }
          </div>
          {/* <!-- / Event details banner --> */}
          {/* <!-- Event details main info --> */}
          <div className="event-details__box">
            {
              ( (eventPhase === RESERVATION_PHASE && events[props.event].event_booked) && <div className="event-details__cancelled">{t("all_reserved", {ns: 'status'})}</div>) ||
              ( (eventPhase === GOING_PHASE || eventPhase === TAKEOVER_PHASE) && <div className="event-details__going">{t("going_phase", {ns: 'status'})}</div> ) ||
              ( (eventPhase === END_PHASE) && <div className="event-details__cancelled">{t("end_phase", {ns: 'status'})}</div> ) ||
              ( (eventPhase === DECLINED_PHASE) && <div className="event-details__cancelled">{t("declined_phase", {ns: 'status'})}</div> )
            }
            <div className="title">{ events[props.event].name }</div>
            <div className="event-details__when">
              <div className="event-details__icon-box">
                <img src="../assets/icons/calendar.svg" alt="" />
                <div className="label">{formatDate(new Date(events[props.event].event_start), 1, t)}</div>
              </div>
              <div className="event-details__icon-box">
                <ClockIcon />
                <div className="label">{formatDate(new Date(events[props.event].event_start), 3)+' - '+formatDate(new Date(events[props.event].event_end), 3)}</div>
              </div>
            </div> 
          </div>
          {/* <!-- / Event details main info --> */}
          {/* <!-- Event details info --> */}
          <div className="grid row-30">
            <div className="event-details__single">
              <div className="event-details__single-name">{t("takeover_label")}</div>
              <h5>{t("until_text", {date: formatDate(new Date(events[props.event].reservation_takeover), 3)})}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("min_age_label")}</div>
              <h5>{t("min_age_text", {age: events[props.event].min_age})}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("available_tables_label")}</div>
              <h5>{(!loading) ? `${
                props.total_tables - reservations.table_reservations
              }/${props.total_tables}` : <Loading small />}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("total_reservations_label")}</div>
              <h5>{(!loading) ? (
                // LOGIC
                // all reservations - limited tables without name
                reservations.total_reservations - 
                // calculate limited tables without name
                (
                  // all limited reservations - limited reservations with names
                  parseInt(reservations.limited_tables.total_limited) -
                  parseInt(reservations.limited_tables.total_limited_with_name)
                )  
              ) : <Loading small/>}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("user_reservations_label")}</div>
              <h5>{(!loading) ? reservations.user_reservations : <Loading small/>}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("total_stack_reservations_label")}</div>
              <h5>{(!loading) ? reservations.total_reservations - reservations.table_reservations : <Loading small/>}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("entrance_label")}</div>
              <h5>{(parseInt(events[props.event].ticket_price) === 0) ? t("free_text") : events[props.event].ticket_price + ' ' +currencies[events[props.event].ticket_currency].symbol}</h5>
            </div>
            <div className="event-details__single">
              <div className="event-details__single-name">{t("reservation_mode")}</div>
              <h5>{t(`${events[props.event].event_mode}_mode`, { ns: 'general' })}</h5>
            </div>
            {
              props.edit !== '' && events[props.event].event_mode === "choose_table" &&
              <div className="event-details__single">
                <div className="event-details__single-name">{t("fake_reservation_mode", {ns: 'inputs'})}</div>
                <h5>{events[props.event].fake_mode ? t("turned_on") : t("turned_off") }</h5>
              </div>
            }
            {
              props.edit !== '' && events[props.event].fake_mode &&
                <div className="event-details__single">
                  <div className="event-details__single-name">{t("wanted_available_tables_label", {ns: 'inputs'})}</div>
                  <h5>{(events[props.event].fake_mode) ? events[props.event].fake_instances : t("mode_is_turned_off")}</h5>
                </div>
            }
            <div className="event-details__single big">
              <div className="event-details__single-name">{t("min_bottles_title")}</div>
              <h5>{t("min_bottles", { normal: events[props.event].minimum_bottles_classic, vip: events[props.event].minimum_bottles_vip })}</h5>
            </div>
            {
              events[props.event].dress_code?.length > 0 &&
                <div className="event-details__single big">
                  <div className="event-details__single-name">{t("dress_code_title")}</div>
                  <h5>{events[props.event].dress_code}</h5>
                </div>
            }
            {
              events[props.event].event_notes?.length > 0 &&
                <div className="event-details__single big">
                  <div className="event-details__single-name">{t("event_notes_title")}</div>
                  <h5>{events[props.event].event_notes}</h5>
                </div>
            }
          </div>
          {/* <!-- Event details info --> */}
        </>
        }
      </div>
      {
        props.event !== -1 && (
          ( (eventPhase === DISPLAY_PHASE) &&
            <div className="reservation-available">
              <h5>{t("can_reserve_text", {date: formatDate(new Date(events[props.event].reservation_start), 2), time: formatDate(new Date(events[props.event].reservation_start), 3)})}</h5>
            </div>
          ) ||
          ( (eventPhase >= RESERVATION_PHASE && eventPhase <= GOING_PHASE) &&
            <div className="button popup__button" onClick={() => navigate(`${window.location.pathname}/reservations/${props.event}`)}>{t("check_reservations", {ns: 'buttons'})}<img src="../assets/icons/button-arrow.svg" /></div>
          ) )
      }
    </PopupWrapper>
  )
}

export default EventPopup;