import * as THREE from 'three';
import CameraScheme, { ReservationScheme, TableReservationScheme, TableScheme } from './enums';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatDate } from '../../helpers';
import EventsScheme from '../../features/events/eventsEnum';
import i18n from '../../services/i18n';
import { addLabels } from '../../helpers/canvas';
import { SectionItem } from './guest-list';

const floors = ["ground_floor", "floor_1", "floor_2", "floor_3"];

// function to download 2D guest list
var download2d = (floorImages: string[], event: EventsScheme | undefined) => {
  // protection
  if(!event) return;
  // create new PDF
  let doc = new jsPDF();
  let width = doc.internal.pageSize.getWidth();
  let height = doc.internal.pageSize.getHeight();
  // set first page
  let page = 1;
  // loop through floor images
  for(let image of floorImages) {
    // if we're not on the first page
    if(page != 1) {
      // add new page
      doc.addPage();
      // set input to new page
      doc.setPage(page);
    }
    // add image to current page
    doc.addImage(image, 'JPEG', 0, 0, width, height);
    // Event name
    doc.setTextColor(54, 52, 49);
    doc.setFontSize(15);
    doc.text(event.name, (doc.internal.pageSize.width / 2), 15, { align: 'center' });
    // Event date and day
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    doc.text(formatDate(new Date(event.event_start), 1, i18n.t), (doc.internal.pageSize.width / 2), 21, { align: 'center' })
    doc.text(i18n.t(floors[page-1]), (doc.internal.pageSize.width / 2), (doc.internal.pageSize.height-10), { align: 'center' })
    // increase page number
    page++;
  }

  // download pdf
  let blob = new Blob([doc.output('blob')], { type: "application/octet-stream" });
  forceDownloadBlob(blob, `${event.name}_${formatDate(new Date(event.event_start), 1, i18n.t)} - 2D Guest List.pdf`);
}

// function to create 2D floor image with reservation labels
export var getFloorImage = (scene: THREE.Scene, tables: Record<string, TableScheme>, reservations: TableReservationScheme[], cameraSetup: CameraScheme | undefined) => {
  // create new renderer without canvas
  let renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
  // protection
  if(!cameraSetup) return renderer.domElement.toDataURL();
  // set scene background to white (for printing)
  scene.background = new THREE.Color( 0xFFFFFF );

  // setup camera
  let factor = cameraSetup.print_factor;
  let camera = new THREE.OrthographicCamera( 630 / -factor, 630 / factor, 891 / factor, 891 / -factor, 1, 1000 );
  camera.position.set(cameraSetup.print_offset ? cameraSetup.print_offset[0] : 0, cameraSetup.camera_distance ? cameraSetup.camera_distance : 180, cameraSetup.print_offset ? cameraSetup.print_offset[2] : 0);
  camera.lookAt(cameraSetup.print_offset ? cameraSetup.print_offset[0] : 0, 0, cameraSetup.print_offset ? cameraSetup.print_offset[2] : 0);

  // this ratio is A4 size aspect ratio (210, 297) * 3 for better quality
  renderer.setSize(630, 891);
  renderer.setPixelRatio(3);
  // add labels to the scene
  scene = addLabels(scene, tables, reservations, true);
  // render new scene
  renderer.render(scene, camera);
  // create and return image from renderer
  return renderer.domElement.toDataURL();
}

// function to create and download PDF with simple guest list
export var download1d = (list: Record<string, SectionItem[]>, event: EventsScheme | undefined) => {
  // protection
  if(!event) return;
  // create new PDF
  const doc = new jsPDF();
  // Event name
  doc.setTextColor(54, 52, 49);
  doc.setFontSize(15);
  doc.text(event.name, (doc.internal.pageSize.width / 2), 15, { align: 'center' });
  // Event date and day
  doc.setTextColor(100, 100, 100);
  doc.setFontSize(10);
  doc.text(formatDate(new Date(event.event_start), 1, i18n.t), (doc.internal.pageSize.width / 2), 21, { align: 'center' })
  // Guest list
  autoTable(doc, {
    head: [[i18n.t("table") || "Table", i18n.t("name_label", {ns: 'inputs'}) || "Name & Surname"]],
    // body: Object.values(list).map(item => { return [item.label, (item.name || "").replace(/[čć]/g, "c") + (item.isLimited ? " (LIMITED)" : "")] }),
    body: Object.entries(list).sort((a, b) => a[0].localeCompare(b[0])).map(([section_name, reservations]) => { 
      return [[{ content: section_name.toUpperCase(), colSpan: 2, rowSpan: 1, styles: { halign: 'center', fillColor: [230, 230, 230], lineWidth: 0 } as any }], ...reservations.map(item => { 
        return [item.table_label, (item.customer_name || "").replace(/[čć]/g, "c") + (item.isLimited ? " (LIMITED)" : "")]
      })]
    }).flat(1),
    columnStyles: { 0: { cellWidth: 20, lineWidth: { right: .3 } } },
    headStyles: { fillColor: [54, 52, 49] },
    startY: 28
  })
  // add copyright footer
  let finalY = (doc as any).lastAutoTable.finalY;
  doc.setTextColor(54, 52, 49);
  doc.setFontSize(8);
  doc.text("Copyright © taboo.hr", (doc.internal.pageSize.width / 2), finalY + 10, { align: 'center' });

  // download pdf
  let blob = new Blob([doc.output('blob')], { type: "application/octet-stream" });
  forceDownloadBlob(blob, `${event.name}_${formatDate(new Date(event.event_start), 1, i18n.t)} - Guest List.pdf`);
}

// force download blob
var forceDownloadBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export default download2d;