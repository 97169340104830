import { PlaceLabel } from "./placeLabel";
import {PhoneState} from './helpers'
import { useTranslation } from "react-i18next";

interface iProps {
  places: PhoneState[],
  assignPlace: (index:number, block:boolean) => void
}


export const PlacesDisplay = (props:iProps) => {
  const {t} = useTranslation();
  return (
  <div className="staff-details__role input-wrapper">
      <div className="label input__label">{t("blocked_display_label")}</div>
      <div className="checkbox-container">
        {props.places.map((place, index) => <PlaceLabel key={index} {...place} index={index} assignPlace={props.assignPlace}/>)} 
      </div>
    </div>);
}