import { SectionTitle } from '../../components/titles';
import Personnel from '../../components/personnel';
import StaffScheme from '../../features/staff/staffEnum';

interface StaffSectionProps {
  title: string,
  personnel: StaffScheme[],
  current_role: number,
  onPersonnelClick: (email:string, role: number) => void
}

const StaffSection = (props: StaffSectionProps) => {

  if(!props.personnel.length)
    return <></>

  return (
    <section className="content-section">
      <SectionTitle title={props.title} />
      <div className="grid">
        { props.personnel.map((person, index) => <Personnel key={person.email} {...person} onClick={() => { props.onPersonnelClick(person.email, props.current_role) }} />) }
      </div>
    </section>
  )
}

export default StaffSection;