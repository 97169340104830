import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface PromptScheme {
  title: string,
  message: string,
  type: string,
  accept_text: string,
  decline_text: string,
  accept: ((params?: any) => any) | null,
  decline: ((params?: any) => any) | null
}

const initialState = {
  prompt: {
    title: '',
    message: '',
    accept: null,
    decline: null,
    type: '',
    accept_text: '',
    decline_text: ''
  } as PromptScheme
};

// create user slice
const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    showPrompt: (state, action: PayloadAction<PromptScheme>) => {
      // 
      state.prompt = action.payload;
    },
    closePrompt: (state, action: unknown) => {
      // erase object
      state.prompt = {
        title: '',
        message: '',
        accept: null,
        decline: null,
        type: '',
        accept_text: '',
        decline_text: ''
      }
    }
  }
})

export const { showPrompt, closePrompt } = promptSlice.actions;

export default promptSlice.reducer;