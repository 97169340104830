import API from '../api';

// function to get old events
export const getOldEvents = async (place_id: number) => {
  try {
    // fetch old events
    let res = await API.post(`/events/old`, { query: { place_id }});
    // sort response data
    res.data.events.sort((a:any, b:any) => ((new Date(b.event_start)).getTime() - (new Date(a.event_start)).getTime()));
    // pick array from response
    return res.data.events;
  }
  catch {
    return [];
  }
}