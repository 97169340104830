import { _format } from "../../../helpers";
import { AppDispatch } from "../../../app/store";
import { isValidPhone } from "../../../helpers/regex";
import { formatPlaces, PhoneState } from "./helpers";
import { editBlockedNumber } from "../../../features/blocked-numbers/blockedSlice";

export const phoneSubmit = async (initialState:PhoneState[], currentState:PhoneState[], phone: string, dispatch:AppDispatch) => {

  try {
    if(!isValidPhone(phone)) return { error:'wrong_number' }

    // get places before formated by place id
    const places_before = _format(initialState, 'place_id');

    // currentState.is_blocked == true && initialState.is_blocked == false
    const create_places = currentState.filter(place => !!place.is_blocked && !places_before[place.place_id].is_blocked);

    // currentState.is_blocked == false && initialState == true
    const delete_places = currentState.filter(place => !place.is_blocked && !!places_before[place.place_id].is_blocked);

    // if create_places and delete places are empty array
    if(!create_places.length && !delete_places.length) return {error: 'no_changes'};

    // format places by place id and slug
    let formated = formatPlaces(create_places, delete_places);

    // format query
    var query = {
      phone,
      places: formated.places
    }
    
    await dispatch(editBlockedNumber({query, slugs: formated.slugs}));
    return {sucess: 'blocked_edit'};
  }
  catch(e) {
    return {error: 'not_authorized'};
  }
}