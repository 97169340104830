import API from '../api';

interface ReservationRequestScheme {
  reservation_id: number,
  customer_name: string,
  place_id: string | number,
  event_id: string | number,
  table_id: string | number,
  phone_number: string,
  new_table_id?: string | number | null,
  switch_reservation_id?: number
}

// reservation types 
export const types = {
  user: 0,
  limited: 1,
  marked: 2
}

// function for update existing reservation
export const updateReservation = async (data: Pick<ReservationRequestScheme, 'customer_name' | 'reservation_id' | 'event_id' | 'place_id'>) => {
  await API.put(`/reservations/update`, { query: data });
}

// function for deleting existing reservation
export const deleteReservation = async (data: Pick<ReservationRequestScheme, 'event_id' | 'reservation_id' | 'place_id'>) => {
  await API.delete(`/reservations/delete`, { data: {
    query: data
  }});
}

// function for new reservation
export const createReservation = async (data: Pick<ReservationRequestScheme, 'customer_name' | 'event_id' | 'place_id'> & { table_id?: number, phone_number?: string }) => {
  await API.post(`/reservations/create`, { query: data });
}

// function for mark table
export const markTable = async (data: Pick<ReservationRequestScheme, 'event_id' | 'table_id' | 'place_id'>) => {
  await API.post(`/reservations/markTable`, { query: data });
}

// function for unmark table
export const unMarkTable = async (data: Pick<ReservationRequestScheme, 'event_id' | 'table_id' | 'place_id'>) => {
  await API.post(`/reservations/unMarkTable`, { query: data });
}

// function for update existing reservation
export const confirmGuestPresence = async (data: Pick<ReservationRequestScheme, 'event_id' | 'reservation_id' | 'place_id'>) => {
  await API.put(`/reservations/confirm`, { query: data });
}

// function for switching table
export const switchTable = async (data: Pick<ReservationRequestScheme, 'reservation_id' | 'switch_reservation_id' | 'event_id' | 'new_table_id' | 'place_id'>) => {
  await API.put(`/reservations/switch_table`, { query: data });
}

// function to get reservation counts
export const getReservationCount = async (key: number) => {
  try {
    let res = await API.get(`/reservations/counts/${key}`);
    return res.data;
  } catch {
    return {};
  }
}