import config from "../../app/config";
import { roles } from "../popup/staff/rolesHelpers";
import { useAppDispatch } from '../../app/hooks';
import { acceptInvitation, declineInvitation } from "../../features/invitations/invitationSlice";
import { getPlace } from "../../features/places/placesSlice";
import { getEventsBySlug } from "../../features/events/eventsSlice";
import { reduceNotifications } from "../../features/user/userSlice";
import { newMessage } from "../../features/messages/messagesSlice";
import { closePrompt, showPrompt } from "../../features/prompt/promptSlice";
import { useTranslation } from "react-i18next";
import { Image } from "../image";

export interface InviteScheme {
  hash: string,
  role: number,
  email: string,
  club_name: string,
  slug: string,
  banner:string,
}

const Invite = (props: InviteScheme & { onAction?: () => void }) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation(['general', 'buttons', 'success', 'errors', 'roles']);
  const handleClick = async (action: string) => {
    try {
      if(action === "decline")
      {
        // show prompt
        dispatch(showPrompt({title: t("decline_invite_title"), message: t("decline_invite_text"), accept: async () => { 
          try {
            // decline invitation
            await dispatch(declineInvitation(props.hash));
            dispatch(closePrompt());
            dispatch(newMessage({content: t("invite_declined", {ns: 'success', club: props.club_name} ), type: 'error'}));
            // reduce notification number
            dispatch(reduceNotifications(-1));
            // on action callback
            !!props.onAction && props.onAction();  

          }
          catch {
            // propagate to catch!
            throw new Error("Pozivnica istekla!");
          }
        }, 
        decline: () => dispatch(closePrompt()), 
        accept_text: t("decline", {ns: 'buttons'}), decline_text:t("back", {ns: 'buttons'}), type: "delete"}))
      }
      else {
        await dispatch(acceptInvitation(props.hash));
        await dispatch(getPlace(props.slug));
        // get events
        await dispatch(getEventsBySlug(props.slug));
        dispatch(newMessage({content: t("invite_accepted", {ns: 'success', club: props.club_name} ), type: 'success'}));
        // reduce notification number
        dispatch(reduceNotifications(-1));
        // on action callback
        !!props.onAction && props.onAction();
      }
    }
    catch {
      dispatch(newMessage({content: t("invite_expired", {ns: 'errors'}), type: 'error'}));
    }
  }

  return (
    <div className="invite">
      <div className="invite__info">
        <div className="invite__img">
          <Image src={config.assets + props.slug + props.banner} alt="" fadeIn />
        </div>
        <div>
          <h2 className="invite__h2">{props.club_name}</h2>
          <div className="label--gray">{t(roles[props.role].name, {ns: 'roles'})}</div>
        </div>
      </div>
      <div className="invite__buttons">
        <div className="button--invert" onClick={async () => await handleClick("decline")}>{t("decline", {ns: "buttons"})}</div>
        <div className="button" onClick={async () => await handleClick("accept")}>{t("accept", {ns: "buttons"})}</div>
      </div>
    </div>
  )
}

export default Invite;