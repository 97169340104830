import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import Club from '../../components/club';
import { ContentTitle } from '../../components/titles';

const Clubs = () => {

  const { places } = useAppSelector(state => state.places);
  const {t} = useTranslation(['general', 'menu']);
  return (
    <>
      <ContentTitle title={t("my_clubs", {ns: 'menu'})} />
      { Object.keys(places).length === 0 && <div className='empty-section'>{t("no_clubs")}</div>}
      <section className="content-section">
        <div className="grid">
          { !!places && Object.entries(places).map(([key, club]) => { return <Club key={club.place_id} {...club}/>}) }
        </div>
      </section>
    </>
  )
}

export default Clubs;