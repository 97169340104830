import { ContentTitle } from '../../components/titles';
import { GeneralSettings } from '../../components/settings/general-data';
import { ChangePassword } from '../../components/settings/change-password';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

const Settings = () => {

  const { user } = useAppSelector(state => state.user);
  const {t} = useTranslation(['menu']);
  return (
    <>
      <ContentTitle title={t("settings", {ns: 'menu'})} />
      <section className="content-section">
        <div className="grid row-60">
          <GeneralSettings name={user.name} />
          <ChangePassword />
        </div>
      </section>
    </>
  )
}

export default Settings;