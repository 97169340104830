import EventsScheme from "../features/events/eventsEnum";

// FAZA PRIJE REZERVACIJA -> SAMO LIMIT TABLES 
export const DISPLAY_PHASE = 0; 
// FAZA REZERVACIJA -> MOŽE SE REZERVIRATI, OTKAZATI, RADITI IZMJENE
export const RESERVATION_PHASE = 1;
// FAZA DOLAZAKA GOSTIJU -> MOŽE SE POTVRDITI DOLAZAK, REZERVIRATI; OTKAZATI, RADITI IZMJENE, OZNAČITI STOL
export const TAKEOVER_PHASE = 2;
// FAZA TRAJANJA DOGAĐAJA -> MOŽE SE POTVRIDITI DOLAZAK, RADITI IZMJENE, OZNAČITI STOL ( rezervira se priko limit tables-a )
export const GOING_PHASE = 3;
// DOGAĐAJ GOTOV - > NIŠTA
export const END_PHASE = 4;
// DOGAĐAJ OTKAZAN
export const DECLINED_PHASE = 5;

const takeoverOffset = 60*60000; // 60 minutes

export const getEventPhase = (event: EventsScheme, withOffset?: boolean) => {
  let { reservation_takeover, event_start, event_end, reservation_start, event_cancelled } = event;
  // event cancelled
  if(event_cancelled)
    return DECLINED_PHASE;
  let current_date = new Date();
  // if current date is smaller than reservation start it is display phase
  if(current_date < new Date(reservation_start))
    return DISPLAY_PHASE;
  // if current date is bigger and equal than reservation start and it is smaller than event start time
  else if(current_date >= new Date(reservation_start) && current_date < new Date(new Date(event_start).getTime() - (withOffset ? takeoverOffset : 0)))
    return RESERVATION_PHASE;
  // if current date is bigger or equal to event start time and smaller than reservation takeover it is takeover phase
  else if(current_date >= new Date(new Date(event_start).getTime() - (withOffset ? takeoverOffset : 0)) && current_date < new Date(reservation_takeover))
    return TAKEOVER_PHASE;
  // if current date is bigger or equal than event_start and smaller than event_end
  else if(current_date >= new Date(event_start) && current_date < new Date(event_end))
    return GOING_PHASE;
  // else it is end phase
  else 
    return END_PHASE;
}

