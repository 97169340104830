import { useTranslation } from "react-i18next";
import { Input } from "../../form";
import { ReservationScheme } from "../enums";
import EventsScheme from "../../../features/events/eventsEnum";
import { FormEvent, useEffect, useState } from "react";
import { changeReservation, revokeReservation } from "./api-calls";
import { useAppDispatch } from "../../../app/hooks";

interface EditReservationScheme {
  event: EventsScheme,
  selectedReservation: ReservationScheme,
  isEditing: boolean,
  stopEditing: () => void
}

const EditReservation = (props: EditReservationScheme) => {
  const dispatch = useAppDispatch();
  // input user state
  let [userData, setUserData] = useState({
    customer_name: props.selectedReservation.customer_name || '',
  });
  // translation
  const { t } = useTranslation(['general', 'inputs', 'buttons']);

  // update user data on table change
  useEffect(() => {
    // update user data on table change
    setUserData({
      customer_name: props.selectedReservation.customer_name || '',
    });
  }, [props.selectedReservation.id]);

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setUserData((prevState) => ({...prevState, [name]: value}));
  }

  // function to edit reservation
  const updateReservation = async () => {
    // make api call
    await changeReservation({
      ...userData,
      event: props.event,
      selectedReservation: props.selectedReservation
    }, dispatch, t)
  }

  // function to delete reservation
  const cancelReservation = async () => {
    // make api call
    await revokeReservation({
      event: props.event,
      selectedReservation: props.selectedReservation
    }, dispatch, t);
  }

  return (
    <div className={"canvas__form__edit-reservation" + (props.isEditing ? " editing" : "")}>
      <div className="canvas__form__cancel-reservation" onClick={cancelReservation}>{t(props.selectedReservation.customer_name ? "revoke_reservation" : "free_table", {ns: 'buttons'})}</div>
      <Input 
        name="customer_name"
        label={t("name_label", {ns: 'inputs'})}
        placeholder={t("name_placeholder", {ns: 'inputs'})}
        type="text"
        value={userData.customer_name}
        onChange={handleInput}
      />
      <div className="canvas__form__actions">
        <div className="button--invert" onClick={props.stopEditing}>{t("back", {ns: 'buttons'})}</div>
        <div className="button" onClick={updateReservation}>{t("save", {ns: 'buttons'})}</div>
      </div>
    </div>
  )
}

export default EditReservation;