import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userAPI } from './userAPI';

export interface LoginParams {
  email: string,
  password: string,
  returnURL: string | null,
  rememberMe: boolean
}

interface userState {
  name: string,
  active:boolean,
  email: string,
  role: number
}
const initialState = {
  isLoggedIn: false as boolean,
  error: "" as string,
  success: "" as string,
  notifications: 0 as number,
  user: {} as userState
};

// login thunk
export const login = createAsyncThunk('users/login', userAPI.login);

// check session thunk
export const checkSession = createAsyncThunk('users', userAPI.checkSession);

// change data thunk
export const changeData = createAsyncThunk('users/change', userAPI.changeData);

// get notification number 
export const getNotificationsCount = createAsyncThunk('users/not_num', userAPI.getNotificationsCount);

// create user slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    errorMsg: (state, { payload }) => {
      state.error = payload.msg;
    },
    successMsg: (state, { payload }) => {
      state.success = payload.msg;
    },
    reduceNotifications: (state, {payload}) => {
      // reduce by one
      state.notifications += payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.error = "";
      state.success = "";
    })
    builder.addCase(checkSession.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = payload.user;
    }).addCase(checkSession.rejected, (state) => {
      state.isLoggedIn = false;
    })
    builder.addCase(changeData.fulfilled, (state, { payload }) => {
      //set to state
      state.user = { ...state.user, name: payload.name};
    })
    builder.addCase(getNotificationsCount.fulfilled, (state, { payload }) => {
      //set to state
      state.notifications = parseInt(payload);
    })
  },
})

export const { errorMsg, successMsg, reduceNotifications } = userSlice.actions;

export default userSlice.reducer;