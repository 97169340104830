import EventsScheme from '../../features/events/eventsEnum';
import config from '../../app/config';
import { formatDate } from '../../helpers';
import { getEventPhase, RESERVATION_PHASE, TAKEOVER_PHASE, GOING_PHASE, DECLINED_PHASE } from '../../helpers/eventPhases';
import { useTranslation } from 'react-i18next';
import { Image } from '../image';
import { useAppSelector } from '../../app/hooks';

const Event = (props: {id:number, slug: string, onClick: () => void }) => {
  let { slug, onClick, id } = props;
  // redux get
  const { events } = useAppSelector( state => state.events );
  let event = events[id];
  let phase = getEventPhase(event);
  const {t} = useTranslation(['status', 'general']);

  return (
  <div className="event" onClick={props.onClick}>
    {
      ((phase === RESERVATION_PHASE) && (event.event_booked) ? <div className="event__cancelled">{t("all_reserved", {ns: 'status'})}</div>: <div className="event__going">{t("reservation_phase", {ns: 'status'})}</div> ) ||
      ((phase === DECLINED_PHASE) && (<div className="event__cancelled">{t("declined_phase", {ns: 'status'})}</div>)) ||
      (phase === GOING_PHASE || phase === TAKEOVER_PHASE) && <div className="event__going">{t("going_phase", {ns: 'status'})}</div>
    }
    {
      (phase === RESERVATION_PHASE) && !!event.request_reservations_count && event.request_reservations_count > 0 && <div className="event__requests">{t("reservation_requests_count", {ns: 'general'})} {event.request_reservations_count}</div>
    }
    <div className="event__banner">
      <Image src={config.assets + slug + event.banner} alt="" fadeIn />
    </div>
    <div className="event__info">
      <div className="eyebrow">{event.event_start && formatDate(new Date(event.event_start), 1, t)}</div>
      <h3 className="event__h3">{event.name}</h3>
    </div>
  </div>
  );
}

export default Event;