const config = {
  api: 'https://api.taboo-host.com/', // 'https://api.taboo.hr/',
  assets: 'https://assets.taboo-host.com/', // 'https://assets.taboo.hr',
  icons: 'https://assets.taboo.hr/icons/',
  socket: 'https://socket.taboo-host.com/',
  languages: 'https://assets.taboo-host.com/languages/',
  supportedLngs: {"en": "English", "hr": "Hrvatski"}
}
/*
const config = {
  api: 'http://134.209.254.57:8080/', // 'https://api.taboo.hr/',
  assets: 'http://134.209.254.57/assets/', // 'https://assets.taboo.hr',
  icons: 'https://assets.taboo.hr/icons/',
  socket: 'http://134.209.254.57:8880/',
  languages: '/locales/',
  supportedLngs: {"en": "English", "hr": "Hrvatski"}
}
*/
export const canvas_config = {
  nameLabels: true,
  demandConfirmationsStart: 36, // can demand confirmations X hours before event start
  demandConfirmationsEnd: 2 // stop possibility to demand confirmations, X hours before event start
}

export const place_roles = {
  hostess: 1,
  barmen: 2,
  manager: 3,
  owner: 4
}

export const maxRejectMessageLength = 100;

export default config;