import { PhoneState } from "./helpers";

export const PlaceLabel = (props:PhoneState & {index: number, assignPlace: (i: number, b:boolean) => void}) => {

  let data_checked = props.is_blocked ? { "data-checked" : "" } : {};
  return (
    <div className="staff__checkbox checkbox" {...data_checked} onClick={() => props.assignPlace(props.index, !props.is_blocked)}>
      <div className="checkbox__box"></div>
      <div>
        <h3>{props.name}</h3>
        <div className="eyebrow">{props.domain}</div>
      </div>
    </div>
  )
};