import { AppDispatch } from '../../app/store';
import { DaysPast, ReverseDate } from '../../helpers';
import { fileCompress } from '../../helpers/fileCompress';
import { newEvent, updateEvent, cancelEvent, deleteEvent } from "../../features/events/eventsSlice";
import { EventMode } from '../../features/events/eventsEnum';

interface eventForm {
  place_id: number,
  name: string,
  min_age: number,
  event_start: Date,
  event_time: string,
  event_end: string,
  reservation_start: Date,
  reservation_time: string,
  reservation_takeover: string,
  ticket_price: string,
  ticket_currency: string,
  minimum_bottles_classic: string,
  minimum_bottles_vip: string,
  fake_mode: boolean,
  fake_instances: string,
  event_mode: EventMode,
  dress_code: string,
  event_notes: string,
  banner: any
}
 
const eventSubmit = async (eventForm: eventForm, event_id: number, dispatch: AppDispatch) => {
  try {
    if(eventForm.name === '' || eventForm.event_time === '' || eventForm.event_end === '' || eventForm.reservation_time === '' || eventForm.reservation_takeover === '')
      return { error: 'input_all_data' };
    
    var query = {
      place_id: eventForm.place_id,
      name: eventForm.name,
      min_age: eventForm.min_age,
      event_start: eventForm.event_start,
      event_end: new Date(eventForm.event_start),
      reservation_start: eventForm.reservation_start,
      reservation_takeover: new Date(eventForm.event_start),
      ticket_price: eventForm.ticket_price,
      minimum_bottles_classic: eventForm.minimum_bottles_classic,
      minimum_bottles_vip: eventForm.minimum_bottles_vip,
      fake_mode: eventForm.fake_mode,
      fake_instances: eventForm.fake_instances,
      ticket_currency: eventForm.ticket_currency,
      event_mode: eventForm.event_mode,
      event_notes: eventForm.event_notes,
      dress_code: eventForm.dress_code
    };

    // event time set
    let time = eventForm.event_time.split(':');
    query.event_start.setHours(parseInt(time[0]), parseInt(time[1]), 0);

    // set + one day
    if(eventForm.event_time >= eventForm.event_end)
      query.event_end.setDate(query.event_end.getDate() + 1);

    // set + 1 day
    if(eventForm.event_time > eventForm.reservation_takeover)
      query.reservation_takeover.setDate(query.reservation_takeover.getDate() + 1);
    
    // set time to reservation takeover
    time = eventForm.reservation_takeover.split(':');
    query.reservation_takeover.setHours(parseInt(time[0]), parseInt(time[1]), 0);

    // set time to event end
    time = eventForm.event_end.split(':');
    query.event_end.setHours(parseInt(time[0]), parseInt(time[1]), 0);

    // set time to reservation start
    time = eventForm.reservation_time.split(':');
    query.reservation_start.setHours(parseInt(time[0]), parseInt(time[1]), 0);

    // if reservation start is bigger than event start -1 hour
    if(query.reservation_start > ReverseDate(query.event_start,-1))
      return {error: 'reservation_start_event_start_conflict'};

    // event start needs to be bigger than current date
    if(new Date() > query.event_start)
      return {error: 'event_start_current_date_conflict'};

    // reservation takeover needs to be smaller than event end
    if(query.reservation_takeover >= query.event_end)
      return {error: 'reservation_takeover_event_end_conflict'};

    if(query.fake_mode && parseInt(query.fake_instances) <= 0)
      return {error: 'invalid_fake_mode_intances'}

    if(parseInt(query.minimum_bottles_classic) < 0 || parseInt(query.minimum_bottles_vip) < 0 || parseInt(query.ticket_price) < 0 || parseInt(query.fake_instances) < 0)
      return {error: 'something_wrong'}

    let formData = new FormData();

    if(event_id === -1 || typeof eventForm.banner !== 'string')
    {
      // compress file
      var image = await fileCompress(eventForm.banner);
      if(image.size > 1.5 * 1000000)
        return {error: 'file_large'}
      formData.append('file', image, image.name);
    }

    formData.append('query', JSON.stringify(query));
    var res;
    if(event_id === -1)
      res = await dispatch(newEvent({ formData, query }));
    else 
      res = await dispatch(updateEvent({ formData, query, event_id }));

    return res.payload.id;
  } catch {
    return { error: 'something_wrong' };
  }
}

export const eventCancel = async (event: any, operation: number, dispatch: AppDispatch) => {
  try {
    // operation = 0 (delete), operation = 1 (cancel) 
    if(operation === 0) 
      await dispatch(deleteEvent({ event_id: event.id, body: { query: { place_id: event.place_id } } }));
    else 
      await dispatch(cancelEvent({ event_id: event.id, body: { query: { event_cancelled: !event.event_cancelled, place_id: event.place_id } } }));
  } catch(e) {
    throw e;
  }
}

export default eventSubmit;

  