import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../components/sidebar';
import Loading from '../../components/loading/loading';
import { getPlaces } from '../../features/places/placesSlice';
import { getEvents } from '../../features/events/eventsSlice';
import { useAppDispatch} from '../../app/hooks';
import MessageContainer from '../../components/messages';
import { getNotificationsCount } from '../../features/user/userSlice';
import Prompt from '../../components/prompt';
import { getCurrencies } from '../../features/currency-list/currencySlice';


const Layout = () => {
  // load all needed
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const load_All = async() => {
      
      // load places
      await dispatch(getPlaces(true));

      // load events
      await dispatch(getEvents(true));

      // load number of notifications
      await dispatch(getNotificationsCount(true));

      // load currency
      await dispatch(getCurrencies(true));
    }
    // load all
    load_All().then( ( ) => setLoaded(true) ).catch( ( err ) => { console.log(err); })
  }, [dispatch])
  
  if(!loaded) // manage loading page
    return <Loading />
  
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="content container">
        <Outlet />
      </div>
      <MessageContainer />
      <Prompt />
    </div>
  )
}

export default Layout;