import Compressor from "compressorjs";

export const fileCompress = async (file: File):Promise<any> => {
  let uri = await new Promise((resolve) => {
    new Compressor(file, {
      quality: 0.8,
      mimeType: 'image/webp',
      //convertSize: 1500000, // 1.5 MB
      maxWidth: 1024,
      maxHeight: 1024,
      strict: true,
      success: (uri) => resolve(uri),
      error: (err) => { console.log(err.message); resolve(-1); }
    })
  });
  if(uri === -1)
    throw new Error('Error with compression!');
  return uri;
}