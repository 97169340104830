import { useState, FormEvent } from 'react';
import eventSubmit, {eventCancel} from './eventSubmit';
import { Navigate, useParams, useNavigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { ContentTitle } from '../../components/titles';
import { formatDate } from '../../helpers';
import config, { place_roles } from '../../app/config';
import Loading from '../../components/loading/loading';
import { useAppDispatch } from "../../app/hooks";
import { ReactComponent as ArrowBigRight } from '../../assets/icons/arrow-big--right.svg';
import { newMessage } from '../../features/messages/messagesSlice';
import { limitTablesAction } from '../../features/events/eventsSlice';
import { CheckBox, Counter, DatePicker, ImageUpload, Input } from '../../components/form';
import { EventActions } from '../../components/event/event-form/actions';
import '../../../node_modules/flatpickr/dist/flatpickr.min.css';
import { closePrompt, showPrompt } from '../../features/prompt/promptSlice';
import { useTranslation } from 'react-i18next';
import { PriceInput } from '../../components/form/priceInput';

const AddEvent = () => {
  const dispatch = useAppDispatch();
  const navigate =  useNavigate();

  // places import
  const { places } = useAppSelector(state => state.places );
  // events import
  const { events, limitTables, delimitTables } = useAppSelector(state => state.events );

  // get slug from link so we can fetch events for selected club
  let { slug, event_id } = useParams();

  // assign current_event initial state
  var current_event = -1;

  // assign it from slug
  if(event_id)
    current_event = parseInt(event_id);

  // state for form
  const [event, setEvent] = useState({
    place_id: places[slug!].place_id,
    name: (current_event !== -1 && events[current_event]) ? events[current_event].name : '',
    min_age: (current_event !== -1 && events[current_event]) ? events[current_event].min_age : 18,
    event_start: (current_event !== -1 && events[current_event]) ? new Date(events[current_event].event_start) : new Date(),
    event_time: (current_event !== -1 && events[current_event]) ?  formatDate(new Date(events[current_event].event_start), 3) : '',
    event_end: (current_event !== -1 && events[current_event]) ?  formatDate(new Date(events[current_event].event_end), 3) : '',
    reservation_start: (current_event !== -1 && events[current_event]) ? new Date(events[current_event].reservation_start) : new Date(),
    reservation_time: (current_event !== -1 && events[current_event]) ?  formatDate(new Date(events[current_event].reservation_start), 3) : '',
    reservation_takeover: (current_event !== -1 && events[current_event]) ? formatDate(new Date(events[current_event].reservation_takeover), 3) : '',
    ticket_price: (current_event !== -1 && events[current_event]) ? events[current_event].ticket_price.toString() : '0',
    minimum_bottles_classic: (current_event !== -1 && events[current_event]) ? events[current_event].minimum_bottles_classic.toString() : '0',
    minimum_bottles_vip: (current_event !== -1 && events[current_event]) ? events[current_event].minimum_bottles_vip.toString() : '0',
    fake_mode: (current_event !== -1 && events[current_event]) ? events[current_event].fake_mode : false,
    fake_instances: (current_event !== -1 && events[current_event]) ? events[current_event].fake_instances.toString() : '0',
    ticket_currency: (current_event !== -1 && events[current_event]) ? events[current_event].ticket_currency : '',
    banner: (current_event !== -1 && events[current_event]) ? events[current_event].banner : {},
    event_mode: (current_event !== -1 && events[current_event]) ? events[current_event].event_mode : 'choose_table',
    event_notes: (current_event !== -1 && events[current_event]) ? events[current_event].event_notes : '',
    dress_code: (current_event !== -1 && events[current_event]) ? events[current_event].dress_code : ''
  });
  // state for image upload
  const [imgShow, setImgShow] = useState<any>(
    (typeof event.banner === 'string' && event.banner !== '') ? config.assets + slug + event.banner : null
  );
  // state for error
  const [error, setError] = useState('');
  // state for loading
  const [loading, setLoading] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'buttons', 'errors', 'success']);

  if(loading)
    return <Loading />;

  // if slug doesn't exist or club suspended or not authorized or you can manage that event, redirect
  if(!places[slug!] || places[slug!].suspend || places[slug!].role < place_roles.manager)
    return <Navigate to="/" replace />

  if(current_event !== -1 && (!events[current_event] || events[current_event].place_id !== event.place_id || new Date() > new Date(events[current_event].event_start)))
    return <Navigate to="/" replace />

  // if event is cancelled and someone is trying to edit it from the link
  if(current_event !== -1 && events[current_event].event_cancelled)
   return <Navigate to="/"  replace />

  // function to handle inputs
  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;

    if(name === "fake_mode")
      setEvent((prevState) => ({...prevState, [name]: !event.fake_mode}));
    else if(name === "event_mode")
      setEvent((prevState) => ({...prevState, [name]: (event.event_mode === value) ? (value === 'choose_table' ? 'choose_section' : 'request') : value}));
    else
      setEvent((prevState) => ({...prevState, [name]: value}));
  }

  // function to handle dateInput
  const handleDateInput = (name: string, value: Date) => setEvent((prevState) => ({...prevState, [name]: value}));
  
  // function to handle fileUpload
  const handleFileUpload = (e: FormEvent<HTMLInputElement>) => {
    const fileUploaded = e.currentTarget.files![0];
    // without this page crashing
    if(fileUploaded) {
      // check if file is HEIC
      if(/image\/heic/.test(fileUploaded.type))
        return dispatch(newMessage({ content: t("heic_error", {ns: 'errors'}), type: 'error' }));
      // set new banner
      setEvent((prevState) => ({...prevState, banner: fileUploaded}));
      // set image showed
      setImgShow(URL.createObjectURL(fileUploaded));
    }
  }

  // function for age handler +/-
  const ageHandler = (value: number)=> {
    // min age 18
    if(event.min_age + value >= 18)
      setEvent((prevState) => ({...prevState, min_age: prevState.min_age + value}));
  }

  // function to submit event changes
  const eventCreate = async () => {
    try {
      // set loading to true
      setLoading(true);
      // if mode is not choose table, set fake mode also on off
      if(event.event_mode !== 'choose_table') {
        event.fake_instances = '0';
        event.fake_mode = false;
      }
      // await fetch for submit
      let res = await eventSubmit(event, current_event, dispatch);
      // set loading to false
      setLoading(false);
      // if error, display error messsage
      if(res.error) {
        setError(res.error);
        dispatch(newMessage({ content: t(res.error, {ns: 'errors'}), type: "error" }));
        return;
      }
      // limit tables if there are any to limit
      if(limitTables.length || delimitTables.length)
        await dispatch(limitTablesAction({ event_id: res, place_id: event.place_id, tables: limitTables, unlimit: delimitTables }));
      // dispatch success message (if no error)
      let message = events[current_event] ? 'edit_event_success' : 'new_event_success';
      dispatch(newMessage({ content: t(message, {ns: 'success'}), type: 'success' }));
      // return back to the club (event list)
      navigate(`/club/${slug}`, { replace: true, state: { event_edited: res } });
    } catch(err: any) {
      // set loading to false
      setLoading(false);
      // display error message
      setError(err);
    }
  }
  
  const changeEventStatus = async () => {
    let status_text = ['delete_event', 'cancel_event'];

    let status = new Date(events[current_event].reservation_start) <= new Date() ? 1 : 0;

    // show prompt
    dispatch(showPrompt({
      title: t(status_text[status], {ns: 'buttons'}),
      message: t(status_text[status]),
      accept: async () => { await eventStatus(status); dispatch(closePrompt());},
      decline: () => dispatch(closePrompt()), accept_text: t(status_text[status], {ns: 'buttons'}),
      decline_text: t("back", {ns: 'buttons'}), type: (status === 1) ? "add" : "delete"
    }))
  }

  // change event status (canceled, deleted)
  const eventStatus = async (operation: number) => {
    try {
      // set loading to true
      setLoading(true);
      // cancel event
      await eventCancel(events[current_event], operation, dispatch);
      // create proper message depending on status
      let message = (operation === 0) ? 'delete_event_success' : 'cancel_event_success';
      // dispatch the message
      dispatch(newMessage({ content: t(message, {ns: 'success'}), type: 'success'}));
      // navigate to the club (event list)
      navigate(`/club/${slug}`, { replace: true });
    }
    catch {
      dispatch(newMessage({ content: t("something_wrong", {ns: 'errors'}), type: 'error'}));
      setLoading(false);
    }
  }

  return (
    <>
      <ContentTitle title={(!event_id) ? t("new_event_title") : t("edit_event_title")} back={t("back2", {ns: 'buttons'})} />
      <section className="content-section">
        <div className="grid">
          <div className="inner-content-grid grid">
            {/* image upload */}
            <div className="edit__box">
              <ImageUpload error={error} name="banner" imgShow={imgShow} fileUpload={handleFileUpload} />
            </div>
            {/* image upload - end */}
            {/* Event opening */}
            <div className="edit__box">
              <div className="input-wrapper">
                <DatePicker name="reservation_start" default_value={event.reservation_start} label={t("reservation_start_label", {ns: 'inputs'})} placeholder={t("date_placeholder", {ns: 'inputs'})} onChange={handleDateInput}/>
                <Input error={error} name="reservation_time" type="time" placeholder={t("time_placeholder", {ns: 'inputs'})} value={event.reservation_time} single onChange={handleChanges}/>
              </div>
              <Counter label={t("min_age_label", {ns: 'inputs'})} value={event.min_age} counterHandler={ageHandler}/>
            </div>
            {/*Event opening - end */}
            <div className="edit__box">
              <Input error={error} name="name" type="text" placeholder={t("event_name_placeholder", {ns: 'inputs'})} value={event.name} label={t("event_name_label", {ns: 'inputs'})} onChange={handleChanges} />
            </div>
            {/* Event start date input */}
            <div className="edit__box">
              <div className="input-wrapper">
              <DatePicker name="event_start" default_value={event.event_start} label={t("event_date_label", {ns: 'inputs'})} placeholder={t("date_placeholder", {ns: 'inputs'})} onChange={handleDateInput}/>
              </div>
            </div>
            {/* Event start date input end */}
            <div className="edit__box">
              <Input error={error} name="event_time" type="time" placeholder={t("time_placeholder", {ns: 'inputs'})} value={event.event_time} label={t("event_start_time_label", {ns: 'inputs'})} onChange={handleChanges} />
            </div>
            <div className="edit__box">
              <Input error={error} name="event_end" type="time" placeholder={t("time_placeholder", {ns: 'inputs'})} value={event.event_end} label={t("event_end_time_label", {ns: 'inputs'})} onChange={handleChanges} />
            </div>
            <div className="edit__box">
              <Input error={error} name="reservation_takeover" type="time" placeholder={t("time_placeholder", {ns: 'inputs'})} value={event.reservation_takeover} label={t("reservation_takeover_label", {ns: 'inputs'})} onChange={handleChanges} />
            </div>
            <div className="edit__box">
              <PriceInput error={error} name="ticket_price" type="number" placeholder={t("ticket_price_placeholder", {ns: 'inputs'})} value={event.ticket_price} label={t("ticket_price_label", {ns: 'inputs'})} onChange={handleChanges} selectedCurrency={event.ticket_currency} selectCurrency={(currency) => setEvent((prevState) => ({...prevState, ticket_currency: currency}))}/> 
            </div>
            <div className="edit__box--row">
              <Input name="dress_code" type="text" placeholder={t("dress_code_placeholder", {ns: 'inputs'})} value={event.dress_code} label={t("dress_code_label", {ns: 'inputs'}) + " " + t("optional_input", {ns: 'inputs'})} onChange={handleChanges} /> 
            </div>
            <div className="edit__box--row">
              <Input error={error} name="minimum_bottles_classic" type="number" placeholder={t("minimum_bottles_classic_placeholder", {ns: 'inputs'})} value={event.minimum_bottles_classic} label={t("minimum_bottles_classic_label", {ns: 'inputs'})} onChange={handleChanges} />
              <Input error={error} name="minimum_bottles_vip" type="number" placeholder={t("minimum_bottles_vip_placeholder", {ns: 'inputs'})} value={event.minimum_bottles_vip} label={t("minimum_bottles_vip_label", {ns: 'inputs'})} onChange={handleChanges} />
            </div>
            <div className="edit__box--row">
              <Input name="event_notes" type="text" placeholder={t("event_notes_placeholder", {ns: 'inputs'})} value={event.event_notes} label={t("event_notes_label", {ns: 'inputs'}) + " " + t("optional_input", {ns: 'inputs'})} onChange={handleChanges} /> 
            </div>
            {/* DISPLAY ONLY IF place type is 0 */}
            { places[slug!].type === 0 &&
              <div className="edit__box--row">
                <h2 className="fake-reservation-mode__h2">{t("booking_mode_title", {ns: 'general'})}</h2>
                <div className="grid--2">
                  <CheckBox label={t("3d_mode", {ns: 'inputs'})} name="event_mode" value={'choose_table'} checked={event.event_mode === 'choose_table'} onChange={handleChanges}/>
                </div>
              </div>
            }
            {
              event.event_mode === 'choose_table' ?
                <div className="edit__box--row">
                  {/* <h2 className="fake-reservation-mode__h2">{t("fake_reservation_mode", {ns: 'inputs'})}</h2> */}
                  <div className="grid--2">
                    <CheckBox label={t("fake_reservation_mode", {ns: 'inputs'})} name="fake_mode" checked={event.fake_mode} onChange={handleChanges}/>
                    {
                      event.fake_mode &&
                        <Input error={error} name="fake_instances" type="number" placeholder={t("wanted_available_tables_placeholder", {ns: 'inputs'})} value={parseInt(event.fake_instances) === 0 ? '' : event.fake_instances} label={t("wanted_available_tables_label", {ns: 'inputs'})} onChange={handleChanges} />
                    }
                  </div>
                </div>
              :
                <div className="edit__box--row">
                  {/* <h2 className="fake-reservation-mode__h2">Gost odabire sektor</h2> */}
                  <div className="grid--2">
                    <CheckBox label={t("choose_section_mode_title", {ns: 'general'})} name="event_mode" value={'choose_section'} checked={event.event_mode === 'choose_section'} onChange={handleChanges}/>
                  </div>
                </div>
            }
          </div>
          <EventActions 
            cancelOperation={() => navigate(-1)}
            submitOperation={ async() => await eventCreate() }
            action={( current_event === -1 ) ? 'create' : 'edit'}
            changeStatus={async() => await changeEventStatus()}
            event_status={(current_event !== -1) ? events[current_event].event_cancelled : null}
            isReservationStart={( current_event === -1 ) ? false : (new Date(events[current_event].reservation_start) <= new Date())}
            isEventCancelled={( current_event === -1 ) ? false : events[current_event].event_cancelled}
          />
          {/* <!-- Start of - restrict tables --> */}
          {
            ((current_event !== -1 && !events[current_event].event_cancelled) || current_event === -1) &&
            <div className="restrict-tables__cta" onClick={() => navigate(`tables`)}>
            <h2>{t("limit_tables_title")}</h2>
            <ArrowBigRight className="restrict-tables__arrow" />
          </div>
          }
          {/* <!-- End of - restrict tables --> */}
        </div>
      </section>
      <Outlet />
    </>
  )
}

export default AddEvent;