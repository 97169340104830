import { useTranslation } from "react-i18next";
import { logout } from "../../features/user/userAPI";
import { LogoutIcon } from "../icons";
import LanguageSwitcher from "../language";

const Logout = () => {
  const {t} = useTranslation(['menu']);
  return (
    <div className="sidebar__container">
      {
        window.innerWidth > 800 && <LanguageSwitcher />
      }
      <div className="button--back logout-button" onClick={logout}>{t("logout", {ns: 'menu'})}<LogoutIcon /></div>
    </div>
  )
}

export default Logout;