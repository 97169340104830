import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Navigate} from 'react-router-dom';
import config, { place_roles } from '../../app/config';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Button, CheckBox, ImageUpload, Input } from '../../components/form';
import SearchLocationInput from '../../components/google-maps/SearchLocationInput';
import Loading from '../../components/loading/loading';
import { ContentTitle } from '../../components/titles';
import { newMessage } from '../../features/messages/messagesSlice';
import { getPlaceData } from '../../helpers/api/clubsExtraData';
import { placeUpdateSubmit } from './clubs-edit/editSubmit';

const ClubEdit = () => {
  const dispatch = useAppDispatch();
  
  // redux get
  const { places } = useAppSelector( state => state.places );

  // get slug from link so we can fetch events for selected club
  let { slug } = useParams();

  // translation
  const {t} = useTranslation(['general', 'inputs', 'buttons', 'errors', 'success']);

  // state for form data
  const [formData, setFormData] = useState({
    name: places[slug!].name,
    banner: places[slug!].banner as string | File,
    logo: places[slug!].logo as string | File,
    contact_number: '',
    email: '',
    automatic_blocking: false,
    place_id: places[slug!].place_id,
    slug: slug!,
    facebook: '',
    instagram: '',
    address: '',
    country_code: '',
    
  });

  // state for loading
  const [loading, setLoading] = useState(true);

  const [imgShow, setImgShow] = useState<any>({
    banner: (typeof places[slug!].banner === 'string' && places[slug!].banner !== '') ? config.assets + slug + places[slug!].banner : null,
    logo: (typeof places[slug!].logo === 'string' && places[slug!].logo !== '') ? config.assets + slug + places[slug!].logo : null
  });

  // state for error in forms
  const [error, setError] = useState('');

  // load club extra data
  useEffect(() => {

    if(slug && places[slug])
    {
      (async (domain: string) => {
        try {
          // set loading to true
          setLoading(true);
          let data = await getPlaceData(domain);
          // put in state
          setFormData((prevState) => ({
            ...prevState,
            ...data
          }));
          // set loaded
          setLoading(false);
        }
        catch {
          // 404 page display
          console.log('404');
        }
      })(places[slug].domain);
    }
  }, [slug]);

  if(loading)
    return <Loading />;

  // if user is not owner redirect
  if(places[slug!].role < place_roles.owner)
    return <Navigate to="/" replace />

  // function to handle inputs
  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setFormData((prevState) => ({...prevState, [name]: (name === "automatic_blocking") ? !formData.automatic_blocking : value}));
  }

  // function to handle fileUpload
  const handleFileUpload = (e: FormEvent<HTMLInputElement>) => {
    let name = e.currentTarget.name;
    let fileUploaded = e.currentTarget.files![0];
    // without this page crashing
    if(fileUploaded) {
      // check if file is HEIC
      if(/image\/heic/.test(fileUploaded.type))
        return dispatch(newMessage({ content: t("heic_error", {ns: 'errors'}), type: 'error' }));
      setFormData((prevState) => ({...prevState, [name]: fileUploaded}));
      // set image showed
      setImgShow((prevState: any) => ({
        ...prevState,
        [name]: URL.createObjectURL(fileUploaded)
      }));
    }
  }

  // function to submit changes
  const updatePlace = async () => {
    try {
      setLoading(true);
      // dispatch update data
      let res = await placeUpdateSubmit(formData, dispatch);
      // set loading to false
      setLoading(false);
      // if error, display error messsage
      if(res.error) {
        setError(res.error);
        dispatch(newMessage({ content: t(res.error, {ns:'errors'}), type: "error" }));
      }
      else {
        // dispatch new message
        dispatch(newMessage({ content: t(res.success!, {ns: 'success'}), type: 'success' }));
      }
    }
    catch(err: any) {
      // set loading to false
      setLoading(false);
      // display error message
      setError(err);
    }
  }

  const handleAddress = (address_object: any) => setFormData((prevState) => ({...prevState, address: address_object.address, country_code: address_object.country_code}));
  return (
    <>
      <ContentTitle title={t("place_settings_title", {club: places[slug!].name})} back={places[slug!].name} />
      <section className="content-section">
        <div className="grid">
          <div className="inner-content-grid grid">
            {/* image upload */}
            <div className="edit__box">
              <div className="label input__label">{t("club_main_image", {ns: 'inputs'})}</div>
              <ImageUpload error={error} name="banner" imgShow={imgShow.banner} fileUpload={handleFileUpload} />
            </div>
            {/* image upload- end */}
            {/* image upload */}
            <div className="edit__box">
              <div className="label input__label">{t("club_logo", {ns: 'inputs'})}</div>
              <ImageUpload error={error} name="logo" imgShow={imgShow.logo} fileUpload={handleFileUpload} />
            </div>
            {/* image upload- end */}
            { /*Place main info*/ }
            <div className="edit__box">
              <Input error={error} label={t("club_name_label", {ns: 'inputs'})} name="name" type="string" placeholder={t("club_name_placeholder", {ns: 'inputs'})} value={formData.name} onChange={handleChanges}/>
              <SearchLocationInput label={t("location_label", {ns: 'inputs'})} placeholder={t("location_placeholder", {ns: 'inputs'})} address={formData.address} assignAddress={handleAddress}  />
            </div>
            {/* Place contact data*/}
            <div className="edit__box">
              <Input label={t("contact_numbers_label", {ns: 'inputs'})} name="contact_number" type="string" placeholder={t("numbers_placeholder", {ns: 'inputs'})} value={formData.contact_number} onChange={handleChanges}/>
              <Input label={t("email_label", {ns: 'inputs'})} name="email" type="string" placeholder={t("email_placeholder", {ns: 'inputs'})} value={formData.email} onChange={handleChanges}/>
            </div>
            {/* Place contact data - end */}
            <div className="edit__box--row">
              <Input label="Facebook link" name="facebook" type="string" placeholder={t("facebook_placeholder", {ns: 'inputs'})} value={formData.facebook} onChange={handleChanges}/>
              <Input label="Instagram link" name="instagram" type="string" placeholder={t("instagram_placeholder", {ns: 'inputs'})} value={formData.instagram} onChange={handleChanges}/>
            </div>
            <div className="edit__box">
              <CheckBox label={t("automatic_blocking_label", {ns: 'inputs'})} name="automatic_blocking" checked={formData.automatic_blocking} onChange={handleChanges}/>
            </div>
            <div className="edit__actions">
              <Button text={t("save", {ns: 'buttons'})} onClick={async () => await updatePlace()}/>
            </div>
            { /* Place main info -end */}
          </div>
        </div>
      </section>
    </>
  )
}

export default ClubEdit;