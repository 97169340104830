import PopupWrapper, { PopupActions, PopupProps } from ".";
import { formatDate } from "../../helpers";
import config from "../../app/config";
import { OldEventsScheme } from "../../features/events/eventsEnum";
import { ClockIcon } from "../icons";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { Image } from "../image";

const OldEventPopup = (props: PopupProps & { event: OldEventsScheme | null, slug: string, total_tables:number }) => {

  const {t} = useTranslation(['general', 'status']);
  // currencies import
  const { currencies } = useAppSelector(state => state.currencies );

  return (
    <PopupWrapper opened={props.isOpened}>
      <div>
        <PopupActions close={props.close} />
        {props.event !== null && <> 
        {/* <!-- Event details banner --> */}
        <div className="event-details__banner">
          <Image src={config.assets + props.slug + props.event.banner} alt="" fadeIn />
        </div>
        {/* <!-- / Event details banner --> */}
        {/* <!-- Event details main info --> */}
        <div className="event-details__box">
          {
            props.event.event_cancelled && <div className="event-details__cancelled">{t("declined_phase", {ns: 'status'})}</div>
          } 
          <div className="title">{ props.event.name }</div>
          <div className="event-details__when">
            <div className="event-details__icon-box">
              <img src="../assets/icons/calendar.svg" alt="" />
              <div className="label">{formatDate(new Date(props.event.event_start), 1, t)}</div>
            </div>
            <div className="event-details__icon-box">
              <ClockIcon />
              <div className="label">{formatDate(new Date(props.event.event_start), 3)+' - '+formatDate(new Date(props.event.event_end), 3)}</div>
            </div>
          </div> 
        </div>
        {/* <!-- / Event details main info --> */}
        {/* <!-- Event details info --> */}
        <div className="grid row-30">
          <div className="event-details__single">
            <div className="event-details__single-name">{t("total_reservations_label")}</div>
            <h5>{props.event.total_reservations}</h5>
          </div>
          <div className="event-details__single">
            <div className="event-details__single-name">{t("user_reservations_label")}</div>
            <h5>{props.event.user_reservations}</h5>
          </div>
          <div className="event-details__single">
            <div className="event-details__single-name">{t("total_limited_label")}</div>
            <h5>{props.event.total_reservations - props.event.user_reservations}</h5>
          </div>
          <div className="event-details__single">
            <div className="event-details__single-name">{t("total_confirmed_label")}</div>
            <h5>{props.event.confirmed_reservations}</h5>
          </div>
          <div className="event-details__single">
            <div className="event-details__single-name">{t("entrance_label")}</div>
            <h5>{(parseInt(props.event.ticket_price) === 0) ? t("free_text") : props.event.ticket_price + ' ' +currencies[props.event.ticket_currency].symbol}</h5>
          </div>
          <div className="event-details__single big">
            <div className="event-details__single-name">{t("min_bottles_title")}</div>
            <h5>{t("min_bottles", { normal: props.event.minimum_bottles_classic, vip: props.event.minimum_bottles_vip })}</h5>
          </div>
        </div>
        {/* <!-- Event details info --> */}
        </>
        }
      </div>
    </PopupWrapper>);
}
export default OldEventPopup;