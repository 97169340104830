import {OldEventsScheme} from '../../../features/events/eventsEnum';
import config from '../../../app/config';
import { formatDate } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { Image } from '../../image';

const OldEvent = (props: OldEventsScheme & { slug:string,  onClick: () => void }) => {

  const {t} = useTranslation(['status', 'general']);

  return (
  <div className="event" onClick={props.onClick}>
    {
      props.event_cancelled && <div className="event__cancelled">{t("declined_phase", {ns: 'status'})}</div>
    }
    <div className="event__banner">
      <Image src={config.assets + props.slug + props.banner} alt="" fadeIn />
    </div>
    <div className="event__info">
      <div className="eyebrow">{props.event_start && formatDate(new Date(props.event_start), 1, t)}</div>
      <h3 className="event__h3">{props.name}</h3>
    </div>
  </div>);
}

export default OldEvent;