export const StaffDetails = (props: {name: string, email: string}) => (
  <div className="staff-details__main user">
    <div className="user__icon">
      <img src="../assets/icons/profile.svg" alt="" />
    </div>
    <div>
      <h2 className="user__name">{props.name}</h2>
      <div className="label--gray">{props.email}</div>
    </div>
  </div>
)