import { queryByTitle } from '@testing-library/react';
import API from '../../helpers/api';

export const blockedAPI = {
  get,
  editBlockedNumber
}

// function to get blocked numbers 
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get blocked numbers
    let res = await API.get('/blocked/');
    // return blocked numbers
    return res.data;
  } catch {
    return rejectWithValue();
  }
}

// function for edit blocked numbers
async function editBlockedNumber(params: {query: any, slugs: any}, { dispatch, rejectWithValue }:any) {
  try {
    // edit blocked numbers
    await API.post('/blocked/update', {query: params.query});
    return {slugs: params.slugs, phone: params.query.phone};
  } catch (err) {
    return rejectWithValue();
  }
}
