import { useTranslation } from "react-i18next";
import { Input } from "../../form";
import TelInput from "../../form/phoneInput";
import { TableScheme } from "../enums";
import EventsScheme from "../../../features/events/eventsEnum";
import { FormEvent, useEffect, useState } from "react";
import { newReseration } from "./api-calls";
import { useAppDispatch } from "../../../app/hooks";

interface NewReservationScheme {
  event: EventsScheme,
  selectedTable: TableScheme
}

const NewReservation = (props: NewReservationScheme) => {
  const dispatch = useAppDispatch();
  // input user state
  let [userData, setUserData] = useState({
    customer_name: '',
    phone_number: ''
  });
  // translation
  const { t } = useTranslation(['inputs', 'buttons']);

  // update user data on table change
  useEffect(() => {
    // update user data on table change
    setUserData({
      customer_name: '',
      phone_number: ''
    });
  }, [props.selectedTable.id]);

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setUserData((prevState) => ({...prevState, [name]: value}));
  }

  const handlePhoneInput = (phone: string) => {
    if(phone !== userData.phone_number)
      setUserData((prevState) => ({...prevState, phone_number: phone}));
  }

  // function to create new reservation
  const makeReservation = async () => {
    // make api call
    await newReseration({
      ...userData,
      ...props
    }, dispatch, t)
  }

  return (
    <div className="canvas__form__new-reservation">
      <Input 
        name="customer_name"
        label={t("name_label", {ns: 'inputs'})}
        placeholder={t("name_placeholder", {ns: 'inputs'})}
        type="text"
        value={userData.customer_name}
        onChange={handleInput}
      />
      <div className="input-wrapper">
        <div className="label input__label">{t("contact_numbers_label", {ns: 'inputs'})} {t("optional_input", {ns: 'inputs'})}</div>
        <TelInput key={props.selectedTable.id} updatePhoneNumber={handlePhoneInput} phone_number={userData.phone_number}/>
      </div>
      <div className="button" onClick={makeReservation}>{t("reserve", {ns: 'buttons'})}</div>
    </div>
  )
}

export default NewReservation;