import { SectionTitle } from '../../components/titles';
import Invite from '../../components/invite';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { getInvitations } from '../../features/invitations/invitationSlice';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/loading/loading';

const InvitationsNotifications = () => {
  const dispatch = useAppDispatch();
  const { invitations } = useAppSelector(state => state.invitations);
  const [loading, setLoading] = useState(true);

  const {t} = useTranslation(['general']);

  useEffect(() => {
    // function to load invitations
    (
      async () => {
      try {
        await dispatch(getInvitations(true));
        setLoading(false);
      }
      catch {
        setLoading(false);
      }
      }
    )();
  }, []);

  if(loading)
    return <Loading />
    
  if(!Object.keys(invitations).length)
    return <div className='empty-section'>{t("no_notifications")}</div>

  return (
      <section className="content-section">
      <SectionTitle title={t("invitations_title")} />
        <div className="grid">
          { Object.keys(invitations).map((hash, key) => { return <Invite key={key} {...invitations[hash]} />}) }
        </div>
      </section>
  )
}

export default InvitationsNotifications;