import { useEffect, useState } from "react";
import PopupWrapper, { PopupActions, PopupProps } from ".";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { maxRole } from "../../helpers";
import { filterPlaces, StaffState } from "./staff/rolesHelpers";
import StaffPopups from "./staff/"
import Loading from "../loading/loading";
import { editStaff } from "./staff/staffSubmit";
import { newMessage } from "../../features/messages/messagesSlice";
import { useTranslation } from "react-i18next";

const NewStaffPopup = (props: PopupProps) => {
  // dispatch for adding new staff
  const dispatch = useAppDispatch();
    
  // staff pull
  const { staff } = useAppSelector(state => state.staff);
  // places pull
  const { places } = useAppSelector(state => state.places);
  // translation
  const {t} = useTranslation(['general','buttons', 'success', 'errors']);

  // selected role
  const [role, setRole] = useState(0);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialState, setInitial] = useState<StaffState[]>([]);
  const [currentState, setCurrent] = useState<StaffState[]>([]);

  useEffect(() => {
    // get new_state
    let new_state = filterPlaces(places, role, staff[email]);

    // when selected role change change initial state
    setInitial(new_state);

    // assign also and current state
    setCurrent(structuredClone(new_state));

  }, [role, email]);

  useEffect(() => {
    setRole(0);
    setEmail('');
  }, [props.isOpened]);

  if(loading)
    return <Loading />

  // functions to pass to another component
  const selectRole = (role:number) => setRole(role);

  // get my max role
  const max_role = maxRole(places);

  // function to assign role
  const assignRole = (index:number, role:boolean) =>  {
    let new_obj = [...currentState];
    new_obj[index].has_role = role;
    setCurrent(new_obj);
  }

  const Submit = async () => {
    setLoading(true);
    await (async () => {
      // dispatch edit staff function
      let response = await editStaff(initialState, currentState, role, email, dispatch);
      if(response.sucess) {
        props.close();
        dispatch(newMessage({content: t(response.sucess, {ns: 'success'}), type: "success" }))
      }
      else 
        dispatch(newMessage({content: t(response.error!, {ns: 'errors'}), type: "error" }))
    })();
    setLoading(false);
  }
  // function to assign email
  const assignEmail = (email: string) => setEmail(email);

  return (
    <PopupWrapper opened={props.isOpened}>
      <div>
        <PopupActions close={props.close} />
        <div className="popup__title">
          <h2>{t("new_staff")}</h2>
        </div>
        <StaffPopups.EmailInput email={email} assignEmail={assignEmail} />
        <StaffPopups.SelectRole role={role} setRole={selectRole} max_role={max_role}/>
        <StaffPopups.PlacesDisplay staff={currentState} assignRole={assignRole}/>
      </div>
      <div>
        <div className="button--invert popup__button" onClick={ () => { setRole(0); props.close(); } }>{t("cancel", {ns: 'buttons'})}</div>
        {role !== 0 && <div className="button--back" onClick={Submit}>{t("save", {ns: 'buttons'})}<img src="../assets/icons/save.svg" /></div>}
      </div>
    </PopupWrapper>
  )
}

export default NewStaffPopup;