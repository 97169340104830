import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SentInvite from "./sentInvite";
import { SectionTitle } from "../titles";
import { getSentInvitations } from "../../features/invitations/invitationSlice";

interface InvitationsScheme {
  title: string
}

const Invitations = (props: InvitationsScheme) => {
  const dispatch = useAppDispatch();
  // staff from redux
  const { sentInvitations } = useAppSelector(state => state.invitations);

  useEffect(() => {
    // function to load sent invitations
    (async () => await dispatch(getSentInvitations(true)))();
  }, [dispatch]);

  if(!Object.keys(sentInvitations).length)
    return <></>

  return (
    <section className="content-section">
      <SectionTitle title={props.title} />
      <div className="grid">
        { Object.keys(sentInvitations).map((hash, index) => <SentInvite key={index} {...sentInvitations[hash]} />) }
      </div>
    </section>
  )
}

export default Invitations;