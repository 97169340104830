import { useEffect, useState } from "react";
import { DoubleTableIcon, ListDownIcon, MoveToStackIcon, MoveToTableIcon, SwitchTableIcon } from "../../icons";
import { ReservationScheme, TableScheme } from "../enums";
import { VIEW_MODE } from "..";
import { useTranslation } from "react-i18next";

interface TableActionsScheme {
  reservation: ReservationScheme | {},
  table: TableScheme | {},
  mode: number,
  onSwitchTableClick: () => void,
  onMergeATableClick: () => void,
  onMoveToStackClick: () => void,
  isAction: boolean,
  actionInstruction: string,
  actionCondition: boolean,
  onExitAction: () => void,
  onConfirmAction: () => void
}

const TableActions = (props: TableActionsScheme) => {
  let [isOpened, setOpened] = useState(false);
  let [hasAuthority, setAuthority] = useState(props.mode === VIEW_MODE && 'table_id' in props.reservation && props.reservation.customer_name && !props.reservation.confirmed);

  // translation
  const { t } = useTranslation(['general', 'buttons']);

  useEffect(() => {
    setAuthority(props.mode === VIEW_MODE && 'table_id' in props.reservation && props.reservation.customer_name && !props.reservation.confirmed)
  }, [props])

  return (
    <>
      <div className="canvas__form__table-actions" onClick={() => hasAuthority && !props.isAction && setOpened(prev => !prev)}>
        <div className="canvas__form__label">{t("table", { ns: 'general' })}</div>
        <div className="canvas__form__table-label">{ 'label' in props.table ? props.table.label : "?" }</div>
        {
          hasAuthority &&
            <ListDownIcon />
        }
        {
          hasAuthority && 'id' in props.reservation &&
            <div className={"canvas__form__table-options" + (isOpened ? " opened" : "")}>
              {
                props.reservation.table_id !== null && <>

                  <div className="canvas__form__table-option" onClick={props.onSwitchTableClick}>
                    <SwitchTableIcon />{t("change_table", { ns: 'buttons' })}
                  </div>
                  <div className="canvas__form__table-option" onClick={props.onMergeATableClick}>
                    <DoubleTableIcon />{t("merge_table", { ns: 'buttons' })}
                  </div>
                  <div className="canvas__form__table-option" onClick={props.onMoveToStackClick}>
                    <MoveToStackIcon />{t("to_stack", { ns: 'buttons' })}
                  </div>

                </>
              }
              {
                props.reservation.table_id === null &&
                  <div className="canvas__form__table-option" onClick={props.onSwitchTableClick}>
                    <MoveToTableIcon />{t("to_table", { ns: 'buttons' })}
                  </div>
              }
            </div>
        }
      </div>
      <div className={"canvas__form__table-action" + (props.isAction ? " opened" : "")}>
        <div className="canvas__form__instructions label--gray">{t(props.actionInstruction, { ns: 'general' })}</div>
        <div className="canvas__form__actions">
          <div className="button--invert extra-small" onClick={props.onExitAction}>{t("back", {ns: 'buttons'})}</div>
          <div className={"button extra-small" + (props.actionCondition ? " disabled" : "")} onClick={props.onConfirmAction}>{t("confirm", {ns: 'buttons'})}</div>
        </div>
      </div>
    </>
  )
}

export default TableActions;